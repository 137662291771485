export default function ChevronDown1(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M7.99999 8.78047L11.3 5.48047L12.2427 6.42314L7.99999 10.6658L3.75732 6.42314L4.69999 5.48047L7.99999 8.78047Z"
        fill="#001018"
      />
    </svg>
  )
}
