export default function ChevronUp({ className, pathClassName }: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32" fill="none">
      <path
        d="M16 14.4377L9.39998 21.0377L7.51465 19.1523L16 10.667L24.4853 19.1523L22.6 21.0377L16 14.4377Z"
        fill="#646464"
      />
    </svg>
  )
}
