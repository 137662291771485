import DashboardLayout from "core/layouts/DashboardLayout"
import React, { useEffect, useRef, useState } from "react"
import Button from "core/components/Button"
import DropDownMenu from "./components/DropDownMenu"
import ProblemCard from "./components/ProblemCard"
import ProblemCardNew from "./components/ProblemCardNew"
import BlogThumbnail from "assets/svgs/BlogThumbnail"
import VideoThumbnail from "assets/svgs/VideoThumbnail"
import DoubtSupportViewModel from "./DoubtSupportViewModel"
import Loader from "core/components/Loader"
import { isEmpty } from "core/utils/misc"
import OngoingDoubtRectangle from "assets/svgs/OngoingDoubtRectangle"
import { format } from "date-fns"
import { cn } from "core/lib/utils"
import { CrossIcon, InfoButton } from "core/constants/svgs"
import CheckBoxCircleFilled from "assets/svgs/CheckBoxCircleFilled"
import OperationalIssueCard from "./components/OperationalIssueCard"
import { useApp } from "core/context/app"
import { Tooltip } from "@mui/material"
import { useLocation, useParams, useSearchParams } from "react-router-dom"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import { Head } from "core/components/seo"

export default function DoubtSupportView() {
  const {
    refreshed,
    classTopics,
    questions,
    solution,
    history,
    searchHistory,
    pendingDoubt,
    operationalChangesDone,
    fetchClassTopics,
    fetchQuestions,
    fetchDoubtQuerySolution,
    fetchStudentDoubtHistory,
    updateDoubtStatus,
    searchStudentDoubt,
    fetchStudentDoubtPending,
    setQuestions,
    setSolution,
    setSearchHistory,
    setPendingDoubt,
    setStudentBatchShift,
    setStudentBatchPause,
    setOperationalChangesDone,
    setMentor,
  } = DoubtSupportViewModel()

  const [index, setIndex] = useState(0)
  const [selectedButton, setSelectedButton] = useState("All")
  const [module, setModule] = useState("")
  const [topic, setTopic] = useState("")
  const [question, setQuestion] = useState("")
  const [resolve, setResolve] = useState(false)
  const [instantResolveCard, setInstantResolveCard] = useState(false)
  const [doubtResolvefromTop, setDoubtResolvefromTop] = useState(false)
  const [onSubmit, setOnSubmit] = useState(false)
  const [currentlySearching, setCurrentlySearching] = useState(false)
  const [popUp, setPopUp] = useState(false)
  const [popUpResolved, setPopUpResolved] = useState(false)
  const [doubtResolvedFromTop, setDoubtResolvedFromTop] = useState("")
  const [instantResolve, setInstantResolve] = useState(false)
  const [infoHover, setInfoHover] = useState(false)
  const [submitQueryTime, setSubmitQueryTime] = useState<any>()
  const [slidingPopUp, setSlidingPopUp] = useState<any>(false)
  const [slidingPopUpText, setSlidingPopUpText] = useState<any>("")
  const [minutes, setMinutes] = useState<any>(10)
  const [seconds, setSeconds] = useState<any>(0)

  const topicRef = useRef<HTMLLIElement | null>(null)

  const { student } = useApp()

  const doubtSupportAvailable = parseInt(student?.batch_start_timestamp) * 1000 <= Date.now()
  const message = "Doubt Support will be available after the batch starts."
  const str_human_mentor_support = student["batch"] === "KCE" ? "Mentor Support" : "Human Support"

  const updateTime = () => {
    setMinutes(Math.ceil(9 - (Date.now() - submitQueryTime) / 60000))
    setSeconds(Math.round(60 - (((Date.now() - submitQueryTime) / 1000) % 60)))
  }

  const toggleValue1 = () => {
    setIndex(0)
  }

  const toggleValue2 = () => {
    setIndex(1)
    setCurrentlySearching(false)
    setSearchHistory({})
    setInstantResolve(false)
  }

  const handleButtonClick = (buttonName: any) => {
    setSelectedButton(buttonName)
  }

  useEffect(() => {
    if (refreshed) {
      fetchClassTopics()
      fetchStudentDoubtPending()
      fetchStudentDoubtHistory()
    }
  }, [refreshed])

  useEffect(() => {
    setQuestion("")
    setQuestions([])
    module != "Operational Issue" && fetchQuestions(topic)
    setOnSubmit(false)
    setOperationalChangesDone(false)
  }, [topic])

  useEffect(() => {
    setQuestion("")
    if (!param.get("topic")) {
      setTopic("")
    }

    setOnSubmit(false)
  }, [module])

  useEffect(() => {
    setOnSubmit(false)
  }, [question])

  useEffect(() => {
    if (refreshed) {
      fetchStudentDoubtHistory()
      setPendingDoubt({})
      fetchStudentDoubtPending()
      setDoubtResolvefromTop(false)
    }
  }, [resolve, instantResolveCard, searchHistory, popUp, refreshed])

  useEffect(() => {
    setSubmitQueryTime(Date.now())
    const intervalId = setInterval(updateTime, 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [solution])

  useEffect(() => {
    if (operationalChangesDone) {
      setSlidingPopUp(true)
      const timer = setTimeout(() => {
        setSlidingPopUp(false)
      }, 3000)
    }
  }, [operationalChangesDone])

  let [param, setParam] = useSearchParams()

  useEffect(() => {
    if (param.get("topic")) {
      setModule("Operational Issue")
      setTopic(param.get("topic") || "")
    }
  }, [param])
  return (
    <DashboardLayoutv2>
      <Head title="Doubt Support" />

      {slidingPopUp && (
        <div className="fixed top-[20px] right-1/4 transform -translate-x-1/2 z-50 flex h-[33px] w-[386px] gap-x-[26px] rounded-md bg-[#00C22B] px-4 py-2 animate-slideIn">
          <div className="flex gap-x-4">
            <CheckBoxCircleFilled />
            <div className="mt-[-2px] text-sm font-semibold text-white">{slidingPopUpText}</div>
          </div>
        </div>
      )}

      {popUp && (
        <div className="fixed inset-0 z-20">
          <div className="flex min-h-screen items-center justify-center">
            <div onClick={() => setPopUp(false)} className="fixed inset-0" aria-hidden="true">
              <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
            </div>
            <div className="no-scrollbar flex h-screen overflow-y-auto py-4">
              <div className="m-auto w-[304px] h-[249px] transform rounded-[8] border border-new-accent bg-new-solid-white p-6 font-medium">
                <div className="relative flex">
                  <div className="text-base font-bold text-red-500">Problem NOT - Resolved</div>
                  <button onClick={() => setPopUp(false)} className="absolute right-0">
                    <CrossIcon className="h-6 w-6 text-[#312E81]" />
                  </button>
                </div>

                <div className="mt-[8px] text-[12px] font-[400] text-[#414141]">
                  {student["batch"] === "KCE"
                    ? "Do you want to connect with a Mentor regarding this problem"
                    : "Do you want to connect to doubt support team regarding this problem?"}
                </div>

                <div className="mt-2 text-[12px] font-normal leading-tight ">
                  <span className="w-[272px] text-[10px] font-normal leading-tight text-[#DC2626] font-[Inter]">
                    NOTE** Estimated time to resolve problem through{" "}
                    {student["batch"] === "KCE" ? "a Mentor." : "doubt support team."} is approx. 30 min - 1 Hr.{" "}
                  </span>
                </div>

                <div className="mt-12 mb-2 flex gap-x-1 justify-end">
                  <button
                    onClick={() => setPopUp(false)}
                    className="flex h-[35px] w-[79px] cursor-pointer items-center justify-center rounded border border-[#D3D3D3] text-[#EF4444] hover:bg-red-100"
                  >
                    <span className="text-[14px] font-[500]">Cancel</span>
                  </button>
                  <a
                    href={
                      "https://api.whatsapp.com/send?phone=919870258716&text=Hi,%0AI have a Doubt related to: " +
                      (module !== "" ? "%0AModule: " + module : "") +
                      (topic !== "" ? "%0ATopics: " + topic : "") +
                      (doubtResolvedFromTop !== "" ? "%0AQuestion: " + doubtResolvedFromTop : "") +
                      ".%0AI have gone through the provided solution on the website."
                    }
                    target="_blank"
                    onClick={async () => {
                      setPopUp(false)
                      await updateDoubtStatus(0, doubtResolvedFromTop)
                      setOnSubmit(false)
                    }}
                    className="flex h-[35px] w-[86px] cursor-pointer items-center justify-center rounded border bg-[#162456] text-white hover:bg-[#1C3474]"
                    rel="noreferrer"
                  >
                    <span className="text-[14px] font-[500]">Confirm</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {popUpResolved && (
        <div className="fixed inset-0 z-20">
          <div className="flex min-h-screen items-center justify-center">
            <div onClick={() => setPopUpResolved(false)} className="fixed inset-0" aria-hidden="true">
              <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
            </div>
            <div className="no-scrollbar flex h-screen overflow-y-auto py-4">
              <div className="m-auto w-[320px] transform rounded border border-new-accent bg-new-solid-white p-6 font-medium">
                <div className="relative flex">
                  <div className="text-base font-bold text-indigo-700">Problem Resolved</div>
                  <button onClick={() => setPopUpResolved(false)} className="absolute right-0">
                    <CrossIcon className="h-6 w-6 text-[#312E81]" />
                  </button>
                </div>

                <div className="mt-[6px] text-sm font-normal">
                  You are about to confirm that your problem has been resolved.
                </div>

                <div className="mt-3 flex gap-x-1">
                  <button
                    onClick={() => {
                      setPopUpResolved(false)
                      setPopUp(true)
                    }}
                    className="flex h-[36px] w-[134px] cursor-pointer items-center justify-center rounded border border-[#DC2626] text-[#DC2626] hover:bg-[#FFEDED]"
                  >
                    <span className="text-[12px] font-[400]">NO</span>
                  </button>
                  <button
                    onClick={async () => {
                      await updateDoubtStatus(1, doubtResolvedFromTop)
                      setInstantResolveCard(true)
                      setInstantResolve(true)
                      setPopUpResolved(false)
                      setResolve(!resolve)
                      setOnSubmit(false)
                    }}
                    className="flex h-[36px] w-[146px] cursor-pointer items-center justify-center rounded border text-[12px] font-[400] text-white bg-[#162456] hover:bg-[#1C3474]"
                  >
                    <span className="">YES</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="font-montserrat ml-6 mr-6 gap-y-[18px] overflow-hidden">
        <div className="mt-6">
          <h2 className={`text-[#333333] font-inter text-lg font-semibold leading-normal`}>
            {student["batch"] === "KCE" ? "Mentor Support" : "Doubt Support"}
          </h2>

          <div className="text-base text-gray-500 font-inter text-[14px] font-[400] leading-normal">
            {student["batch"] === "KCE"
              ? "This platform facilitates query resolution through mentor assistance."
              : "You can reach out to the Doubt Support Team whenever you find yourself stuck in any question or technical problem."}
          </div>
        </div>

        <div className="gap-y-[18px] mt-6">
          <div className="inline-flex gap-1 rounded border border-gray-200 bg-gray-200 p-1">
            <Button
              onClick={toggleValue1}
              className={`w-fit min-w-[150px] select-none rounded-md py-2 px-4 text-sm font-medium leading-[22px] ${
                index === 0 ? "bg-white text-gray-900" : "bg-gray-200 text-gray-500 hover:bg-white "
              }`}
            >
              <h4>Raise New Query/Doubt</h4>
            </Button>
            <Button
              onClick={toggleValue2}
              className={`w-fit min-w-[150px] select-none rounded-md py-2 px-4 text-sm font-medium leading-[22px] ${
                index === 1 ? "bg-white text-gray-900" : "bg-gray-200 text-gray-500 hover:bg-white"
              }`}
            >
              <h4>Problem History</h4>
            </Button>
          </div>
        </div>

        <div className="mt-6">
          {index === 0 ? (
            // Raise New Query/Doubt
            <div className="flex flex-col">
              <div className="flex gap-x-2">
                <div className="ml-1 shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.15)] relative h-[491px] w-[345px] rounded-[8px] border-[0.5px] bg-[#FFF] border-[#D3D3D3]">
                  <div className="m-6 flex flex-col gap-[24px]">
                    <h3 className="text-[16px] text-[#333333] -mb-2 font-[600]">Select Query Type :</h3>
                    <svg xmlns="http://www.w3.org/2000/svg" width="300" height="2" viewBox="0 0 300 2" fill="none">
                      <path d="M0 1L300 1.00003" stroke="#D3D3D3" />
                    </svg>
                    <div className="flex flex-col -mt-2">
                      <div className="flex flex-col gap-y-2">
                        <div className="h-[17px] w-auto flex gap-x-1 text-left text-[14px] font-[600] leading-[22px] tracking-normal">
                          <div className="text-[#333333]">Query Type</div>
                          <div className="text-red-600">*</div>
                        </div>
                        <DropDownMenu
                          id={1}
                          options={(() => {
                            if (student["batch"] === "KCE") {
                              return ["DSA", "Other"]
                            } else if (student["batch"].includes("EV") === true) {
                              return ["DSA", "System Design", "Operational Issue", "Other"]
                            } else if (student["batch"].includes("Accelerator") === true) {
                              return ["DSA", "Projects", "Operational Issue", "Other"]
                            } else {
                              return ["DSA", "Data Engineering", "DSML", "Projects", "Operational Issue", "Other"]
                            }
                          })()}
                          module={module}
                          setModule={setModule}
                          zindex="z-50"
                          index={1}
                        />
                      </div>

                      {module === "Other" ? (
                        <div />
                      ) : module === "Projects" ? (
                        <div />
                      ) : module === "Data Engineering" ? (
                        <div />
                      ) : module === "System Design" ? (
                        <div />
                      ) : module === "DSML" ? (
                        <div className="flex flex-col gap-y-2 mt-6">
                          <div className="h-[17px] w-[104px] flex gap-x-1 text-left text-[14px] font-[600] leading-[22px] tracking-normal">
                            <div className="text-[#333333]">Select Topics</div>
                            <div className="text-red-600">*</div>
                          </div>

                          <DropDownMenu
                            id={2}
                            options={[
                              "Excel",
                              "Tableau",
                              "Power BI",
                              "SQL",
                              "Python",
                              "Machine Learning",
                              "Deep Learning",
                              "Maths",
                              "Other",
                            ]}
                            module={module}
                            topic={topic}
                            setTopic={setTopic}
                            zindex="z-40"
                            index={1}
                          />
                        </div>
                      ) : module === "Operational Issue" ? (
                        <div className="flex flex-col gap-y-2 mt-6">
                          <div className="h-[17px] w-auto flex gap-x-1 text-left text-[14px] font-[600] leading-[22px] tracking-normal">
                            <div className="text-[#333333]">Select Issue</div>
                            <div className="text-red-600">*</div>
                          </div>
                          <DropDownMenu
                            id={2}
                            options={
                              student?.batch?.includes("Accelerator")
                                ? ["Other"]
                                : ["Batch Change", "Batch Pause", "Resume Course", "Mentor Change", "Other"]
                            }
                            module={module}
                            topic={topic}
                            ref={topicRef}
                            setTopic={setTopic}
                            zindex="z-40"
                            index={1}
                          />
                          {topic === "Other" ? <div /> : <div />}
                        </div>
                      ) : (
                        <div className="flex flex-col gap-y-2 mt-6">
                          <div className="h-[17px] w-auto flex gap-x-1 text-left text-[14px] font-[600] leading-[22px] tracking-normal">
                            <div className="text-[#333333]">Select Chapter</div>
                            <div className="text-red-600">*</div>
                          </div>
                          <DropDownMenu
                            id={2}
                            options={classTopics}
                            module={module}
                            topic={topic}
                            setTopic={setTopic}
                            zindex="z-40"
                            index={1}
                          />
                          {topic === "Other" ? (
                            <div />
                          ) : (
                            <div className="flex flex-col gap-y-2 mt-6">
                              <div className="h-[17px] w-auto flex gap-x-1 text-left text-[14px] font-[600] leading-[22px] tracking-normal">
                                <div className="text-[#333333]">Select Question</div>
                                <div className="text-red-600">*</div>
                              </div>

                              <DropDownMenu
                                id={3}
                                options={questions}
                                module={module}
                                topic={topic}
                                question={question}
                                setQuestion={setQuestion}
                                zindex="z-30"
                                index={1}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {module !== "Operational Issue" &&
                  (["Other", "Projects", "System Design", "Data Engineering"].includes(module) ||
                    (module === "DSML" &&
                      [
                        "Excel",
                        "Tableau",
                        "Power BI",
                        "SQL",
                        "Python",
                        "Maths",
                        "Machine Learning",
                        "Deep Learning",
                      ].includes(topic)) ||
                    topic === "Other" ||
                    question === "Other") ? (
                    <Tooltip title={!doubtSupportAvailable && message} placement="bottom-end">
                      <button
                        disabled={!doubtSupportAvailable}
                        className={`absolute bottom-32 left-0 ml-6 flex px-4 py-[9px] justify-center items-center gap-[10px] rounded-[4px] text-[14px] font-[500] cursor-pointer text-[white] ${
                          !doubtSupportAvailable ? "bg-[#D3D3D3]" : "bg-[#162456] hover:bg-[#1C3474]"
                        }`}
                        onClick={() => {
                          const url =
                            "https://api.whatsapp.com/send?phone=919870258716&text=Hi,%0AI have a question about a topic that isn't currently covered on the website. Can you assist me with this?"
                          window.open(url, "_blank")
                        }}
                      >
                        {str_human_mentor_support}
                      </button>
                    </Tooltip>
                  ) : (
                    <Tooltip title={!doubtSupportAvailable && message} placement="bottom-end">
                      <button
                        onClick={async () => {
                          if (module !== "Operational Issue") {
                            fetchDoubtQuerySolution(question)
                          }
                          setResolve(!resolve)
                          setSolution({})
                          setInstantResolveCard(false)
                          setOnSubmit(true)
                          setPendingDoubt({})
                        }}
                        className={`absolute bottom-30 left-0 ml-6 flex px-4 py-[9px] justify-center items-center gap-[10px] rounded-[4px] text-[14px] font-[500] cursor-pointer text-[white] ${
                          module === "" ||
                          topic === "" ||
                          (question === "" && module !== "Operational Issue") ||
                          !doubtSupportAvailable
                            ? "bg-[#D3D3D3]"
                            : "bg-[#162456] hover:bg-[#1C3474]"
                        }`}
                        disabled={
                          module === "" ||
                          topic === "" ||
                          (question === "" && module !== "Operational Issue") ||
                          !doubtSupportAvailable
                        }
                      >
                        Submit Query
                      </button>
                    </Tooltip>
                  )}
                </div>

                {!onSubmit ? (
                  // before submitting
                  <div className="flex h-[491px] w-full items-center justify-center rounded-[8px] border-[0.5px] p-6 font-normal ml-[20px]">
                    <div className="flex flex-col">
                      <div className="text-center">Select question first to</div>
                      <div className="text-center">show solution</div>
                    </div>
                  </div>
                ) : // after submitting
                module === "Operational Issue" ? (
                  <OperationalIssueCard
                    topic={topic}
                    setSlidingPopUpText={setSlidingPopUpText}
                    setSlidingPopUp={setSlidingPopUp}
                    setStudentBatchShift={setStudentBatchShift}
                    operationalChangesDone={operationalChangesDone}
                    setStudentBatchPause={setStudentBatchPause}
                    setMentor={setMentor}
                  />
                ) : isEmpty(solution) || Object.keys(solution).length === 0 ? (
                  <div className="flex h-[491px] w-full items-center justify-center border ml-[20px]">
                    <Loader />
                  </div>
                ) : solution["resources"][1]["link"].length == 0 ||
                  solution["resources"][0]["link"].length == 0 ||
                  solution["resources"][1]["link"].substring(0, 5) != "https" ||
                  solution["resources"][0]["link"].substring(0, 5) != "https" ? (
                  <div className="border-1 relative flex h-[491px] w-full flex-col justify-center border p-6 font-normal ml-[20px]">
                    <div className="flex flex-col gap-y-4">
                      <h2>Solution for this particular problem is not available on the website.</h2>
                      <h3>Please contact Human Doubt Support from here:</h3>
                      <button className="absolute bottom-8 left-0 ml-6 flex px-4 py-[9px] justify-center items-center gap-[10px] rounded-[4px] bg-[#D3D3D3] text-[14px] font-[500] hover:bg-[#162456] cursor-pointer text-[white]">
                        <a
                          href={
                            "https://api.whatsapp.com/send?phone=919870258716&text=Hi,%0AI have a Doubt related to: %0AModule: " +
                            module +
                            "%0ATopics: " +
                            topic +
                            "%0AQuestion: " +
                            question +
                            ".%0AI have gone through the provided solution on the website."
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {str_human_mentor_support}
                        </a>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="h-[491px] w-full items-center justify-center rounded-[8px] border-[0.5px] p-6 font-normal ml-[20px]">
                    <div className="-m-2 flex flex-col">
                      <p className="text-[14px] font-[600] text-[#333]">Doubt Raised Question number</p>
                      <div className="flex gap-x-1">
                        <h4 className="mt-1 block">
                          {solution["num_resolved"]}+
                          <span className="text-[14px] font-normal leading-normal text-[var(--Black-and-white-Gray-500,#646464)] font-[Inter]">
                            People find the solution for the problem relevant and helpful.
                          </span>
                        </h4>
                      </div>

                      <div className="mt-4 flex flex-col gap-x-4 align-stretch">
                        <div className="mt-4 grid gap-4 sm:grid-cols-2 xs:grid-cols-1">
                          <div className="w-full h-[117px] rounded-[8px] border border-[#E3E3E3] bg-[#FFF]">
                            <a
                              href={
                                solution["resources"][1]
                                  ? solution["resources"][1]["link"]
                                  : `https://api.whatsapp.com/send?phone=919870258716&text=Hi,%0AI have a Doubt related to: %0AModule: ${module}%0ATopics: ${topic}%0AQuestion: ${question}.%0AI have gone through the provided solution on the website.`
                              }
                              target="_blank"
                              rel="noreferrer"
                              className="flex flex-row justify-start items-center h-full px-3 gap-4"
                            >
                              <div className="cursor-pointer rounded-[4.279px]">
                                <VideoThumbnail h={93} w={184} />
                              </div>

                              <div className="flex flex-col items-start gap-y-8">
                                <span className="text-[#333333] text-[12px] font-[500] mt-1">Video Solution</span>
                                <button className="mt-2 mb-2 inline-flex px-4 py-[6px] justify-center items-center gap-[13px] cursor-pointer rounded-[4px] border-[0.5px] border-[#D3D3D3] bg-[#FFF] hover:bg-[#F5F5F5] hover:text-[#333333] active:bg-[#1C3474] active:text-[#FFF]">
                                  <span className="text-[#646464] text-[12px] font-[400]">Click To View</span>
                                </button>
                              </div>
                            </a>
                          </div>

                          <div className="w-full h-[117px] rounded-[8px] border border-[#E3E3E3] bg-[#FFF]">
                            <a
                              href={
                                solution["resources"][0]
                                  ? solution["resources"][0]["link"]
                                  : "https://api.whatsapp.com/send?phone=919870258716&text=Hi,%0AI have a Doubt related to: %0AModule: " +
                                    module +
                                    "%0ATopics: " +
                                    topic +
                                    "%0AQuestion: " +
                                    question +
                                    ".%0AI have gone through the provided solution on the website."
                              }
                              target="_blank"
                              rel="noreferrer"
                              className="flex flex-row justify-start items-center h-full px-3 gap-4"
                            >
                              <div className="cursor-pointer rounded-[4.279px] h-[93px] w-[184px]">
                                <BlogThumbnail h={94} w={184} />
                              </div>

                              <div className="flex flex-col items-start gap-y-8">
                                <span className="text-[#333333] text-[12px] font-[500] mt-1">Blog Article</span>
                                <button
                                  className="
            mt-2 mb-2 inline-flex px-4 py-[6px] justify-center items-center gap-[13px] cursor-pointer rounded-[4px] border-[0.5px] border-[#D3D3D3] bg-[#FFF] hover:bg-[#F5F5F5] hover:text-[#333333] active:bg-[#1C3474] active:text-[#FFF]
          "
                                >
                                  <span className="text-[#646464] text-[12px] font-[400]">Click To View</span>
                                </button>
                              </div>
                            </a>
                          </div>
                        </div>

                        {!instantResolveCard && (
                          <div className="border-1 w-full border p-6 mt-4 flex px-[16px] py-[12px] justify-between items-start self-stretch rounded-[8px] bg-[var(--color-shades-bw-gray-100,#F9F9F9)]">
                            <div className="relative flex flex-col">
                              <div className="text-[10px] font-medium">Was this solution helpful?</div>
                              <div className="relative flex gap-[9px] leading-[15px]">
                                <div className="flex items-center justify-center gap-x-1 text-[10px] font-semibold">
                                  Connect to{" "}
                                  {minutes >= 0 ? (
                                    <div className="cursor-not-allowed font-bold underline">
                                      {str_human_mentor_support}
                                    </div>
                                  ) : (
                                    <a
                                      className="cursor-pointer font-bold leading-[15px] underline"
                                      href={`https://api.whatsapp.com/send?phone=919870258716&text=Hi,%0AI have a Doubt related to: ${
                                        module !== "" ? "%0AModule: " + module : ""
                                      }${topic !== "" ? "%0ATopics: " + topic : ""}${
                                        doubtResolvedFromTop !== "" ? "%0AQuestion: " + doubtResolvedFromTop : ""
                                      }.%0AI have gone through the provided solution on the website.`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {str_human_mentor_support}
                                    </a>
                                  )}
                                </div>
                                <div className="relative">
                                  <div
                                    onMouseEnter={() => setInfoHover(true)}
                                    onMouseLeave={() => setInfoHover(false)}
                                    className="cursor-pointer py-4 inline-block"
                                  >
                                    <InfoButton width={24} height={24} />
                                  </div>

                                  {infoHover && (
                                    <div className="absolute bottom-[100%] left-1/2 transform -translate-x-1/2 -mb-2 z-50 w-max px-3 py-2 rounded-md border border-[#D3D3D3] bg-white text-[10px] text-[#162456] font-semibold text-center shadow-md">
                                      {minutes < 0 ? (
                                        <div>Click '{str_human_mentor_support}' for assistance.</div>
                                      ) : (
                                        <>
                                          <div>
                                            {str_human_mentor_support} button will be active in{" "}
                                            <span className="text-red-500">
                                              {minutes} min : {seconds} sec.
                                            </span>
                                          </div>
                                          <div>In the meantime, you can take a look at the provided solutions.</div>
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="mt-[2px] flex gap-[8px]">
                              <Button
                                className="flex h-auto w-auto cursor-pointer items-center justify-center px-4 py-[6px] rounded-md border-[0.5px] border-[#DC2626] text-[#DC2626] bg-[#FFF] hover:bg-red-100"
                                onClick={() => {
                                  setPopUp(true)
                                  setDoubtResolvedFromTop(solution["problem_id"])
                                }}
                              >
                                <span className="text-[12px]">NO</span>
                              </Button>
                              <Button
                                onClick={() => {
                                  setPopUpResolved(true)
                                  setDoubtResolvedFromTop(solution["problem_id"])
                                }}
                                className="h-auto w-auto cursor-pointer text-[#646464] inline-flex px-4 py-[6px] justify-center items-center rounded-md border-[0.5px] border-[var(--Black-and-white-Gray-400,#D3D3D3)] bg-[#FFF] hover:bg-gray-100"
                              >
                                <span className="text-[12px]">YES</span>
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="gap-y-6 mt-6">
              <div className="flex gap-1">
                <Button
                  onClick={() => {
                    handleButtonClick("All")
                    setSearchHistory({})
                    setCurrentlySearching(true)
                    setInstantResolve(false)
                  }}
                  className={`inline-flex w-auto h-[27px] px-4 py-1.5 justify-center items-center rounded ${
                    selectedButton === "All"
                      ? "bg-[#162456] text-white"
                      : "bg-[#F9F9F9] text-[#646464] hover:bg-[#F5F5F5]"
                  }`}
                >
                  <span className="font-inter text-[12px] font-medium leading-none">All</span>
                </Button>

                <Button
                  onClick={() => handleButtonClick("Resolved")}
                  className={`inline-flex w-auto h-[27px] px-4 py-1.5 justify-center items-center rounded ${
                    selectedButton === "Resolved"
                      ? "bg-[#162456] text-white"
                      : "bg-[#F9F9F9] text-[#646464] hover:bg-[#F5F5F5]"
                  }`}
                >
                  <span className="font-inter text-[12px] font-medium leading-none">Resolved</span>
                </Button>

                <Button
                  onClick={() => handleButtonClick("Not-resolved")}
                  className={`inline-flex w-auto h-[27px] px-4 py-1.5 justify-center items-center rounded ${
                    selectedButton === "Not-resolved"
                      ? "bg-[#162456] text-white"
                      : "bg-[#F9F9F9] text-[#646464] hover:bg-[#F5F5F5]"
                  }`}
                >
                  <span className="font-inter text-[12px] font-medium leading-none">Not-resolved</span>
                </Button>
              </div>
              {selectedButton === "All" && (
                <div className="gap-y-6 mt-6">
                  {isEmpty(history) ? (
                    <div className="flex h-[482px] w-full items-center justify-center border">
                      <Loader />
                    </div>
                  ) : history?.data?.length === 0 ? (
                    <div className="gap-y-[18px] mt-6">
                      <Button className="flex h-[125px] justify-center items-center self-stretch rounded-lg border border-[#DEDEDE] bg-white shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.05)] px-[220px]">
                        <span className="text-center text-[#D3D3D3] font-inter text-sm font-normal leading-[21px]">
                          There are no history of earlier doubt inquiry.
                        </span>
                      </Button>
                    </div>
                  ) : (
                    history?.data?.map((doubt: any) => (
                      <ProblemCardNew
                        history={doubt}
                        id={doubt["problem_id"]}
                        key={doubt["problem_id"]}
                        resolve={resolve}
                        setResolve={setResolve}
                        setDoubtResolvedFromTop={setDoubtResolvedFromTop}
                        setPopUp={setPopUp}
                        setPopUpResolved={setPopUpResolved}
                        setTopic={setTopic}
                      ></ProblemCardNew>
                    ))
                  )}
                </div>
              )}

              {selectedButton === "Resolved" && (
                <div className="gap-y-6 mt-6">
                  {isEmpty(history) ? (
                    <div className="flex h-[482px] w-full items-center justify-center border">
                      <Loader />
                    </div>
                  ) : history?.data?.filter((doubt: any) => doubt["progress"] === "RESOLVED" || doubt["instantResolve"])
                      .length === 0 ? (
                    <div className="gap-y-[18px] mt-6">
                      <Button className="flex h-[125px] justify-center items-center self-stretch rounded-lg border border-[#DEDEDE] bg-white shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.05)] px-[220px]">
                        <span className="text-center text-[#D3D3D3] font-inter text-sm font-normal leading-[21px]">
                          No resolved problems found.
                        </span>
                      </Button>
                    </div>
                  ) : (
                    history?.data
                      ?.filter((doubt: any) => doubt["progress"] === "RESOLVED" || doubt["instantResolve"])
                      ?.map((doubt: any) => (
                        <ProblemCardNew
                          history={doubt}
                          id={doubt["problem_id"]}
                          key={doubt["problem_id"]}
                          resolve={resolve}
                          setResolve={setResolve}
                          setDoubtResolvedFromTop={setDoubtResolvedFromTop}
                          setPopUp={setPopUp}
                          setPopUpResolved={setPopUpResolved}
                          setTopic={setTopic}
                        ></ProblemCardNew>
                      ))
                  )}
                </div>
              )}

              {selectedButton === "Not-resolved" && (
                <div className="gap-y-6 mt-6">
                  {isEmpty(history) ? (
                    <div className="flex h-[482px] w-full items-center justify-center border">
                      <Loader />
                    </div>
                  ) : history?.data?.filter(
                      (doubt: any) => doubt["progress"] !== "RESOLVED" && !doubt["instantResolve"]
                    ).length === 0 ? (
                    <div className="gap-y-[18px] mt-6">
                      <Button className="flex h-[125px] justify-center items-center self-stretch rounded-lg border border-[#DEDEDE] bg-white shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.05)] px-[220px]">
                        <span className="text-center text-[#D3D3D3] font-inter text-sm font-normal leading-[21px]">
                          All problems are resolved.
                        </span>
                      </Button>
                    </div>
                  ) : (
                    history?.data
                      ?.filter((doubt: any) => doubt["progress"] !== "RESOLVED" && !doubt["instantResolve"])
                      ?.map((doubt: any) => (
                        <ProblemCardNew
                          history={doubt}
                          id={doubt["problem_id"]}
                          key={doubt["problem_id"]}
                          resolve={resolve}
                          setResolve={setResolve}
                          setDoubtResolvedFromTop={setDoubtResolvedFromTop}
                          setPopUp={setPopUp}
                          setPopUpResolved={setPopUpResolved}
                          setTopic={setTopic}
                        ></ProblemCardNew>
                      ))
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        {index === 0 ? (
          isEmpty(pendingDoubt) ? (
            <div className="mt-4 flex h-[293px] items-center justify-center p-4 rounded-lg border-[0.5px] border-[#D3D3D3]">
              <Loader />
            </div>
          ) : (
            pendingDoubt?.data.length > 0 && (
              <div
                className="p-6 rounded-lg border-[0.5px] border-[#D3D3D3]
              bg-white shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.15)] mt-6 mb-6 ml-1 "
              >
                <div className="flex flex-col items-start gap-6 py-2 overflow-x-auto custom-scrollbar-3 w-auto h-auto">
                  <p className="w-auto h-auto text-[#333] text-[16px] font-[600]">Doubts that are not resolved yet :</p>
                  <div className="relative flex gap-x-4">
                    {pendingDoubt.data.map((doubt: any, index: any) => (
                      <div
                        id={index}
                        key={index}
                        className={cn(
                          "shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.15)] w-[158px] flex-shrink-0 -mt-2 border-[0.5px] rounded-lg border-[#D3D3D3]"
                        )}
                      >
                        <div className="flex flex-col">
                          <div className="relative flex gap-x-2 p-1 overflow-hidden">
                            <span
                              className="mt-4 ml-4 h-[40px] w-[100%] break-words text-[14px] font-[600] text-[#312E81]"
                              title={doubt["problem_name"]}
                            >
                              {doubt["problem_name"].length > 30
                                ? doubt["problem_name"].substring(0, 30) + "..."
                                : doubt["problem_name"]}
                            </span>
                          </div>

                          <div className="mx-5 mb-4">
                            <div className="">
                              <div className="flex gap-x-[2px] text-[10px] font-[500] text-[#646464]">
                                <div className="">Date:</div>
                                <div className="">
                                  {format(new Date(parseInt(doubt["timestamp"], 10) * 1000), "do MMM, yyyy")}
                                </div>
                              </div>

                              <h5 className="h-[12px] w-[126px] text-[10px] font-[500] text-[#646464]">
                                Status : Not Resolved
                              </h5>
                            </div>
                            <div className="mt-6">
                              <Button
                                onClick={() => {
                                  setTopic("")
                                  setModule("")
                                  setDoubtResolvedFromTop(doubt["problem_id"])
                                  setPopUpResolved(true)
                                }}
                                className="flex justify-center items-center gap-2.5 self-stretch p-[9px_16px] rounded-md text-[14px] font-[500] text-[#FFFFFF] bg-[#162456] hover:bg-[#1C3474] cursor-pointer"
                              >
                                Resolved
                              </Button>
                              <a
                                href={"#" + doubt["problem_id"]}
                                onClick={() => {
                                  setIndex(1)
                                  setCurrentlySearching(false)
                                }}
                                className="mt-2 flex justify-center items-center gap-[13px] self-stretch p-[9px_16px] rounded-md border border-gray-400 bg-white
                cursor-pointer text-[14px] font-[500] text-[#646464] hover:bg-blue-100"
                              >
                                <div className="">Show Detail</div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )
          )
        ) : (
          <span />
        )}
      </div>
    </DashboardLayoutv2>
  )
}
