import StarRating from "core/components/StarRating"
import Avatar from "react-avatar"
import { LinkedInIcon } from "core/constants/svgs"
import Alumni from "assets/svgs/Alumni"
import { useState } from "react"
import { cn } from "core/lib/utils"
import Linkedin_icon from "assets/images/Linkedin_icon.png"

export default function CurrentMentorCard(props: any) {
  const [isHovered, setIsHovered] = useState(false)
  const [infoHover, setInfoHover] = useState(false)

  return (
    <div
      className={cn(
        "flex flex-col items-start gap-4 self-stretch px-6 py-4 rounded-lg border-[0.5px] border-[#D3D3D3] bg-[#FFF] shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.05)]",
        props.state === "Selected" ? "sticky bottom-0 bg-[#F5F5F5] pt-[2px]" : "relative"
      )}
    >
      <div className="flex flex-col justify-between items-start">
        <div className="flex">
          <Avatar
            className="shrink-0 rounded-md object-cover bg-lightgray bg-center bg-cover bg-no-repeat"
            src={props.details["photo"]}
            name={props.details["name"]}
            size="67"
          />
          <div className="flex flex-col">
            <div className="flex w-full flex-1 justify-between">
              <div className="flex">
                <div className="flex flex-col gap-4 ml-4">
                  <div className="flex gap-x-2">
                    <div className="text-[#333] text-[14px] font-[600]">{props.details["name"]}</div>
                    <div className="text-[#FBBF24] text-[14px] font-[500]">
                      {Math.round(props.details["rating"] * 10) / 10} Rating
                    </div>
                  </div>
                  <div className="flex -mt-4">
                    <div className="flex gap-1 text-[#646464] text-[12px] font-[400]">
                      <span>
                        {props.details["current_company"]} | {props.details["experience"]}Years of Exp.{" "}
                      </span>
                    </div>
                    {props.details["is_alumni"] && (
                      <div className="flex gap-1 text-[#646464] text-[12px] font-[400] mx-1">
                        |
                        <div className="text-[#333] text-[12px]">
                          <span className="font-[600]">Bosscoder Alumni</span>
                        </div>
                      </div>
                    )}

                    <span className="text-[#333] text-[12px] font-[400] mx-1">|</span>
                    <span className="text-[#07B42D] text-[12px] font-[600]">Recommended</span>
                  </div>
                </div>
                {props.state === "Current" ? (
                  <div className="text-sm font-semibold text-new-success">Current Mentor</div>
                ) : props.state === "Previous" ? (
                  <div className="text-sm font-semibold text-[#DC2626]">Previous Mentor</div>
                ) : props.state === "Select" || props.state === "Selected" ? (
                  <div onMouseEnter={() => setInfoHover(true)} onMouseLeave={() => setInfoHover(false)}>
                    {props.state === "Selected" ? (
                      <button className="flex px-4 py-[9px] justify-center items-center gap-[13px] self-stretch rounded-md absolute top-4 right-6 border-[1px] border-[#D3D3D3] bg-[#07B42D] text-[14px] font-[500] text-white cursor-default">
                        Selected
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          props.setSelectedMentor(props.details)
                          props.setOpenMentorToSelect(false)
                        }}
                        disabled={props.details["bandwidth"] >= 1000}
                        className={cn(
                          "flex px-4 py-[9px] justify-center items-center gap-[13px] self-stretch rounded-md absolute top-4 right-6 border-[1px] border-[#D3D3D3] bg-[#FFF] text-[14px] font-[500] text-[#646464] hover:bg-[#bbc9ef]",
                          props.details["bandwidth"] >= 1000 && "cursor-not-allowed opacity-60"
                        )}
                      >
                        Select Mentor
                        {infoHover && props.details["bandwidth"] >= 1000 && (
                          <div className="absolute left-[-128px] top-[-29px] flex h-[28px] w-[125px] items-center justify-center rounded-sm bg-black">
                            <h5 className="font-semibold text-white">Currently Unavailable</h5>
                          </div>
                        )}
                      </button>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex ml-4 -mt-8">
              <a href={props.details["linkedin"]} target="_blank" rel="noreferrer">
                <img
                  src={Linkedin_icon}
                  alt="LinkedIn"
                  className="h-4 w-4 object-cover bg-center bg-cover bg-no-repeat"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
