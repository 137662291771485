export default function BulletPoint3() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect width="16" height="16" rx="2" fill="#2C4C9F" />
      <circle cx="5" cy="10" r="2" fill="white" />
      <circle cx="8" cy="5" r="2" fill="white" />
      <circle cx="11" cy="10" r="2" fill="white" />
    </svg>
  )
}
