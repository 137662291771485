import { useState } from "react"

export default function ScrollCard(props: any) {
  const [isHovered, setIsHovered] = useState(false)
  const [infoHover, setInfoHover] = useState(false)

  return (
    <div className="w-full h-auto rounded-lg border-[0.5px] border-[#D3D3D3] bg-[#FFF] shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.05)]">
      <>
        <div className="m-4 flex flex-col gap-4">
          <div className="flex w-full flex-col justify-between">
            <div className="flex gap-1 justify-between items-center">
              <span className="text-[#333] text-[14px] font-[600]">{props.details["name"]}</span>
              <span className="text-[#07B42D] text-center text-[14px] font-[600] -mt-3">Selected Mentor</span>
            </div>
            <div className="">
              <span className="text-[#646464] text-[12px] font-[400]">
                {props.details["current_company"]} | {props.details["experience"]} Years of Exp.
              </span>
            </div>
            <div className="flex">
              <button className="flex px-4 py-[9px] w-auto items-end justify-between gap-[13px] rounded-md border-[1px] border-[#D3D3D3] bg-[#FFF] ml-auto">
                <a href={props.details["linkedin"]} target="_blank" rel="noreferrer">
                  <span className="text-[#646464] text-[14px] font-[500] w-auto">Linkedin Id</span>
                </a>
              </button>
            </div>
            <div className="-mt-5">
              <span className="text-[#FBBF24] text-14px] font-[500]">
                {" "}
                {Math.round(props.details["rating"] * 10) / 10} Rating{" "}
              </span>
            </div>
          </div>
        </div>
      </>
    </div>
  )
}
