import { cn } from "core/lib/utils"

export default function EdgeCasePage(props: any) {
  return (
    <div className="border-1 flex h-[491px] w-full rounded-[8px] border-[0.5px] ml-[20px]">
      <div className="m-auto flex h-auto w-auto flex-col gap-y-[10px] justify-center items-center text-center">
        <div className="h-auto w-auto m-auto mb-0 text-[18px] font-[600] text-[#162456]">{props.h1}</div>
        <div className="flex flex-col gap-y-1">
          <div className="h-auto w-auto m-auto text-[14px] font-[600] text-[#DC2626]">{props.h2}</div>
          <div
            className={cn(
              "h-auto w-auto m-auto text-[12px] font-[400] text-[#646464] text-center font-inter",
              props.className
            )}
          >
            {props.h3}
          </div>
        </div>
      </div>
    </div>
  )
}
