import { cn } from "core/lib/utils"

export default function Cross({ className }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#FFF"
    >
      <rect width="24" height="24" rx="2" fill="white" fillOpacity="0.6" />
      <path d="M18 6L6 18" stroke="#646464" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 6L18 18" stroke="#646464" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
