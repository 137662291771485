import { CrossIcon } from "core/constants/svgs"
import { useEffect } from "react"

export default function BatchShiftModal(props: any) {
  const handleOperationalIssue = () => {
    if (props.topic === "Mentor Change") {
      props.setMentor(props.selectedMentor["email"], props.reason)
      props.setSlidingPopUpText("Mentor Changed Successfully")
      props.setSlidingPopUp(true)
      setTimeout(() => {
        window.location.reload()
      }, 2000)
      setTimeout(() => {
        props.setSlidingPopUp(false)
      }, 3000)
    } else if (props.topic === "Batch Pause") {
      props.setStudentBatchPause(props.reason)
      props.setSlidingPopUpText("Course Paused Successfully")
      props.setSlidingPopUp(true)
      setTimeout(() => {
        window.location.reload()
      }, 2000)
      setTimeout(() => {
        props.setSlidingPopUp(false)
      }, 3000)
    } else {
      props.setStudentBatchShift(
        props.futureBatchDes["rows"][props.selectedBatch]["batch"][0] === "B"
          ? props.futureBatchDes["rows"][props.selectedBatch]["batch"]
              .slice(0, 3)
              .concat(props.futureBatchDes["rows"][props.selectedBatch]["batch"][4] === "A" ? "A" : "B")
          : props.futureBatchDes["rows"][props.selectedBatch]["batch"],
        props.reason,
        props.topic === "Resume Course" ? 1 : 0,
        props.discordUsername
      )
      props.topic === "Resume Course"
        ? (props.setSlidingPopUpText("Course Resumed Successfully"),
          props.setSlidingPopUp(true),
          setTimeout(() => {
            window.location.reload()
          }, 2000),
          setTimeout(() => {
            props.setSlidingPopUp(false)
          }, 3000))
        : (props.setSlidingPopUpText("Batch Changed Successfully"),
          props.setSlidingPopUp(true),
          setTimeout(() => {
            window.location.reload()
          }, 2000),
          setTimeout(() => {
            props.setSlidingPopUp(false)
          }, 3000))
    }
    props.setIsOpen(false)
  }

  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={() => props.setIsOpen(false)} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>

        <div className="no-scrollbar flex items-center justify-center h-screen overflow-y-auto py-4">
          <div className="relative w-auto h-auto transform rounded-lg bg-white shadow-[-1px_1px_4px_0px_rgba(34,41,48,0.20)] border border-new-accent p-6 font-medium">
            <div className="relative flex justify-between items-center">
              <div className="text-[16px] font-[500] h-auto w-auto text-[#333333]">{props.title}</div>
              <button onClick={() => props.setIsOpen(false)}>
                <CrossIcon className="h-[24px] w-[24px] text-[#646464]" />
              </button>
            </div>

            <div className="w-[272px] mt-4 text-[12px] font-[400]">{props.heading}</div>
            <div className="mt-[22px] flex gap-x-2.5 justify-end items-end">
              <button
                onClick={() => props.setIsOpen(false)}
                className="h-[auto] w-[auto] inline-flex px-4 py-2 gap-3 rounded border border-gray-400 bg-white
        cursor-pointer items-center justify-center text-[14px] font-[500] text-[#D41B1B] hover:bg-red-100"
              >
                Cancel
              </button>
              <button
                onClick={handleOperationalIssue}
                className="inline-flex px-4 py-2 gap-2.5 rounded bg-[#162456] hover:bg-[#1C3474] text-white font-500 text-[14px] leading-normal font-inter
        h-[auto] w-[auto] cursor-pointer items-center justify-center"
              >
                Confirm Change
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
