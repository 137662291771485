import React, { useState } from "react"
import { EllipseIcon } from "core/constants/svgs"
import { format } from "date-fns"
import Button from "core/components/Button"
import ChevronDown from "assets/svgs/ChevronDown"
import ChevronUp from "assets/svgs/ChevronUp"
import YellowProblemHistoryRectangle from "assets/svgs/YellowProblemHistoryRectangle"
import GreenProblemHistoryRectangle from "assets/svgs/GreenProblemHistoryRectangle"
import { cn } from "core/lib/utils"
import QuestionThumbnail from "assets/images/QuestionThumbnail.png"
import DoubtSupportViewModel from "../DoubtSupportViewModel"
import VideoThumbnail from "assets/svgs/VideoThumbnail"
import BlogThumbnail from "assets/svgs/BlogThumbnail"

export default function ProblemCardNew(props: any) {
  const { updateDoubtStatus, searchStudentDoubt } = DoubtSupportViewModel()
  const date: any = parseInt(props.history["timestamp"], 10) * 1000

  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div
      id={props.id}
      className={cn(
        "relative m-4 flex h-[183px] ml-1 self-stretch rounded-lg border-[0.5px] border-[#DEDEDE] bg-white shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.15)]",
        isOpen ? "h-[400px]" : "h-[183px]"
      )}
    >
      <div className="m-4 gap-x-4 w-full">
        <div className="relative w-full">
          <div className="flex flex-col gap-y-[10px]">
            <div className="flex flex-col gap-y-1 mb-0">
              <div className="w-full flex justify-between items-center">
                <h4 className="text-[12px] font-semibold font-inter text-[#646464]">
                  {format(new Date(parseInt(date)), "do MMMMMMMMMMMMM, yyyy")}
                </h4>
                <div
                  className={cn(
                    "text-[#07B42D] align-right font-[Inter] text-[18px] font-[600] -mt-2",
                    props.instantResolve || props.history["progress"] === "RESOLVED" ? "" : "hidden"
                  )}
                >
                  Resolved
                </div>
              </div>
              <div className="flex gap-x-1 mt-1">
                <h3 className="text-[18px] font-semibold font-inter text-[#0C0C0C] w-[354px]">
                  {props.history["problem_name"]}
                </h3>
              </div>
            </div>

            <div className="relative w-full flex flex-row justify-between items-center text-sm">
              <div className="flex flex-row gap-x-1 items-center mt-1">
                <div className="h-auto w-auto text-[#FBBF24] text-center font-inter text-[12px] font-semibold leading-none px-[6px] py-0.5 justify-center items-center rounded-sm bg-[#FEFFE4]">
                  {props.history["num_queries_raised"]}+
                </div>
                <div className="text-[#646464] font-inter text-[14px] font-normal leading-none">
                  People raised the same problem
                </div>
              </div>

              <div
                className={cn(
                  "flex gap-x-[10px] ml-auto justify-end items-start -mt-5",
                  props.instantResolve || props.history["progress"] === "RESOLVED" ? "hidden" : ""
                )}
              >
                <Button
                  onClick={async () => {
                    props.setDoubtResolvedFromTop(props.id)
                    props.setPopUp(true)
                    props.setTopic("")
                  }}
                  className="inline-flex items-center justify-center gap-[13px] px-[16px] py-[9px] rounded-[4px] border border-[#D3D3D3] bg-white hover:bg-red-100"
                >
                  <span className="text-[#D41B1B] font-inter text-[14px] font-medium leading-none">Not-Resolved</span>
                </Button>
                <Button
                  onClick={async () => {
                    await searchStudentDoubt(props.history["problem_name"])
                    props.setResolve(!props.resolve)
                    props.setDoubtResolvedFromTop(props.id)
                    props.setPopUpResolved(true)
                    props.setTopic("")
                  }}
                  className="inline-flex items-center justify-center gap-[13px] px-[16px] py-[9px] whitespace-nowrap rounded border border-[#D3D3D3] bg-white hover:bg-[#F5F5F5]"
                >
                  <span className="text-[#646464] font-medium text-[14px] leading-none font-inter">Mark Resolved</span>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div
          onClick={toggleDropdown}
          className={cn(
            "absolute top-[110px] left-5 right-5 flex flex-col",
            isOpen
              ? " flex flex-col items-start h-auto bg-[#F9F9F9] rounded-lg py-3 px-4 gap-4"
              : "h-[65px] items-center justify-center cursor-pointer"
          )}
        >
          {isOpen ? (
            <div className="flex items-center justify-between w-full cursor-pointer mt-2]">
              <div className="text-[#646464] font-inter text-[14px] font-[400] leading-normal">Show Solution</div>
              <ChevronUp className={cn("h-4 w-4 border", isOpen && "rotate-180")} />
            </div>
          ) : (
            <div
              className={cn(
                "flex w-full h-auto px-[212px] py-[12px] justify-center items-center gap-2 rounded-md bg-[#F9F9F9] hover:border-[1px] hover:border-[#D3D3D3]",
                isOpen && "mt-4"
              )}
            >
              <div className="text-center text-[14px] font-[400]">Show Solution</div>
              <div className="inline-flex items-center justify-center p-1">
                <ChevronDown className="h-4 w-4" />
              </div>
            </div>
          )}
          {isOpen && (
            <div className="flex flex-col items-start -mt-2">
              <h2 className="text-[#333] text-[14px] font-[600] ">{props.history["problem_name"]}</h2>
              <div className="flex gap-x-1">
                <h4 className="text-[#646464] text-[14px] font-[400]">
                  {props.history["num_resolved"]}+ People find the solution for the problem relevant and helpful.
                </h4>
              </div>
              <div className="mt-5 flex gap-4 self-stretch">
                <div className="w-full h-auto rounded-lg border border-[#E3E3E3] bg-[#FFF] self-stretch">
                  <a
                    href={props.history["resources"][1] ? props.history["resources"][1]["link"] : "www.google.com"}
                    target="_blank"
                    rel="noreferrer"
                    className="flex flex-row items-center gap-4 p-3"
                  >
                    <div className="cursor-pointer rounded-[4.279px]">
                      <VideoThumbnail h={93} w={184} />
                    </div>

                    <div className="flex flex-col items-start gap-y-7">
                      <span className="text-[#333] text-[12px] font-[500] mt-1">Video Solution</span>
                      <button className="inline-flex px-[16px] py-[6px] mt-2 cursor-pointer justify-center items-center rounded-[4px] border-[0.5px] border-[#D3D3D3] bg-[#FFF] hover:bg-[#F5F5F5] hover:text-[#333333] active:bg-[#1C3474] active:text-[#FFF]">
                        <span className="text-[#646464] text-[12px] font-[400]">Click To View</span>
                      </button>
                    </div>
                  </a>
                </div>

                <div className="w-full h-auto rounded-[8px] border border-[#E3E3E3] bg-[#FFF]">
                  <a
                    href={props.history["resources"][0] ? props.history["resources"][0]["link"] : "www.google.com"}
                    target="_blank"
                    rel="noreferrer"
                    className="flex flex-row items-center gap-4 p-3"
                  >
                    <div className="cursor-pointer rounded-[4.279px]">
                      <BlogThumbnail h={93} w={184} />
                    </div>

                    <div className="flex flex-col items-start gap-y-7">
                      <span className="text-[#333] text-[12px] font-[500] mt-1">Blog Article</span>
                      <button className="inline-flex px-[16px] py-[6px] mt-2 cursor-pointer justify-center items-center gap-[13px] rounded-[4px] border-[0.5px] border-[#D3D3D3] bg-[#FFF] hover:bg-[#F5F5F5] hover:text-[#333333] active:bg-[#1C3474] active:text-[#FFF]">
                        <span className="text-[#646464] text-[12px] font-[400]">Click To View</span>
                      </button>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
