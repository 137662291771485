import coin from "../../../assets/images/coinv2.png"

interface CoinCardProps {
  coins: number
}

const CoinCard: React.FC<CoinCardProps> = ({ coins }) => {
  const formattedCoins = new Intl.NumberFormat().format(Number(coins))

  return (
    <div
      className="flex flex-col border-2 border-[#FBBF24] w-[140px] h-[87px] px-4 py-3 rounded-[4px] bg-[white] justify-center items-center"
      style={{ boxShadow: "5px 5px 0px 0px #FBBF24" }}
    >
      <div className="flex w-auto h-auto mb-[10px]">
        <img src={coin} alt="Coin" />
      </div>

      <p className="w-auto font-semibold text-[16px] text-[#FBBF24]">{formattedCoins}</p>
    </div>
  )
}

export default CoinCard
