import { CrossIcon } from "core/constants/svgs"
import CurrentMentorCard from "./CurrentMentorCard"
import { cn } from "core/lib/utils"
import LinkedInIcon from "assets/svgs/LinkedInIcon"
import StarRating from "core/components/StarRating"
import Avatar from "react-avatar"

export default function SelectMentorModal(props: any) {
  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={() => props.setOpenMentorToSelect(false)} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>
        <div className="flex flex-col py-4 w-auto h-auto overflow-y-auto">
          <div className="relative m-auto w-auto h-auto gap-4 rounded-lg bg-[#FFF] px-6 py-[22px] items-start shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.15)]">
            {/* content goes here */}
            <div className="relative w-[800px] flex px-6 py-4 items-start gap-[38px] rounded-lg border-[0.5px] border-[#E3E3E3] bg-[#FFF] shadow-[0px_3px_7px_0px_rgba(0,0,0,0.10)]">
              <div className="flex flex-col justify-start">
                <div className="text-[#333] text-[16px] font-[600]">Select New Mentor</div>
                <div className="text-[#646464] text-[14px] font-[400]">
                  Select your new mentor from the available list of mentors.
                </div>
              </div>
              <button onClick={() => props.setOpenMentorToSelect(false)} className="absolute top-4 right-6">
                <CrossIcon className="h-6 w-6" />
              </button>
            </div>

            <div className="mt-4 w-[800px] p-4 flex h-auto flex-col gap-y-4 rounded-lg border-[0.5px] border-[#E3E3E3] bg-[#FFF] shadow-[0px_3px_7px_0px_rgba(0,0,0,0.10)] max-h-[500px] ">
              <div className="flex flex-col">
                <div className="text-[#333] text-[16px] font-[600]">Your mentor list</div>
                <div className="text-[#646464] text-[14px] font-[400]">Select any one mentor to proceed further.</div>
              </div>
              <div className="p-2 overflow-y-auto custom-scrollbar-3 space-y-4">
                {props.mentors.map((mentor: any, i: number) => (
                  <CurrentMentorCard
                    key={i}
                    details={mentor}
                    state="Select"
                    setSelectedMentor={props.setSelectedMentor}
                    setOpenMentorToSelect={props.setOpenMentorToSelect}
                  />
                ))}
              </div>
              {Object.keys(props.selectedMentor).length === 0 ? (
                <div className="m-6 absolute left-0 bottom-0 z-10 flex flex-col px-6 py-4 h-auto w-[800px] items-start gap-[10px] rounded-sm justify-center bg-[#F9F9F9] shadow-[0px_3px_7px_0px_rgba(0,0,0,0.10)] ">
                  <div className="text-[#333] text-[14px] font-[600]">Mentor not yet selected!</div>
                </div>
              ) : (
                <div className="m-6 absolute left-0 bottom-0 z-10 flex flex-col px-6 py-4 h-auto w-[800px] items-start gap-[10px] rounded-sm justify-center bg-[#F9F9F9] shadow-[0px_3px_7px_0px_rgba(0,0,0,0.10)]">
                  <div className="flex flex-col gap-2">
                    <div className="text-[#333] text-[14px] font-[600]">Selected Mentor</div>
                    <div className="text-[#333] text-[12px] font-[400] -mt-2">{props.selectedMentor["name"]}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
