export default function ChevronDown(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <rect y="0.5" width="16" height="16" rx="2" fill="#F9F9F9" />
      <path
        d="M7.99999 9.28047L11.3 5.98047L12.2427 6.92314L7.99999 11.1658L3.75732 6.92314L4.69999 5.98047L7.99999 9.28047Z"
        fill="#646464"
      />
    </svg>
  )
}
