import { cn } from "core/lib/utils"
import { isEmpty } from "core/utils/misc"
import { useState } from "react"

export default function OperationalIssueTable({ data, outlined, OperationalIssue, futureBatchEligiblity }: any) {
  const [infoHover, setInfoHover] = useState<any>(false)
  const [index, setIndex] = useState<any>()
  const [rowIndex, setRowIndex] = useState<any>()
  const [hoverContent, setHoverContent] = useState<any>("")

  return (
    <div className="rounded-md">
      {!isEmpty(data?.rows) ? (
        data?.rows?.map((row: any, ind: any) => (
          <div className="mb-4 group relative bg-new-solid-white border-[1px] rounded-md" key={ind}>
            {Object.keys(row).map((key: any, i: any) => (
              <div
                key={key}
                className={cn(
                  "relative whitespace-nowrap px-6 py-4 font-medium text-new-neutral-shallow group-hover:text-new-neutral-dark rounded-md",
                  i > 0 && i === Object.keys(row).length - 1 && "text-right",
                  i === 0 ? "text-[12px] font-[500] text-[#333333] p-4 pb-0 bg-white" : "",
                  i === 1 ? "w-[110px] h-[auto] text-[10px] font-[500] text-[#646464] p-4 pt-0 pb-0 bg-white" : "",
                  i === 2 ? "w-[auto] pt-0 -mt-8" : "",
                  futureBatchEligiblity &&
                    futureBatchEligiblity[ind]["is_batch_open_to_choose"] === false &&
                    "opacity-50"
                )}
                onMouseEnter={() => {
                  setIndex(i)
                  setRowIndex(ind)
                  setInfoHover(true)
                  setHoverContent(row[key])
                }}
                onMouseLeave={() => setInfoHover(false)}
              >
                {OperationalIssue ? (row[key].length > 30 ? row[key].slice(0, 30) + "..." : row[key]) : row[key]}
              </div>
            ))}
            {ind == rowIndex &&
              index === 2 &&
              futureBatchEligiblity &&
              !futureBatchEligiblity[rowIndex]["is_batch_open_to_choose"] &&
              infoHover && (
                <div className="absolute right-[45px] top-[-28px] z-50 flex items-center justify-center rounded-sm bg-[#312E81] py-1 px-2 opacity-100">
                  <div className="text-[12px] font-medium text-white">
                    Selection for this batch is only possible once it commences
                  </div>
                </div>
              )}
            {ind == rowIndex && hoverContent.length > 30 && index === 1 && OperationalIssue && infoHover && (
              <div className="absolute left-[250px] top-[-28px] z-50 flex items-center justify-center rounded-sm bg-[#312E81] py-1 px-2 opacity-100">
                <div className="text-[12px] font-medium text-white">{data?.rows[rowIndex]["status"]}</div>
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="h-[50px] w-full bg-new-misc-gray">
          <td colSpan={data?.header?.length} className="py-2 text-center font-medium leading-6 text-new-neutral-light">
            No data available
          </td>
        </div>
      )}
    </div>
  )
}
