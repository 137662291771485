import { Auth } from "domain/model/Auth"
import { DoubtSupportRepository } from "domain/repository/Student/DoubtSupportRepository"

export interface StudentBatchShiftUseCase {
  invoke(auth: Auth, batch: any, reason: any, is_batch_resume: any, discord_username: any): Promise<any>
}

export class StudentBatchShift implements StudentBatchShiftUseCase {
  private repository: DoubtSupportRepository

  constructor(repository: DoubtSupportRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, batch: any, reason: any, is_batch_resume: any, discord_username: any) {
    console.log(batch)
    return this.repository.studentBatchShift(auth, batch, reason, is_batch_resume, discord_username)
  }
}
