export const FLASK_PROD = "https://backend-flask-dot-bosscoderplatformindia.el.r.appspot.com"
export const NODE_PROD = "https://backend-node-dot-bosscoderplatformindia.el.r.appspot.com"
export const FLASK_STAG = "https://backend-flask-dot-bosscoderplatform.uc.r.appspot.com"
export const NODE_STAG = "https://backend-node-dot-bosscoderplatform.uc.r.appspot.com"
export const FLASK_DEV = "http://localhost:8080"
export const NODE_DEV = "http://localhost:8000"
export const API_V4_LOGIN = "v4_login"
export const API_GET_ICPSTUDENTS_FROM_ACTIVE_BATCHES = "get_ICPstudents_from_active_batches"
export const API_GET_ICPSTUDENTS_FROM_NON_ACTIVE_BATCHES = "get_ICPstudents_from_non_active_batches"
export const API_GET_ICPSTUDENTS_FROM_BATCHES = "get_ICPstudents_from_batches"
export const API_ALL_QUESTIONS = "all_questions"
export const API_GET_NEXT_100_QUESTIONS = "get_next_100_questions"
export const API_V4_REFRESH = "v4_refresh_token"
export const API_V4_RESET = "v4_reset_password"
export const API_V4_SIGNUP = "v4_signup"
export const API_V4_STUDENT = "v4_student"
export const API_V4_ADMIN = "v4_admin"
export const API_V3_ADMIN = "admin"
export const API_SEND_LOGIN_DETAILS = "send_login_details"
export const API_DOCUMENT_VERIFIED = "document_verified"
export const API_v4_UPDATE_DISABLE_STATE = "update_disable_state"
export const API_v4_UPDATE_PAID_TO_UNPAID = "convert_to_paid_user"
export const API_V4_GET_NEXT_100_STUDENTS = "get_next_100_students"
export const API_V4_TOGGLE_WEEKLY_TEST_COMPLETED_STATUS = "toggle_weekly_test_complete_status"
export const API_V4_GET_USER_DATA = "userprofile"
export const API_GET_ALL_ASSESSMENT = "get_all_assessment"
export const API_CONTEST = "contest"
export const API_GET_CONTEST_RESULT = "get_contest_result"
export const API_V3_MENTORPROFILE = "mentorprofile"
export const API_V3_UPDATEMENTEESMENTOR = "update_mentees_mentor"
export const API_V3_DELETE_MENTEE = "delete_mentee"
export const API_V4_GET_MY_REFERRED_CANDIDATES = "my_referred_candidates"
export const API_V4_REFER_CANDIDATE = "refer_candidate"
export const API_v3_UPDATE_MENTOR_PROFILE = "update_mentor_profile"
export const API_V4_GET_ALL_MODULES = "get_all_modules"
export const API_V4_GET_ALL_TRACKS = "get_all_tracks"
export const API_V4_CREATE_CLASS = "create_class"
export const API_GET_QUESTION_WITH_ANSWER = "get_question_with_answer"
export const API_V4_CREATE_BATCH = "create_batch"
export const API_V4_SSM_CALL_HISTORY = "get_ssm_details"
export const API_V4_CREATE_SSM_CALL = "create_ssm"
export const API_V4_DELETE_SSM_CALL = "delete_ssm"
export const API_V4_UPDATE_SSM_CALL = "update_ssm"
export const API_V4_UPDATE_SSM_CALL_DETAIL = "update_ssm_detail"
export const API_V4_UPDATE_CLASS = "update_class"
export const API_V4_Get_Daily_Report = "progress_roadmap/daily_report"
export const STR_CANCEL_MENTOR_SESSION = "cancel_mentor_session"
export const STR_GET_ALL_MENTOR_SESSIONS = "get_all_mentor_sessions"
export const API_v4_GET_TOP_100_STUDENTS = "get_top_100_students"
export const API_V4_GET_BATCH_HISTORY = "get_batch_change_history"
export const API_V4_DELETE_STUDENT = "delete_user"
export const API_UPDATE_STUDENT_PROFILE = "update_student_profile"
export const API_GET_ALL_MENTORS = "get_all_mentors"
export const API_GET_ALL_ONBOARDING_MANAGERS = "get_all_onboarding_managers"
export const API_GET_BATCH_CLASSES = "get_batch_classes"
export const API_CANCEL_SCHEDULE_CLASS = "delete_class"
export const API_V4_GET_ALL_BATCHES = "get_all_batches"
export const API_V4_GET_ALL_ACTIVE_BATCHES = "get_all_active_batches"
export const API_V4_END_BATCH = "end_batch"
export const API_V4_GET_ALL_INSTRUCTORS = "get_all_instructors"
export const API_V4_GET_MODULE_CLASSES = "get_module_classes"
export const API_V4_GET_CLASS_TEMPLATE = "get_class_template"
export const API_V3_GET_ALL_MENTORS_DETAILS = "get_all_mentors_details"
export const API_V3_UPDATE_MENTOR_DISABLE_STATE = "update_mentor_disable_state"
export const API_V3_ENABLE_IS_INSTRUCTOR = "enable_is_instructor"
export const API_V3_CHANGE_BATCH = "change_student_batch"
export const API_UPDATE_BATCH_BUNCH = "update_batch_student_list"
export const API_GET_ATTENDENCE = "is_student_registered"
export const API_GET_RESULT = "get_student_result"
export const API_V4_CREATE_ONBOARDING_MANAGER = "add_onboarding_manager"
export const API_V4_GET_DAILY_REPORT = "progress_roadmap/daily_report"
export const API_V4_GET_OVERALL_REPORT = "progress_roadmap/get_student_overall_report"
export const API_V4_TOGGLE_SESSION_COMPLETE_STATUS = "toggle_session_complete_status"
export const API_V4_TOGGLE_PLATFORM_PROBLEM_STATUS = "toggle_platform_problem_status"
export const API_V4_TOGGLE_LEETCODE_PROBLEM_COMPLETE_STATUS = "toggle_leetcode_problem_status"
export const API_V4_CREATE_COMPANY = "create_company"
export const API_V4_DELETE_JOB = "delete_job"
export const API_V4_GET_JOB_APPLICATIONS = "get_job_applications"
export const API_V4_GET_APPLICATION_STATUS = "get_application_status"
export const API_V4_SET_APPLICATION_STATUS = "set_job_application_status"
export const API_V4_NEW_UPDATES = "new_feature"
export const API_V4_PRE_HOMEPAGE = "pre_homepage"
export const API_POST_METHOD = "POST"
export const API_GET_METHOD = "GET"
export const API_ID_TOKEN = "id_token"
export const API_REFRESH_TOKEN = "refresh_token"
export const API_LOCAL_ID = "local_id"
export const API_ROLE = "role"
export const API_STUDENT = "student"
export const API_V4_MENTOR = "v4_mentor"
export const API_SURVEY = "survey"
export const API_V4_GET_ALL_SSM_HISTORY = "/v4_student/get_ssm_details"
export const API_GET_NEXT_SCHEDULE_CLASS_TIMESTAMP = "get_next_schedule_class_timestamp"
export const API_TOGGLE_CHECKLIST_STATUS = "toggle_checklist_status"
export const API_TOGGLE_HOMEPAGE_LIST_STATUS = "toggle_homepage_list_status"
export const STR_PLAY_AGAIN = "Play Again"
export const API_IMPORT_TEMPLATE_CODE = "import_template_code"
export const API_GET_QUESTION = "get_question"
export const API_GET_SUBJECTIVE_CASE_STUDY_QUESTION = "get_subjective_case_study_question"
export const API_GET_QUESTION_SUBMISSIONS = "get_question_submissions"
export const API_GET_SUBMISSION = "get_submission"
export const API_CREATE_DOCUMENTATION_FILE = "create_documentation_file"
export const API_GET_DOCUEMENTATION_FILE = "get_documentation_file"
export const API_GET_DOCUEMENTATION_FILES = "get_documentation_files"
export const API_UPDATE_DOCUMENTATION_FILE = "update_documentation_file"
export const API_DELETE_DOCUMENTATION_FILES = "delete_documentation_file"
export const API_GET_WALK_THROUGH_DAY = "method_discount"
export const API_CREATE_SUBMISSION = "create_submission"
export const API_RUN_CODE = "run_code"
export const API_LIKE_QUESTION = "like_question"
export const API_DISLIKE_QUESTION = "dislike_question"
export const API_GET_STORE_ITEMS = "get_store_items"
export const API_STUDENT_ALL_ADDRESSES = "student_all_addresses"
export const API_ADD_STUDENT_ADDRESS = "add_student_address"
export const API_UPDATE_STUDENT_ADDRESS = "update_student_address"
export const API_DELETE_STUDENT_ADDRESS = "delete_student_address"
export const API_PLACE_STORE_ORDER = "place_store_order"
export const API_GET_STUDENT_STORE_ORDERS = "get_student_store_orders"
export const API_GET_STORE_ORDER_STATUS = "get_store_order_status"
export const API_ADD_STORE_ITEM = "add_store_item"
export const API_UPDATE_STORE_ITEM = "update_store_item"
export const API_DELETE_STORE_ITEM = "delete_store_item"
export const API_GET_STORE_ORDERS = "get_store_orders"
export const API_UPDATE_STORE_ORDER_STATUS = "update_store_order_status"
export const API_GET_RESUME = "get_resume"
export const API_FREE_SIGNUP = "free_signup"
export const API_GET_LIVE_CONTESTS = "get_live_contests"
export const API_GET_INSTANT_CONTESTS = "get_instant_contests"
export const API_GET_CONTEST_DETAILS = "get_contest_details"
export const API_GET_STUDENT_PAST_CONTESTS = "get_student_past_contests"
export const API_GET_STUDENT_CONTEST_DETAILS = "get_student_contest_details"
export const API_REGISTER_CONTEST = "register_contest"
export const API_UNREGISTER_CONTEST = "unregister_contest"
export const API_GET_CONTEST_LEADERBOARD = "get_contest_leaderboard"
export const API_IS_STUDENT_REGISTERED = "is_student_registered"
export const API_HAS_STUDENT_STARTED_CONTEST = "has_student_started_contest"
export const API_GET_MCQ_QUESTION_DETAILS = "get_mcq_question_details"
export const API_GET_SUB_CASE_QUESTION_DETAILS = "get_sub_case_question_details"
export const API_GET_CODING_QUESTION_DETAILS = "get_coding_question_details"
export const API_ADMIN = "admin"
export const API_GET_STUDENT_BY_EMAIL = "get_student_by_email"
export const API_GET_STUDENTS_BY_NAME = "get_students_by_name"
export const API_GET_STUDENTS_BY_BATCH = "get_students_by_batch"
export const API_SHIFT_BATCH = "shift_batch"
export const API_GET_BATCH_STUDENT_DETAILS = "get_batch_student_details"
export const API_SUBMIT_CODING_QUESTION = "submit_coding_question"
export const API_SUBMIT_MCQ_QUESTION = "submit_mcq_question"
export const API_SUBMIT_SUBJECTIVE_QUESTIONS = "submit_batch_subjective_questions"
export const API_SUBMIT_CASESTUDY_QUESTIONS = "submit_batch_casestudy_questions"
export const API_SUBMIT_BATCH_MCQ_QUESTION = "submit_batch_mcq_question"
export const API_SUBMIT_CONTEST = "submit_contest"
export const STR_PLACEHOLDER_IMAGE =
  "https://d23qowwaqkh3fj.cloudfront.net/wp-content/uploads/2022/05/placeholder-2.png"
export const API_GET_MCQ_QUESTIONS = "get_mcq_questions"
export const API_CREATE_MCQ_QUESTION = "create_mcq_question"
export const API_GET_MCQ_QUESTION = "get_mcq_question"
export const API_UPDATE_MCQ_QUESTION = "update_mcq_question"
export const API_DELETE_MCQ_QUESTION = "delete_mcq_question"
export const API_GET_QUESTION_LIST = "get_question_list"
export const API_CASE_STUDIES_QUESTION_LIST = "get_case_study_questions"
export const API_CREATE_CONTEST = "create_contest"
export const API_UPDATE_CONTEST = "update_contest"
export const API_DELETE_CONTEST = "delete_contest"
export const API_GET_MASTER_CLASS = "get_master_class"
export const API_UPLOAD_IMAGE = "upload_img"
export const API_UPLOAD_DOCUMNET = "upload_doc"
export const API_UPLOAD_IMAGE_ONBOARDING_MANAGER = "upload_img_onboarding_manager"
export const API_UPLOAD_MENTOR_IMAGE = "upload_mentor_image"
export const API_DELETE_MENTOR_IMAGE = "delete_mentor_image"
export const API_GET_ATTENDANCE_AND_ASSIGNMENTS = "get_attendance_and_assignments"
export const API_SUBMIT_MONTHLY_FEEDBACK = "submit_monthly_feedback"
export const API_SEND_EMAIL_TO_ALL = "send_email_to_all"
export const API_SEND_EMAIL_TO_PROGRAMME = "send_email_to_programme"
export const API_SEND_EMAIL_TO_BATCH = "send_email_to_batch"
export const API_SEND_EMAIL_TO_GROUP_OF_STUDENTS = "send_email_to_group_of_students"
export const API_CHECK_MONTHLY_FEEDBACK = "check_monthly_feedback"
export const API_GET_MONTHLY_FEEDBACK = "get_monthly_feedback"
export const API_GET_MONTHLY_FEEDBACK_ARCHIVED_DATA = "get_monthly_feedback_archived_data"
export const API_CHECK_BATCH_FOR_MASTERS = "check_batch_for_masters"
export const API_REGISTER_MASTERS_PROGRAM = "register_masters_program"
export const API_GET_FEE_AFTER_DISCOUNT = "get_student_fee_after_discount"
export const API_FETCH_MENTOR_PROFILE = "fetch_mentor_profile"
export const API_MENTOR = "mentor"
export const API_MENTORS = "mentors"
export const API_GET_ALL_CLASSES = "get_all_classes"
export const API_GET_CLASS_ALL_RATING = "get_class_all_rating"
export const API_GET_CLASS_AVERAGE_RATING = "get_class_average_rating"
export const API_UPDATE_ALL_CLASS_DETAILS = "update_all_class_details"
export const API_GET_ALL_INSTRUCTOR_DETAILS = "get_all_instructor_details"
export const API_GET_INSTRUCTOR_DETAILS = "get_instructor_details"
export const API_UPDATE_INSTRUCTOR_DETAILS = "update_instructors_details"
export const API_UPDATE_INSTRUCTOR_COMEPNSATION_DETAILS = "update_instructor_compensation_details"
export const API_GET_ALL_SESSION_OF_MONTH_YEAR = "get_all_session_of_month_year"
export const API_FINALISE_INVOICE_OF_A_MENTOR = "finalise_invoice_of_a_mentor"
export const API_GET_INSTRUCTOR_AVERAGE_RATING = "get_instructor_average_rating"
export const API_UPDATE_WALKTHROUGH_DATE = "update_walkthrough_date"
export const API_GET_MENTOR_SESSIONS = "get_mentor_sessions"
export const API_GET_GROUP_MENTOR_SESSIONS = "get_group_mentor_sessions"
export const API_GET_BATCH_YEAR_DATA = "get_batch_year_data"
export const API_GET_MENTOR_LIST_BATCH = "get_mentor_list_batch"
export const API_GET_MENTOR_INVOICES = "get_mentor_invoices"
export const API_GET_INSTRUCTOR_INVOICES = "get_instructor_invoices"
export const API_GET_MENTORINSTRUCTOR_INVOICES = "get_all_invoices"
export const API_GET_ALL_CLASS_TOPICS = "get_all_class_topics"
export const API_GET_ALL_QUESTIONS = "get_all_questions"
export const API_GET_DOUBT_QUERY_SOLUTION = "get_doubt_query_solution"
export const API_UPDATE_DOUBT_STATUS = "update_doubt_status"
export const API_GET_STUDENT_DOUBT_HISTORY = "get_student_doubt_history"
export const API_SEARCH_STUDENT_DOUBT = "search_student_doubt"
export const API_GET_STUDENT_DOUBT_PENDING = "get_student_doubt_pending"
export const API_GET_UPCOMING_CLASSES = "get_upcoming_classes"
export const API_V4_DUPLICATE_CLASS = "duplicate_class"
export const API_CANCEL_SESSION = "cancel_session"
export const API_WRITE_STUDENT_FEEDBACK = "write_student_feedback"
export const API_GET_DOUBT_RAISED_STATS = "get_doubt_raised_stats"
export const API_FETCH_MONTHLY_MENTOR_SESSION = "fetch_monthly_mentor_session"
export const API_GET_AVAILABILITY = "get_availability"
export const API_UPDATE_AVAILABILITY = "update_availability"
export const API_BLOCK_DATES = "block_dates"
export const API_UPDATE_INSTRUCTOR_INCENTIVE_DETAILS = "update_instructor_incentive_details"
export const API_GET_INSTRUCTOR_INCENTIVE_DETAILS = "get_instructor_incentive_details"
export const API_CONFIRM_INVOICE_OF_MENTOR = "confirm_invoice_of_a_mentor"
export const API_GET_MENTOR_LIST = "get_mentor_list"
export const API_GET_MENTOR_RATING = "get_mentor_rating"
export const API_UPDATE_MENTOR_SESSION = "update_mentor_session"
export const API_UPDATE_MENTOR_COMEPNSATION_DETAILS = "update_mentor_compensation_details"
export const API_UPDATE_MENTOR_DETAILS = "update_mentor_details"
export const API_GET_MENTOR_SESSION_OF_MONTH_YEAR = "get_mentor_session_month_year"
export const API_GET_ALL_MENTORS_LIST = "get_all_mentors_list"
export const API_MENTOR_SESSION_INVOICING = "mentor_session_invoicing"
export const API_FINALIZE_MENTOR_SESSION_INVOICING = "finalize_mentor_session_invoicing"
export const API_GET_GROUP_MENTOR_SESSION = "get_group_mentor_session"
export const API_UPDATE_GROUP_MENTOR_SESSION = "update_group_mentor_session"
export const API_DELETE_GROUP_MENTOR_SESSION = "delete_group_mentor_session"
export const API_CREATE_GROUP_MENTOR_SESSION = "create_group_mentor_session"
export const API_CREATE_GOAL_SETTING_SESSION = "create_goal_setting_session"
export const API_CREATE_EXTRA_MENTOR_SESSION = "create_extra_mentor_session"
export const API_GET_GROUP_MENTOR_SESSION_OF_MONTH_YEAR = "get_group_mentor_session_month_year"
export const API_GET_MODULES_CLASSES = "get_module_based_session"
export const API_CREATE_MODULE_CLASS = "create_module_based_session"
export const API_UPDATE_MODULE_CLASS = "update_module_based_session"
export const API_DELETE_MODULE_CLASS = "delete_module_based_session"
export const API_GET_MENTORS_DETAIL_LIST = "get_mentors_detail_list"
export const API_REMOVE_MENTOR = "remove_mentor"
export const API_GET_FUTURE_BATCHES = "get_future_batches"
export const API_STUDENT_BATCH_SHIFT = "student_batch_shift"
export const API_IS_ELIGIBLE_FOR_BATCH_CHANGE = "is_eligible_for_batch_change"
export const API_STUDENT_BATCH_PAUSE = "student_batch_pause"
export const API_MARK_ATTENDANCE = "mark_attendance"
export const API_GET_BATCH_CHANGE_DETAILS = "get_batch_change_details"
export const API_GET_BATCH_PAUSE_DETAILS = "get_batch_pause_details"
export const API_GET_BATCH_RESUME_DETAILS = "get_batch_resume_details"
export const API_GET_MENTOR_CHANGE_DETAILS = "get_mentor_change_details"
export const API_GET_FILTERED_DATA = "get_autotask_filtered_data"
export const API_SEARCH_OPERATIONAL_DATA_BY_BATCH = "search_operational_data_by_batch"
export const API_GET_STUDENTS_DOUBT_DETAILS = "get_students_doubt_details"
export const API_GET_OPERATIONAL_DATA_BY_DATE = "get_operational_data_by_date"
export const API_GET_OPERATIONAL_DATA_BY_STATUS = "get_operational_data_by_status"
export const API_GET_QUESTION_BY_TOPIC = "get_question_by_topic"
export const API_CREATE_QUESTION = "create_question"
export const API_UPDATE_QUESTION = "update_question"
export const API_CREATE_PAYMENT_ADMIN = "create_payments"
export const API_CREATE_CCAV_PAYMENT_ADMIN = "create_ccavenue_payments"
export const API_UPDATE_PAYMENT_OPTIONS = "update_student_payment_options"
export const API_UPDATE_PAYMENT_METHOD = "update_student_payment_method"
export const API_PAYMENT_DETAIL_ADMIN = "get_student_payment_details_admin"
export const API_GET_STUDENT_EMI = "get_student_emi"
export const API_GET_PAYMENT_METHODS = "get_payment_methods"
export const API_GET_PAYMENT_DOCUMENTS = "get_payment_documents"
export const API_UPDATE_PAYMENT_DOCUMENT = "update_payment_document"
export const API_UPLOAD_PAYMENT_DOCUMENT = "upload_payment_document"
export const API_UPDATE_TRANSACTION_ADMIN = "update_transaction_details"
export const API_DISABLE_STUDENT_REASON_UPDATE = "disable_student_reason_update"
export const STR_PLATFORM_VERSION = "1.0"
export const STR_GET_UPCOMING_CLASSES = "get_upcoming_classes"
export const STR_GET_ALL_COMPANIES = "get_all_companies"
export const STR_GET_ALL_NEW_COMPANIES = "get_all_new_companies"
export const UPLOAD_COMPANY_LOGO = "upload_company_logo"
export const UPLOAD_STUDENT_PICTURE = "upload_student_picture"
export const ADD_NEW_PLACEMENT = "add_new_placement"
export const STR_CREATE_JOB = "create_job"
export const STR_UPDATE_JOB = "update_job"
export const STR_GET_WEEKLY_TEST = "get_all_weekly_test"
export const STR_GET_ALL_SCHEDULE_TEST = "get_all_scheduled_weekly_test"
export const STR_CREATE_WEEKLY_TEST = "create_weekly_test"
export const STR_UPDATE_WEEKLY_TEST = "update_weekly_test"
export const STR_DELETE_WEEKLY_TEST = "delete_weekly_test"
export const STR_SCHEDULE_WEEKLY_TEST = "schedule_weekly_test"
export const STR_UPDATE_SCHEDULE_WEEKLY_TEST = "update_schedule_weekly_test"
export const STR_DELETE_SCHEDULE_WEEKLY_TEST = "delete_schedule_weekly_test"
export const STR_UPDATE_JOB_STATUS = "update_job_status"
export const STR_APPLY_FOR_JOB = "apply_for_job"
export const STR_GET_COURSE = "get_course"
export const STR_GET_ALL_JOBS = "get_all_jobs"
export const STR_GET_JOB_DETAILS = "get_job_details"
export const STR_GET_STUDENT_JOBS = "get_student_jobs"
export const STR_GET_APPLICATION_STATUS = "get_application_status"
export const STR_GET_MENTOR_DETAILS = "get_mentor_details_list"
export const STR_GET_ELIGIBLE_MENTORS = "get_eligible_mentors"
export const STR_UPDATE_MENTOR = "update_mentor"
export const STR_GET_RECENT_PLACEMENTS = "v4_get_recent_placements"
export const STR_GET_NEW_PLACEMENT = "get_new_placement"
export const STR_DELETE_NEW_PLACEMENT = "delete_new_placement"
export const STR_GET_STUDENT_PLACEMENT = "get_student_placement"
export const STR_LOGIN_TITLE = "Welcome Back"
export const STR_LOGIN_SUBTITLE = "We have missed you a lot!"
export const STR_WEEKLY_TEST_SERIES = "Exams"
export const STR_WEEKLY_TEST_SERIES_SUBTITLE = "All the exams have been kept in pace to the completion of the course."
export const STR_ONGOING_TEST = "Upcoming Exams"
export const STR_UPCOMING_SESSIONS = "Upcoming Sessions"
export const STR_PAST_SESSIONS = "Past Sessions"
export const STR_CREATE_TEST = "Create Test"
export const STR_SCHEDULE_TEST = "Schedule Test"
export const STR_COMPLETED_TEST = "Past Exams"
export const STR_REMEMBER_ME = "Remember me"
export const STR_FORGOT_PASSWORD = "Forgot Password?"
export const STR_FORGOT_PASSWORD_HEADING = "Forgot Password"
export const STR_FORGOT_PASSWORD_SUBHEADING = "Get to know your old password"
export const STR_LOGIN = "Sign in"
export const STR_NO_ACCOUNT = "Don't have an Account yet?"
export const STR_REGISTER_NOW = "Register Now!"
export const STR_AUTHORIZED_EMAIL = "Authorized user E-mail"
export const STR_NEXT = "Next"
export const STR_RESET_TITLE = "Reset password !"
export const STR_RESET_SUBTITLE = "Reset your password by following steps:"
export const STR_RESET_PASSWORD = "Reset Password"
export const STR_EMAIL = "Full email id"
export const STR_PASSWORD = "Type your password here!"
export const STR_ENTER_BATCH = "Enter Batch"
export const STR_USER_CREATED = "User Created"
export const STR_NEW_PASSWORD = "New Password"
export const STR_CONFIRM_PASSWORD = "Confirm Password"
export const STR_SIGN_IN_WITH_NEW_PASSWORD = "Sign in with new password?"
export const STR_SIGN_IN = "Sign in"
export const STR_SIGN_UP = "Sign up"
export const STR_LOADING = "Loading..."
export const STR_CHAPTER_CREATED = "Chapter created"
export const STR_LOGIN_SUCCESSFUL = "Logged in successfuly!"
export const STR_BAD_REQUEST_STATUS = 400
export const STR_CREATED_STATUS = 201
export const STR_USER_NOT_FOUND = "User not found!"
export const STR_FILL_ALL_FIELDS = "Please fill all fields!"
export const STR_EMAIL_FIELD = "email"
export const STR_DATE_AND_TIME_FIELD = "datetime-local"
export const STR_DATE_FIELD = "date"
export const STR_MONTH_AND_YEAR_FIELD = "month"
export const STR_PASSWORD_FIELD = "password"
export const STR_CHECKBOX_FIELD = "checkbox"
export const STR_TEXT_FIELD = "text"
export const STR_ROOT_PATH = "/"
export const STR_LIVE_CLASSES = "Live classes"
export const STR_LIVE_CLASSES_SUBTITLE = "You can start you class from here!"
export const STR_LETS_GET_STARTED = "Let's get started!"
export const STR_CONFIRM_BEFORE_PROCEED = "Confirm the following details before you can proceed further."
export const STR_SUBMIT = "Submit"
export const STR_CANDIDATE_FULLNAME = "Candidate Full Name"
export const STR_FULLNAME = "Full Name"
export const STR_EMAIL_ID = "Email ID"
export const STR_REQUEST_FOR_CHANGE = "Request for change"
export const STR_PHONE_NUMBER = "Phone Number"
export const STR_NUMBER_FILED = "number"
export const STR_PROFESSION = "Profession"
export const STR_MENTEE_LIST = "Mentee List"
export const STR_MENTEE_LIST_SUBTITLES = "Your assigned mentee list."
export const STR_STUDENT = "Student"
export const STR_YEARS_OF_EXPERIENCE = "Years of Experience"
export const STR_BATCH = "Batch"
export const STR_BANDWIDTH = "Bandwidth"
export const STR_REQUESTED_FOR_CHANGE = "You have requested for changes"
export const STR_VIEW_MODULE = "View Module"
export const STR_MENTOR_ALLOTED = "Mentor successfully allotted!"
export const STR_SUCCESS = "success"
export const STR_COPIED = "Copied successfully"
export const STR_STUDENT_INFO_SAVED = "Student Info Saved Successfully"
export const STR_MENTOR_INFO_SAVED = "Mentor Info Saved Successfully"
export const STR_FAILURE = "failure"
export const STR_FEES = "Fees Amount"
export const STR_PASSWORD_DONT_MATCH = "Password does not match!"
export const STR_PASSWORD_RESET_SUCCESS = "Password reset successfully!"
export const STR_PASSWORD_RESET_FAILURE = "Error: Password reset failed!"
export const STR_PASSWORD_RESET_LINK_SENT = "Password reset link has been sent at your email!"
export const GET_PAYMENT_DOCUMENTS = "get_payment_documents"
export const UPLOAD_PAYMENT_DOCUMENT = "upload_payment_document"
export const DELETE_PAYMENT_DOCUMENT = "delete_payment_document"
export const GET_STUDENT_EMI = "get_student_emi"
export const CREATE_STUDENT_EMI = "create_student_emi"
export const STR_COURSE_SUBTITLE = "All your courses will be available here."
export const STR_NEW_PASSWORD_NAME = "newPassword"
export const STR_CONFIRM_PASSWORD_NAME = "confirmPassword"
export const STR_AUTHORIZED_EMAIL_NAME = "authorizedEmail"
export const STR_PROVIDE_AUTHORIZED_EMAIL = "Please provide authorized email!"
export const STR_PROVIDE_PASSWORD = "Please provide password!"
export const STR_EMAIL_NOT_VALID = "Please enter valid email!"
export const STR_RESET = "Reset"
export const STR_FULL_COURSE_CONTENT = "Full Course Content"
export const STR_UPCOMING_LECTURES = "Upcoming Sessions"
export const STR_RECORDED_LECTURES = "Past Recorded Sessions"
export const STR_PAYMENT_OPTION1 = "Direct to Bosscoder"
export const STR_PAYMENT_OPTION2 = "EMI through NBFCs"
export const GET_STUDENT_PAYMENT_DETAILS = "get_student_payments"
export const GET_STUDENT_PAYMENT_DETAILS_ALL = "get_student_paymentdetails_all"
export const API_CREATE_PAYMENT_STUDENT = "create_payments_oneshot_new"
export const STR_PAST_SESSIONS_AND_ASSIGNMENT = "Past Sessions"
export const STR_COURSE_VIEW = "Course View"
export const STR_AVAILABLE_PAYMENT_METHODS = "Available Payment Methods"
export const STR_AVAILABLE_PAYMENT_OPTIONS = "Available Payment Options"
export const STR_SERVER_ERROR = "Can't connect to server"
export const STR_LEADERBOARD = "Leaderboard"
export const STR_LEADERBOARD_SUBTITLE = "Ranking is based on the number of Problems Solved in the Past Seven days."
export const STR_PLACEMENT = "Recent Placements"
export const STR_PLACEMENT2 = "Previous Placements"
export const STR_PLACEMENT_SUBTITLE = "Our top students who got recently placed at top product based companies."
export const STR_PLACEMENT_SUBTITLE2 = "Our top students who got recently placed at top product based companies."
export const STR_AVAILABLE_JOBS = "Available Jobs"
export const STR_UPCOMING_CLASSES = "Upcoming Classes"
export const STR_RECORDED_CLASSES = "Recorded Classes"
export const STR_JOBS_APPLIED_FOR = "Jobs Applied For"
export const STR_GET_CLASS_DETAILS = "get_class_details"
export const STR_VIEW_FEEDBACK = "View Feedback"
export const STR_VIEW_NOTES = "View Notes"
export const STR_REQUEST_PRERECORDED = "Request Pre-Recorded"
export const STR_GET_FULL_COURSE_CONTENT = "get_full_course_content"
export const STR_COMPLETE_MENTOR_SELECTION_PROCESS = "Complete mentor selection process."
export const STR_COMPLETE_MENTOR_SELECTION_PROCESS_DESCRIPTION =
  "You have not selected your mentor yet, complete the remaining steps before your batch starts."
export const STR_MENTOR_SELECTION_TIME_EXPECTED = "5 min"
export const STR_CONTINUE_PROCESS = "Continue Process"
export const STR_PAY_NOW = "Pay Now"
export const STR_GENERATE_NOW = "Initiate Payment"
export const STR_CALENDAR = "Calendar"
export const STR_SCHEDULE = "Schedule"
export const STR_CALENDAR_SUBTITLE = "Here is the Weekly Class Schedule of your Batch"
export const STR_GET_STARTED = "Let's get started"
export const STR_GET_STARTED_SUBTITLE = "Follow these steps and win your first badge."
export const STR_SELECT_ATMAX_THREE_MENTORS = "**You can select maximum 3 mentors at a time."
export const STR_NEXT_STEP_BUTTON = "Next step"
export const STR_MENTOR_LIST = "Mentor List"
export const STR_MENTOR_NOT_CHOSEN_STATUS = "MENTOR NOT CHOSEN"
export const STR_MENTOR_ALLOTED_STATUS = "MENTOR ALLOTED"
export const STR_UPDATE_MENTOR_PRIORITY = "update_mentor_priority"
export const STR_GET_MENTOR_AVAILABILITY = "get_mentor_availability"
export const STR_GET_LEADERBOARD = "get_leaderboard"
export const STR_MENTOR_NOT_AVAILABLE = "There is no mentor availability on this specific date"
export const STR_GET_BOOKED_SESSIONS = "booked_mentor_sessions"
export const STR_SCHEDULE_MENTORSHIP = "Schedule Mentorship"
export const STR_SCHEDULE_MENTORSHIP_DESCRIPTION = "Book all your mentor sessions hassle free!"
export const STR_MENTOR_SESSION = "Mentor Session"
export const STR_SSM_MEETING_SESSION = "Upcoming Student Progress Meeting"
export const STR_CANCEL = "Cancel"
export const STR_ADD_STUDENT_REVIEW = "add_student_review"
export const STR_ADD_MENTOR_FEEDBACK = "add_mentor_feedback"
export const STR_BOOK_MENTOR_SESSION = "book_mentor_session"
export const STR_FULL_REPORT = "Full Report"
export const STR_WELCOME_BACK = "Welcome Back"
export const STR_GET_STUDENT_DETAILS = "get_student_details"
export const STR_DISCORD_LINK = "https://discord.gg/zgPx5KmGFC"
export const STR_CAPTURE_ATTENDANCE = "capture_attendance"
export const STR_IS_LEETCODE_VERIFIED = "is_leetcode_verified"
export const STR_VERIFY_LEETCODE = "verify_leetcode"
export const STR_VIEW_SUMMARY = "View Summary"
export const STR_SUBMIT_CLASS_FEEDBACK = "submit_class_feedback"
export const STR_SUBMIT_RECORDING_CLASS_FEEDBACK = "submit_recording_feedback"
export const STR_GET_FEEDBACK_DETAILS = "get_feedback_details"
export const STR_GET_MENTOR_MENTEES = "get_mentor_mentees"
export const STR_GET_MENTEE_MENTOR_SESSION = "get_mentee_mentor_sessions"
export const STR_GET_ALL_CLASSES = "get_all_classes"
export const STR_JOIN_CLASS = "join_class"
export const STR_PLAY_VIDEO = "Play Video"
export const STR_NOT_SCHEDULED = "NOT SCHEDULED"
export const STR_TIME_LIMIT_EXCEEDED = "Time Limit Exceeded"
export const STR_RUN = "Run"
export const STR_WRONG_ANSWER = "Wrong Answer"
export const STR_PARTIALLY_ACCEPTED = "Partially Accepted"
export const STR_ACCEPTED = "Accepted"
export const STR_ERROR = "Error"
export const STR_UPDATE_PROFILE = "update_profile"
export const STR_UPLOAD_PROFILE_PICTURE = "upload_profile_pic"
export const STR_UPLOAD_RESUME = "upload_resume"
export const STR_PERCENT_COMPLETED_PROFILE = "percent_completed_profile"
export const STR_GET_STUDENT_PROFILE_DETAILS = "get_student_profile_details"
export const STR_COLLEGE_STUDENTS_URL = "https://drive.google.com/file/d/1SeU2Hj5vwrd7HKXfYnqotSWbRj5LtYk5/view"
export const STR_WORKING_PROFESSIONAL_URL = "https://drive.google.com/file/d/1nkpwvzmi423f7qd1UsX_suhHTEv1bVpd/view"
export const STR_CODING_PROBLEMS = "Coding"
export const STR_CASE_STUDY_PROBLEMS = "Case Study"
export const STR_SUBJECTIVE_PROBLEMS = "Subjective"
export const STR_MCQS = "MCQs"
export const STR_DESCRIPTION = "Description"
export const STR_SUBMISSIONS = "Submissions"
export const STR_ADD = "add"
export const STR_QUESTION = "Question"
export const STR_OPTIONS = "Options"
export const STR_SOLUTION = "Solution"
export const STR_PAID = "PAID"
export const STR_MASTER_CLASS = "Master Class"
export const STR_UPCOMING = "Upcoming"
export const STR_PAST = "Past"
export const STR_CANCELLED = "Cancelled"
export const STR_UPCOMING_TYPE = "upcoming"
export const STR_PAST_TYPE = "past"
export const STR_CANCELLED_TYPE = "cancelled"
export const STR_DSML = "DSML"
export const STR_UNKNOWN_ERROR_OCCURRED = "Unknown Error Occurred"
export const API_GET_MODULE_BASED_TRACK = "get_module_based_on_track"
export const API_V4_UPDATE_CLASS_AND_TIMING = "update_class_and_timing"
export const API_REMOVE_COMPENSATION = "remove_compensation"
export const API_REMOVE_MENTOR_COMPENSATION = "remove_mentor_compensation"
export const API_EDIT_MENTOR_COMPENSATION = "edit_mentor_compensation_table"
export const API_EDIT_COMPENSATION = "edit_compensation_table"
export const API_REMOVE_INCENTIVE = "remove_incentive"
export const API_UPDATE_BATCH_DATA = "update_batch_data"
export const STR_WHATSAPP_COMMUNITY_LINK = "https://chat.whatsapp.com/K71KWhYeQ8Q3lbOEHBmhD4"
export const API_V4_GET_BATCH_TEMPLATE_GENERIC = "get_batch_template_generic"
export const API_V4_UPDATE_BATCH_TEMPLATE_GENERIC = "update_batch_template_generic"
export const STR_GET_MENTOR_SESSION_LINK_STUDENT = "get_mentor_session_link_student"
export const STR_MARK_MENTOR_ABSENT = "mark_mentor_absent"
export const STR_GET_MENTOR_SESSIONS_BY_STUDENT = "get_mentor_sessions_by_student"
export const STR_GET_MENTOR_SESSIONS_BY_STUDENT_MENTOR = "get_mentor_sessions_by_student_mentor"
export const STR_ADD_MENTOR_FEEDBACK_NEW = "add_mentor_feedback_new"
export const STR_EDIT_MENTOR_TEMPLATE_FOR_STUDENT = "edit_mentor_template_for_student"
export const STR_MARK_MENTEE_ABSENT = "mark_mentee_absent"
export const STR_CANCEL_MENTOR_SESSION_BY_MENTOR = "cancel_mentor_session_by_mentor"
export const STR_GET_MENTOR_DETAILS_BY_STUDENT = "get_mentor_details_by_student"
export const STR_GET_MENTOR_NAME_FROM_ID = "get_mentor_name_from_id"
export const STR_GET_MENTOR_SESSION_DETAILS = "get_mentor_session_details_new"
export const STR_GET_MENTOR_SESSION_DETAILS_MENTOR = "get_mentor_session_details_new_mentor"
export const API_CREATE_SUB_CASE_QUESTION = "create_subcase_question"
export const API_UPDATE_SUB_CASE_QUESTION = "update_create_subcase_question"
export const API_DELETE_SUB_CASE_QUESTION = "delete_sub_case_question"
export const GET_CASESTUDY_SUBJECTIVE_QUESTION = "get_sub_case_questions"
export const GET_QUESTION = "get_question"
export const GET_COUNT_SUB_CASE_QUESTIONS = "count_sub_case_questions"
export const GET_FILTERED_SUB_CASE_QUESTIONS = "filter_case_sub_questions"
export const API_ADD_EXTRA_MENTOR_SESSION = "allow_extra_mentor_session_v3"
export const API_CREATE_PROJECT = "create_project"
export const API_GET_PROJECT = "get_project"
export const API_GET_PROJECT_DETAILS = "get_project_details"
export const API_EDIT_PROJECT = "edit_project"
export const API_TOGGLE_PROJECT_STATUS = "toggle_project_status"
export const API_DELETE_PROJECT = "delete_project"
export const API_V4_BATCH_SEARCH = "batch_search"
export const API_V4_UPDATE_BATCH_DETAILS = "update_batch_details"
export const API_V4_GET_ALL_INVOICES = "get_payment_invoices"
export const API_V4_UPDATE_INVOICE_DETAILS = "update_invoice_details"
export const API_V4_FILTERED_SEARCH_INVOICES = "get_filtered_payment_invoices"
export const API_V4_GET_NEXT_30_INVOICES = "get_next_30_invoices"
export const API_V4_GET_TOP_30_INVOICES = "get_top_30_invoices"
export const API_V4_REFUND_STUDENT = "refund_student_payment"
export const API_GET_PRODUCT_ANALYTICS = "get_product_analytics"
export const STR_EV_BATCH = "EV"
export const API_V4_GET_WEEKLY_REPORT = "progress_roadmap/get_student_weekly_report"
export const API_V4_GET_MONTHLY_REPORT = "progress_roadmap/get_student_monthly_report"
export const API_V4_GET_STUDENT_LIST_OF_BATCH = "get_students_of_batch"
export const API_V4_GET_BATCH_ASSIGMENT = "get_batch_assignment"
export const API_V4_GET_STUDENT_ASSIGNMENT_DETAILS = "get_student_assignment_details"
export const API_V4_CREATE_FEEDBACK_RESPONSE = "create_session_feedback_response"
export const API_V4_GET_FEEDBACK_RESPONSE = "get_student_session_feedback_response"
export const API_V4_GET_SSM_FEEDBACK_RESPONSE = "get_ssm_feedback_response"
export const API_SUBMIT_ASSIGNMENT_QUESTION = "submit_assignment_questions"
export const API_v4_GET_TOP_100_STUDENTS_BY_COINS = "get_top_30_students_by_coins"
export const API_V4_GET_NEXT_30_STUDENTS_BY_COINS = "get_next_30_students_by_coins"
export const API_V4_GET_ORDER_HISTORY = "get_order_history"
export const API_SUBJECTIVE_QUESTION_LIST = "get_subjective_questions"
export const API_EDIT_COUPON = "edit_coupon"
export const API_CREATE_COUPON = "create_coupon"
export const API_DELETE_COUPON = "delete_coupon"
export const API_GET_ALL_COUPONS = "get_all_coupons"
export const API_REDEEM_COINS = "redeem_coins"
export const API_GET_ORDER_HISTORY = "get_order_history"
export const API_BOOK_MENTOR_SESSION_COUPON = "book_extra_mentor_session"
export const API_GET_EXTRA_SESSION = "get_mentor_session_coupon"
export const API_ALLOW_REDEEM_COINS = "allow_redeem_coins"
export const API_GET_BATCHES_ALLOWED_REDEEM_COINS = "get_batches_allowed_redeem_coins"
export const API_V4_GET_MENTOR_SESSION_COUPON = "get_mentor_session_coupon"
export const STR_SOFTWARE_ENGINEERING = "Software Engineering"
export const STR_DATA_SCIENTIST = "Data Science"
export const API_V4_GET_STUDENT_SUBMISSION = "get_submissions"
export const GET_STUDENT_CCAV_PAYMENT_DETAILS = "get_ccavenue_payment_details"
export const API_V4_GET_SSM_SUBMISSION_ENABLE_STATUS = "get_ssm_submision_enable_status"
export const API_V4_GET_FEEDBACK_RESPONSE_SUBMISSION = "get_ssm_feedback_details"
export const API_V4_DELETE_FEEDBACK_FILE = "delete_feedback_file"
export const LOGIN = "login"
export const TOKEN_REFRESH = "token_refresh"
export const NOT_PAID = "NOT_PAID"
export const STUDENT = "student"
export const CODE = "code"
export const RUN = "run"
export const SUBMIT = "submit"
export const API_V4_UPDATE_MILESTONE_SESSION_MENTOR = "update_milestone_session_mentor"
export const API_V4_CANCEL_MENTOR_SESSION_BY_MENTOR_OLD = "cancel_mentor_session_by_mentor_old"
export const ONBOARDING_TEAM = "onboarding_team"
export const HOME = "home"
export const MEET_AND_GREET = "meet_greet"
export const PREREQUISITE = "prerequisite"
export const CLASS_REMAINDER = "class_details"
export const API_GET_MCQ_NAME_FROM_ID = "get_mcq_name_from_id"
export const SDE_FORM_LINK = "https://forms.gle/MTrr9DHEY95yUdsA7"
export const DSML_FORM_LINK = "https://forms.gle/HobVKQFa7So4edjQA"
export const API_V4_GET_CONTEST_STUDENT_LIST = "get_contest_student_list"
export const BATCH_WEEKLY_REPORT = "admin/batch_progress_report/weekly"
export const CONTEST_LIST = "admin/contest_approve/get_all_contest_list"
export const PLACEMENT_CONTEST_LIST = "admin/contest_approve/get_placement_contest_list"
export const BATCH_MONTHLY_REPORT = "admin/batch_progress_report/monthly"
export const PLACEMENT_READINESS_SCORE = "score/placement_readiness_score"
export const API_V4_GET_STUDENT_CONTEST_SUBMISSIONS = "get_student_contest_submissions"
export const API_V4_CREATE_CONTEST_FEEDBACK_RESPONSE = "create_contest_ssm_feedback_response"
export const API_V4_GET_CONTEST_FEEDBACK_DETAILS = "get_contest_feedback_details"
export const API_V4_GET_CONTEST_SSM_SUBMISSION_ENABLE_STATUS = "get_contest_ssm_submission_enable_status"
export const API_V4_CONTEST_DELETE_FEEDBACK_FILE = "contest_delete_feedback_file"
export const API_V4_GET_STUDENT_CONTEST_FEEDBACK_RESPONSE = "get_student_contest_feedback_response"
export const MENTOR_SESSION_REMAINDER = "mentor_session_remainder"
export const CONTEST_REMAINDER = "contest_details"
export const ROADMAP = "roadmap"
export const NOTIFICATION = "notification"
export const PROGRESS_MEET = "progress_meet_details"
export const NOTIFICATION_READ = "read"
export const PLACEMENT_COHORT = "placement_cohort"
export const PLACEMENT = "placement"
export const ONBOARDING_MEET = "onboarding_meet_details"
export const RIGHT_PANEL = "right_panel"
export const TOAST = "toast"
export const API_V4_TOGGLE_PROCTORING = "toggle_proctoring"
export const API_CONTEST_APPROVE = "contest_approve"
export const STUDENT_DASHBOARD = "student_dashboard"
export const API_ADD_COHORT_STUDENT_TO_LIST = "add_cohort_student_to_list"
export const API_ADD_ISCOHORT_FIELD = "add_iscohort_field"
export const API_GET_ALL_COHORT_ATTENDED = "get_all_cohort_attended"
export const API_GET_PLACEMENT_STUDENT_LIST = "get_placement_student_list"
export const API_GET_STUDENT_DETAILS = "get_student_details"
export const API_ADD_STUDENT_DETAILS = "add_student_details"
export const API_PUT_STUDENT_DETAILS = "edit_student_details"
export const API_PUT_PLACEMENT_STATUS = "edit_placement_status"
export const API_UPLOAD_RESUME = "upload_resume"
export const API_GET_REVIEWS_BY_ID = "get_reviews_by_id"
export const API_GET_JOURNEYS_BY_ID = "get_journeys_by_id"
export const API_UPDATE_REVIEWS_BY_ID = "update_student_review"
export const API_GET_TOP_100_APPROVE_REQUESTS = "get_top_100_approve_requests"
export const API_CREATE_PLACEMENT_BATCH = "create_placement_batch"
export const API_APPROVE_STUDENT = "approve_student"
export const API_GET_APPROVAL = "get_approval"
export const API_GET_APPROVAL_BY_EMAIL = "get_approval_by_email"
export const API_GET_APPROVAL_BY_DATE = "get_approval_by_date"
export const API_V4_MARK_SUBMIT_PROCTORED = "mark_submit_proctored"
export const API_GET_ACTIVE_STUDENTS_OF_BATCH_DATA = "get_active_students_of_batch_data"
export const API_V4_VALIDATE_CODING_QUESTION = "validate_coding_question"
export const API_V4_VALIDATE_MCQ_QUESTION = "validate_mcq_question"
export const API_V4_VALIDATE_SUBJECTIVE_QUESTION = "validate_subjective_question"
export const API_V4_CLASS_REMINDER_SCHEDULING = "class_reminder_scheduling"
export const API_V4_GET_CLASS_REMINDER_DATA = "get_class_reminder_data"
export const API_V4_GET_ALL_MENTORSHIP_TEMPLATES = "get_all_mentorship_templates"
export const API_TOGGLE_GOAL_SETTING_MENTOR = "toggle_goal_setting_mentor"
export const API_GET_GOAL_SETTING_AVAILABILITY = "get_goal_setting_availability"
export const API_UPDATE_GOAL_SETTING_AVAILABILITY = "update_goal_setting_availability"
export const DETAILS = "details"
export const ATTENDANCE_AND_ASSIGNMENTS = "attendance_and_assignments"
export const MASTERCLASS = "masterclass"
export const GET_ONBOARDING_DATA_FOR_ADMIN = "admin/onboarding/data"
export const UPCOMING_CLASSES = "upcoming_classes"
export const LEADERBOARD = "leaderboard"
export const MENTOR_DETAILS = "mentor_details"
export const API_GET_DSML_BEGINNER_MODULES_SESSIONS = "get_dsml_beginner_module_class"
export const STR_DSML_BEGINNER = "DSML Beginner"
export const API_DELETE_COMPANY = "delete_company"
export const API_UPDATE_COMPANY = "update_company"
export const API_GET_COMPANY = "get_company"
export const API_COMPANY = "company"
export const TEMPLATE_CODE = "template_code"
export const CAPTURE_ATTENDANCE = "capture_attendance"
export const MARK_ATTENDANCE = "mark_attendance"
export const API_BLOCK_GOAL_SETTING_DATES = "block_goal_setting_dates"
export const API_TOGGLE_PERMANENT_DISABLE_STUDENT = "toggle_permanent_disable_student"
export const API_ALLOT_MENTORS = "allot_mentors"
export const API_PERMANENT_DISABLE_STUDENT_REASON_UPDATE = "permanent_disable_student_reason_update"
export const LEETCODE_VERIFIED = "leetcode_verified"
export const SUBMIT_ASSIGNMENT_QUESTION = "submit_assignment_question"
export const GET_AI_FEEDBACK = "get_ai_feedback"
export const STUDENT_AI_FEEDBACK = "student_ai_feedback"
export const INSTRUCTOR_SESSIONS = "instructor_sessions"
export const MENTOR_SESSION = "sessions"
export const INSTRUCTOR = "instructor"
export const MONTH_INVOICE_DATA = "month-invoice-data"
export const MENTOR_INSTRUCTOR = "/mentor/instructor"
export const GOAL_SETTING_SESSION_AMOUNT_UPDATE = "goal-setting-session-amount-update"
export const MENTOR_GOAL_SETTING_SESSION = "mentor-goal-setting-sessions"
export const EXTRA_GOAL_SETTING_SESSION_AMOUNT_UPDATE = "extra-goal-setting-session-amount-update"
export const GOAL_SETTING_STATUS_UPDATE = "goal-setting-status-update"
export const MODULE_COMPENSATION = "module-compensation"
export const API_TAKE_PLEDGE = "take_pledge"
export const AI = "ai"
export const ALL_FEEDBACKS = "all_feedbacks"
export const STR_RESET_PASSWORD_SUBTITLE = "Reset your password by following steps"
export const STR_RESET_YOUR_PASSWORD = "Reset your password!"
export const STR_OLD_PASSWORD_FIELD = "oldPassword"
export const STR_NEW_PASSWORD_FIELD = "newPassword"
export const STR_RETYPE_PASSWORD_FIELD = "reTypePassword"
export const STR_KNOW_YOUR_PASSWORD = "Know your password?"
export const API_FORGOT_PASSWORD = "forgot_password"
export const API_RESET_PASSWORD_BY_STUDENT = "password_reset"
export const STR_CREDENTIALS_SEND = "Login Credentials sent to Registered Email!"
export const STR_REGISTERED_EMAIL_ID = "Here is your registered Email ID"
export const STR_LOGIN_SUBTITLE_2 = "Log In to Unlock Your Potential"
export const STR_LOGIN_TITLE_2 = "Welcome!"
export const AI_FEEDBACK_ON_STUDENT = "ai_feedback_on_student"
export const API_V4_REATTEMPT_ATTEMPTED_CONTEST = "reattempt_attempted_contest"
export const API_TOGGLE_LOCK_MENTOR = "toggle_lock_mentor"
export const API_V4_GET_ALL_QUESTIONS = "get_all_coding_questions"
export const API_V4_GET_ALL_MCQ__QUESTIONS = "get_all_mcq_questions"
export const API_BATCH_TIMESTAMP_DATA = "admin/batch_timestamp_data"
export const API_VIEW_BATCH_TIMESTAMP_DATA = "admin/view_timestamp_data"
export const API_CREATE_EXAM = "create_exam"
export const API_UPDATE_EXAM = "update_exam"
export const API_GET_EXAM_DETAILS = "get_exam_details"
export const API_REGISTER_EXAM = "register_exam"
export const API_UNREGISTER_EXAM = "unregister_exam"
export const API_IS_STUDENT_REGISTERED_EXAM = "is_student_registered_exam"
export const API_GET_STUDENT_EXAM_DETAILS = "get_student_exam_details"
export const API_GET_ALL_EXAMS = "get_all_exams"
export const API_GET_STUDENT_PAST_EXAMS = "get_student_past_exams"
export const API_GET_EXAM_CODING_QUESTION = "get_exam_coding_question"
export const API_GET_EXAM_MCQ_QUESTION = "get_exam_mcq_question"
export const API_SUBMIT_EXAM_CODE = "submit_exam_code"
export const API_GET_EXAM_SUBJECTIVE_CASESTUDY_QUESTION = "get_exam_subjective_casestudy_question"
export const API_DELETE_EXAM = "delete_exam"
export const API_V4_MARK_EXAM_SUBMIT_PROCTORED = "mark_exam_submit_proctored"
export const API_V4_GET_EXAM_RESULT = "get_exam_result"
export const API_V4_GET_EXAM_LEADERBOARD = "get_exam_leaderboard"
export const API_V4_GET_EXAM_QUESTION_SUBMISSIONS = "get_exam_question_submissions"
export const API_V4_GET_EXAM_QUESTIONS = "get_exam_questions"
export const STR_MARK_TAKEN_ELSEWHERE = "mark_taken_elsewhere"
export const STR_GET_UPCOMING_EXAMS = "get_upcoming_exams"
export const API_V4_UPDATE_CLASS_WITHOUT_ICS = "update_class_without_ics"
