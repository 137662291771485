import DoubtSupportViewModel from "../DoubtSupportViewModel"
import React from "react"
import Button from "core/components/Button"
import BatchShiftModal from "./BatchShiftModal"
import { cn } from "core/lib/utils"
import CurrentMentorCard from "./CurrentMentorCard"
import ScrollCard from "./ScrollCard"
import SelectMentorModal from "./SelectMentorModal"
import Loader from "core/components/Loader"
import IssueResolvedPage from "./IssueResolvedPage"
import EdgeCasePage from "./EdgeCasePage"
import OperationalIssueTable from "./OperationalIssueTable"
import { format } from "date-fns"

export default function OperationalIssueCard(props: any) {
  const date: number = Date.now()
  const {
    fetchFutureBatches,
    futureBatchDes,
    selectedBatch,
    batchChangeStatus,
    fetchEligibleMentors,
    eligibleForBatchChange,
    eligibleMentorDetails,
    futureBatchEligiblity,
  } = DoubtSupportViewModel()
  const [isOpen, setIsOpen] = React.useState<any>(false)
  const [heading, setHeading] = React.useState<any>()
  const [title, setTitle] = React.useState<any>()
  const [reason, setReason] = React.useState<any>("")
  const [openMentorToSelect, setOpenMentorToSelect] = React.useState<any>(false)

  const [selectedMentor, setSelectedMentor] = React.useState<any>({})
  const [discordUsername, setDiscordUsername] = React.useState<any>("")

  React.useEffect(() => {
    fetchFutureBatches()
    eligibleForBatchChange()
    fetchEligibleMentors()
  }, [])

  return (
    <>
      {/* Confirmation Modal  */}
      {isOpen && (
        <BatchShiftModal
          setIsOpen={setIsOpen}
          heading={heading}
          title={title}
          selectedBatch={selectedBatch}
          futureBatchDes={futureBatchDes}
          selectedMentor={selectedMentor}
          reason={reason}
          topic={props.topic}
          setSlidingPopUp={props.setSlidingPopUp}
          setSlidingPopUpText={props.setSlidingPopUpText}
          setStudentBatchShift={props.setStudentBatchShift}
          setStudentBatchPause={props.setStudentBatchPause}
          setMentor={props.setMentor}
          discordUsername={discordUsername}
        />
      )}

      {/* Browse Mentors Modal */}
      {openMentorToSelect && (
        <SelectMentorModal
          mentors={eligibleMentorDetails?.mentor}
          setOpenMentorToSelect={setOpenMentorToSelect}
          setSelectedMentor={setSelectedMentor}
          selectedMentor={selectedMentor}
        />
      )}

      {/* Code for Batch Change */}
      {props.topic === "Batch Change" &&
        (Object.keys(batchChangeStatus).length === 0 ? (
          <div className="flex flex-col h-[491px] w-full items-center justify-center rounded-[8px] border-[0.5px] border-[#D3D3D3] bg-[#FFF] ml-[20px]">
            <Loader />
          </div>
        ) : props.operationalChangesDone ? (
          <IssueResolvedPage h1="Batch Change" h2="Your Batch has been changed successfully" />
        ) : batchChangeStatus["is_batch_ended"] ? (
          <EdgeCasePage h1="Batch Change" h2="Your Batch cannot be changed as your course has concluded" />
        ) : batchChangeStatus["is_batch_changed"] === 1 || batchChangeStatus["valid_batch_change"] >= 2 ? (
          <EdgeCasePage
            h1="Batch Change"
            h2="You're not eligible for batch change."
            h3="You have already changed your batch once."
          />
        ) : batchChangeStatus["is_batch_paused_recently"] === 1 ? (
          <EdgeCasePage
            h1="Batch Change"
            h2="Batch Change is currently disabled as your Course is on Pause"
            h3="Please Resume your Course to proceed with choosing a preferred
          batch"
          />
        ) : (
          <div className="h-[491px] w-full rounded-lg border-[0.5px] border-[#D3D3D3] bg-[#FFF] ml-[20px] p-[2px]">
            <div className="h-full w-full overflow-y-auto custom-scrollbar-3">
              <div className="m-4 flex flex-col gap-y-4">
                <div className="text-[#333333] font-inter text-[14px] font-[600] leading-normal">
                  {props.topic + " :"}
                </div>
                <div className="flex flex-col gap-y-4">
                  <div className="relative flex">
                    <div className="flex gap-x-1 text-sm font-semibold">
                      <div className="text-[#333333] font-inter text-[14px] font-[600] leading-normal">
                        Select New Batch to change
                      </div>
                      <div className="text-[#DC2626] font-inter text-[14px] font-[600] leading-normal">*</div>
                    </div>
                    <div className="absolute right-0 flex gap-x-1 text-sm font-semibold -mt-9">
                      <div className="text-[#333] font-[500]">Selected Batch:</div>
                      <div className={cn(selectedBatch === -1 ? "text-black" : "text-[#00C22B]")}>
                        {selectedBatch === -1 ? "N/A" : futureBatchDes["rows"][selectedBatch]["batch"]}
                      </div>
                    </div>
                  </div>
                  {futureBatchDes["header"].length === 0 ? (
                    <Loader />
                  ) : (
                    <OperationalIssueTable
                      data={futureBatchDes}
                      OperationalIssue={true}
                      futureBatchEligiblity={futureBatchEligiblity}
                    />
                  )}
                </div>
                <div className="-mt-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="333" height="2" viewBox="0 0 333 2" fill="none">
                    <path d="M0 1H333" stroke="#646464" stroke-width="0.5" stroke-dasharray="7 7" />
                  </svg>
                </div>

                <div className="flex flex-col gap-y-2">
                  <div className="flex gap-x-1 font-inter text-[14px] leading-normal font-semibold">
                    <div className="text-[#333] ">Reason to change Batch</div>
                    <div className="text-[#DC2626]">*</div>
                  </div>

                  <textarea
                    className="h-[92px] rounded-[4px] border border-[#D3D3D3] bg-[#FFF] shadow-[rgba(132,_136,_139,_0.05)_-1px_1px_3px_0px] focus:border-[#D3D3D3] focus:outline-none"
                    id="reason"
                    name="reason"
                    placeholder="Type your message here!"
                    onChange={(event) => {
                      setReason(event.target.value)
                    }}
                  />
                </div>

                <div className="flex flex-col gap-y-2 leading-tight">
                  <div className="text-[#DC2626] font-inter text-[12px] font-normal leading-tight">
                    ** Note : You can change your batch only once throughout the course.
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center gap-2 rounded-lg bg-[#F9F9F9] m-4 bottom-0 px-4 py-3 cursor-pointer">
                <Button
                  onClick={() => {
                    setIsOpen(true)
                    setTitle("Batch Change Confirmation")
                    setHeading(
                      <>
                        You are about to change your current batch to{" "}
                        <p className="mt-[13px] h-[15px] w-[218px] text-[12px] font-[600] text-[#07B42D]">
                          {futureBatchDes["rows"][selectedBatch]["batch"]}
                        </p>
                        <p className="h-[15px] w-[218px] text-[12px] font-[600] text-[#07B42D]">
                          {format(new Date(date), "do MMMMMMMMMMMMM, yyyy")}
                        </p>
                        <p
                          className="mt-[13px] w-[272px] text-[10px] font-[400] text-[#DC2626]"
                          style={{ lineHeight: "1.2" }}
                        >
                          ** Note : You can change your batch only once throughout the course.
                        </p>
                      </>
                    )
                  }}
                  className={`w-[auto] inline-flex px-[16px] py-[6px] justify-center items-center gap-[8px] rounded-[4px] text-[white] text-[14px] font-[500] ${
                    selectedBatch === -1 || reason === "" ? "bg-[#D3D3D3]" : "bg-[#162456] hover:bg-[#1C3474]"
                  }`}
                  disabled={selectedBatch === -1 || reason === ""}
                >
                  Confirm Change
                </Button>

                <Button className="w-[auto] text-[#DC2626] inline-flex px-[16px] py-[6px] justify-center items-center gap-[8px] text-[14px] font-[500] rounded-[4px] border-[1px] border-[#D3D3D3] bg-[#FFF] hover:bg-[#FFEDED]">
                  Cancel Change
                </Button>
              </div>
            </div>
          </div>
        ))}

      {props.topic === "Batch Pause" &&
        (Object.keys(batchChangeStatus).length === 0 ? (
          <div className="flex flex-col h-[491px] w-full items-center justify-center rounded-[8px] border-[0.5px] border-[#D3D3D3] bg-[#FFF] ml-[20px]">
            <Loader />
          </div>
        ) : props.operationalChangesDone ? (
          <IssueResolvedPage h1="Batch Pause" h2="You Course has been Paused successfully" />
        ) : batchChangeStatus["is_batch_ended"] ? (
          <EdgeCasePage h1="Batch Pause" h2="Your Course cannot be Paused as your course has concluded" />
        ) : batchChangeStatus["is_batch_paused"] === 1 ? (
          <EdgeCasePage
            h1="Batch Pause"
            h2="You are not eligible for Course Pause."
            h3="Your Course is already Paused."
          />
        ) : (
          <div className="flex flex-col h-[491px] w-full rounded-[8px] border-[0.5px] border-[#D3D3D3] bg-[#FFF] ml-[20px] gap-y-4">
            <div className="m-6 mb-0 text-[#333333] font-inter text-sm font-semibold leading-normal">{props.topic}</div>
            <div className="m-6 mt-0 mb-0 flex flex-col gap-y-2">
              <div className="flex gap-x-1 font-inter text-[14px] leading-normal font-semibold">
                <div className="text-[#333]">Reason to Pause Change</div>
                <div className="text-[#DC2626]">*</div>
              </div>
              <textarea
                className="h-[92px] w-[auto] rounded-[4px] border border-[#D3D3D3] bg-[#FFF] shadow-[rgba(132,_136,_139,_0.05)_-1px_1px_3px_0px] focus:border-[#D3D3D3] focus:outline-none"
                id="reason"
                name="reason"
                placeholder="Type your message here!"
                onChange={(event) => {
                  setReason(event.target.value)
                }}
              />
            </div>

            <div className="m-6 mt-0 mb-0 flex flex-col gap-y-2 leading-tight">
              <div className="text-[#DC2626] font-inter text-[12px] font-normal leading-tight">
                ** Note : You can pause your course only once throughout the course for a maximum period of 3 months.
              </div>
            </div>
            <div className="m-4 flex flex-row justify-start items-end h-full p-4 gap-2 cursor-pointer">
              <Button
                onClick={() => {
                  setIsOpen(true)
                  setTitle("Batch Pause Confirmation")
                  setHeading(
                    <>
                      Are you sure you want to pause your Course?
                      <p
                        className="mt-[13px] w-[272px] text-[10px] font-[400] text-[#DC2626]"
                        style={{ lineHeight: "1.2" }}
                      >
                        ** Note : You can pause your course only once throughout the course for a maximum period of 3
                        months.
                      </p>
                    </>
                  )
                }}
                className={`w-[auto] inline-flex px-[16px] py-[6px] justify-center items-center gap-[8px] rounded-[4px] text-[white] text-[14px] font-[500] ${
                  reason === "" ? "bg-[#D3D3D3]" : "bg-[#162456] hover:bg-[#1C3474]"
                }`}
                disabled={reason === ""}
              >
                Confirm Change
              </Button>
              <Button className="w-[auto] text-[#DC2626] inline-flex px-[16px] py-[6px] justify-center items-center gap-[8px] text-[14px] font-[500] rounded-[4px] border-[1px] border-[#D3D3D3] bg-[#FFF] hover:bg-[#FFEDED]">
                Cancel
              </Button>
            </div>
          </div>
        ))}

      {props.topic === "Resume Course" &&
        (Object.keys(batchChangeStatus).length === 0 ? (
          <div className="flex flex-col h-[491px] w-full items-center justify-center rounded-[8px] border-[0.5px] border-[#D3D3D3] bg-[#FFF] ml-[20px]">
            <Loader />
          </div>
        ) : props.operationalChangesDone ? (
          <IssueResolvedPage h1="Resume Course" h2="You Course has been Resumed successfully" />
        ) : batchChangeStatus["is_batch_ended"] ? (
          <EdgeCasePage h1="Resume Course" h2="Your Course cannot be Resumed as your course has concluded" />
        ) : batchChangeStatus["is_batch_resume"] === 1 || batchChangeStatus["is_batch_paused"] === 0 ? (
          <EdgeCasePage
            className="text-[#DC2626]"
            h1="Resume Course"
            h2="Course Resumption is applicable to students who had paused their course earlier."
          />
        ) : (
          <div className="h-[491px] w-full rounded-lg border-[0.5px] border-[#D3D3D3] bg-[#FFF] ml-[20px] p-[2px]">
            <div className="h-full w-full overflow-y-auto custom-scrollbar-3">
              <div className="m-4 flex flex-col gap-y-4">
                <div className="text-[#333333] font-inter text-[14px] font-[600] leading-normal">
                  {props.topic + " :"}
                </div>
                <div className="flex flex-col gap-y-4">
                  <div className="relative flex">
                    <div className="flex gap-x-1 text-sm font-semibold">
                      <div className="text-[#333333] font-inter text-[14px] font-[600] leading-normal">
                        Opt for a New Batch to Resume your Course
                      </div>
                      <div className="text-[#DC2626] font-inter text-[14px] font-[600] leading-normal">*</div>
                    </div>
                    <div className="absolute right-0 flex gap-x-1 text-sm font-semibold -mt-9">
                      <div className="text-[#333] font-[500]">Selected Batch:</div>
                      <div className={cn(selectedBatch === -1 ? "text-black" : "text-[#00C22B]")}>
                        {selectedBatch === -1 ? "N/A" : futureBatchDes["rows"][selectedBatch]["batch"]}
                      </div>
                    </div>
                  </div>
                  {futureBatchDes["header"].length === 0 ? (
                    <Loader />
                  ) : (
                    <OperationalIssueTable
                      data={futureBatchDes}
                      OperationalIssue={true}
                      futureBatchEligiblity={futureBatchEligiblity}
                    />
                  )}
                </div>
              </div>
              <div className="bottom-0 w-full bg-white p-4">
                <Button
                  onClick={() => {
                    setIsOpen(true)
                    setTitle("Batch Resume Confirmation")
                    setHeading("Are you sure you want to resume your course from selected batch?")
                  }}
                  className={`w-[auto] inline-flex px-[16px] py-[6px] justify-center items-center gap-[8px] rounded-[4px] text-[white] text-[14px] font-[500] ${
                    selectedBatch === -1 ? "bg-[#D3D3D3]" : "bg-[#162456] hover:bg-[#1C3474]"
                  }`}
                  disabled={selectedBatch === -1}
                >
                  Confirm Change
                </Button>
                <Button className="w-[auto] text-[#DC2626] inline-flex px-[16px] py-[6px] justify-center items-center gap-[8px] text-[14px] font-[500] rounded-[4px] border-[1px] border-[#D3D3D3] bg-[#FFF] hover:bg-[#FFEDED] ml-2">
                  Cancel Change
                </Button>
              </div>
            </div>
          </div>
        ))}

      {/* Code for Mentor Change */}
      {props.topic === "Mentor Change" &&
        (Object.keys(eligibleMentorDetails).length === 0 ? (
          <div className="flex flex-col h-[491px] w-full items-center justify-center rounded-[8px] border-[0.5px] border-[#D3D3D3] bg-[#FFF] ml-[20px]">
            <Loader />
          </div>
        ) : props.operationalChangesDone ? (
          <IssueResolvedPage h1="Mentor Change" h2="You Mentor has been changed successfully" />
        ) : batchChangeStatus["is_batch_ended"] ? (
          <EdgeCasePage h1="Mentor Change" h2="Your Mentor cannot be changed as your course has concluded" />
        ) : eligibleMentorDetails?.mentor_history.length === 0 ? (
          <EdgeCasePage
            h1="Mentor Change"
            h2="You haven't selected your Mentor yet."
            h3="Please complete the Mentor selection process."
          />
        ) : (
          <div className="h-[491px] w-full rounded-lg border-[0.5px] border-[#D3D3D3] bg-[#FFF] ml-[20px] pt-[2px] relative overflow-y-hidden">
            <div className="h-full w-full overflow-y-auto custom-scrollbar-3">
              <div className="m-4 flex flex-col gap-y-4">
                <div className="text-[#333333] font-inter text-[14px] font-[800] leading-normal">
                  {props.topic + " :"}
                </div>

                {/* Mentor Selection */}
                <div className="relative flex">
                  <div className="flex gap-x-1 text-sm font-semibold">
                    <div className="text-[#333333] font-inter text-[14px] font-[600] leading-normal">
                      Select New Mentor to change
                    </div>
                    <div className="text-[#DC2626] font-inter text-[14px] font-[600] leading-normal">*</div>
                  </div>
                </div>

                <div className="flex w-full p-4 justify-between items-start rounded-md border-[1px] border-[#E3E3E3] bg-[#FFF]">
                  <div className="w-auto flex flex-col items-start gap-[3px]">
                    <span className="text-[#333] self-stretch text-[12px] font-[500]">Mentor List</span>
                    <span className="w-[142px] text-[#646464] text-[10px] font-[500] leading-tight">
                      Browse mentor list and select new available mentor.
                    </span>
                  </div>
                  <button
                    onClick={() => setOpenMentorToSelect(true)}
                    className={cn(
                      "flex px-4 py-[6px] items-center justify-center rounded-md gap-[13px]	border-[0.5px] border-[#D3D3D3] bg-[#FFF] text-[12px] font-[400] text-[#646464] hover:bg-[#bbc9ef]",
                      batchChangeStatus["mentor_changed"] >= 6 ? "cursor-not-allowed opacity-60" : ""
                    )}
                    disabled={batchChangeStatus["mentor_changed"] >= 6}
                  >
                    <div>Select Mentor</div>
                  </button>
                </div>

                <div className="">
                  <svg xmlns="http://www.w3.org/2000/svg" width="333" height="2" viewBox="0 0 333 2" fill="none">
                    <path d="M0 1H333" stroke="#646464" stroke-width="0.5" stroke-dasharray="7 7" />
                  </svg>
                </div>

                {Object.keys(selectedMentor).length !== 0 && (
                  <>
                    <ScrollCard details={selectedMentor} state="Selected" />
                  </>
                )}

                <div className="flex flex-col gap-y-2">
                  <div className="flex gap-x-1 font-inter text-[14px] leading-normal font-semibold">
                    <div className="text-[#333]">Reason to change Mentor</div>
                    <div className="text-[#DC2626]">*</div>
                  </div>
                  <textarea
                    className="h-[92px] rounded-[4px] border border-[#D3D3D3] bg-[#FFF] shadow-[rgba(132,_136,_139,_0.05)_-1px_1px_3px_0px] focus:border-[#D3D3D3] focus:outline-none"
                    id="reason"
                    name="reason"
                    placeholder="Type your message here!"
                    onChange={(event) => {
                      setReason(event.target.value)
                    }}
                  />
                </div>

                <div className="mt-2 flex flex-col gap-y-2 leading-tight">
                  {batchChangeStatus["mentor_changed"] < 6 ? (
                    <div className="text-[#DC2626] font-inter text-[12px] font-normal leading-tight">
                      ** Note : Each student will have the opportunity to request a mentor change a maximum of two times
                      throughout the duration of their course.
                    </div>
                  ) : (
                    <div className="text-[#DC2626] font-inter text-[12px] font-normal leading-tight">
                      ** Note : You are no longer eligible for mentor change as you have already changed your mentor
                      five times.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex w-full gap-2 sticky left-0 bottom-0 z-10 justify-center items-center bg-[#F9F9F9] px-4 py-2">
              <Button
                onClick={() => {
                  setIsOpen(true)
                  setTitle("Mentor Change Confirmation")
                  setHeading("Are you sure you want to change your Mentor?")
                }}
                className={`w-[auto] inline-flex px-[16px] py-[6px] justify-center items-center gap-[8px] rounded-[4px] text-[white] text-[14px] font-[500] ${
                  reason === "" || Object.keys(selectedMentor).length === 0 || batchChangeStatus["mentor_changed"] >= 6
                    ? "bg-[#D3D3D3]"
                    : "bg-[#162456] hover:bg-[#1C3474]"
                }`}
                disabled={
                  reason === "" || Object.keys(selectedMentor).length === 0 || batchChangeStatus["mentor_changed"] >= 6
                }
              >
                Confirm Change
              </Button>
              <Button className="w-[auto] text-[#DC2626] inline-flex px-[16px] py-[6px] justify-center items-center gap-[8px] text-[14px] font-[500] rounded-[4px] border-[1px] border-[#D3D3D3] bg-[#FFF] hover:bg-[#FFEDED]">
                Cancel Change
              </Button>
            </div>
          </div>
        ))}

      {props.topic === "Other" && (
        <div className="flex flex-col h-[491px] w-full items-center justify-center rounded-[8px] border-[0.5px] border-[#D3D3D3] bg-[#FFF] ml-[20px]">
          <div className="flex w-full flex-col font-semibold text-red-500">
            <div className="text-center">For any operational issues, kindly</div>
            <div className="text-center">mail us at help@bosscoderacademy.com</div>
          </div>
        </div>
      )}
    </>
  )
}
