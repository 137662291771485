import Loader from "core/components/Loader"
import { ChevronDownIcon1 } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import { isEmpty } from "core/utils/misc"
import React, { useState, useEffect, useRef } from "react"
import { SearchIcon } from "lucide-react"
import { CustomScroll } from "react-custom-scroll"
import listenForOutsideClick from "./listen-for-outside-clicks"

export default function DropDownMenu(props: any) {
  const [isOpen, setIsOpen] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [isFocused, setIsFocused] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const menuRef = useRef(null)
  const [listening, setListening] = useState(false)
  useEffect(listenForOutsideClick(listening, setListening, menuRef, setIsOpen))

  const handleOptionClick = (option: any) => {
    props.id == 2 ? props.setTopic(option) : props.id == 3 ? props.setQuestion(option) : props.setModule(option)
    setIsOpen(false)
  }

  const handleSearchBarClick = () => {
    if (!isSearching) {
      setIsSearching(true)
    }
  }

  return (
    <div
      className={cn(
        "relative flex h-auto w-[300px] cursor-pointer rounded-[4px] border border-[#D3D3D3] px-4 py-2 bg-[#FFF] justify-between items-start ",
        (props.index == 1 && props.id == 2 && props.module == "") ||
          (props.id == 3 && (props.module == "" || props.topic == "")) ||
          (props.index == 2 && props.id == 3 && props.topic == "")
          ? "pointer-events-none opacity-50"
          : ""
      )}
      ref={menuRef}
      onClick={toggleDropdown}
    >
      <div className="flex flex-row">
        <div className="w-[236px] h-auto text-[#646464] text-[14px] font-[400] justify-between items-start mr-[10px]">
          {props.id == 2
            ? props.topic == ""
              ? props.module == "Operational Issue"
                ? "Select an Issue"
                : "Select a Chapter"
              : props.topic
            : props.id == 3
              ? props.question == ""
                ? "Select a Question"
                : props.question
              : props.module == ""
                ? "Select a Query"
                : props.module}
        </div>
        <ChevronDownIcon1 className={cn("h-6 w-6 border-none", isOpen && "rotate-180")} />
      </div>
      {isOpen && (
        <ul
          className={cn(
            "inline-flex border-1 absolute top-[35px] left-0 max-h-[150px] w-full list-none flex-col overflow-y-scroll custom-scrollbar-3 overflow-x-hidden border bg-white px-6 pt-4 pb-2 items-center gap-[5px] rounded-md shadow-[-1px_1px_4px_0px_rgba(34,41,48,0.20)]",
            props.zindex
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="w-[300px] flex items-center border-b-[1px] border-b-[#e8e8e8] px-4 py-2 -mt-4"
            onClick={handleSearchBarClick}
          >
            {!isSearching ? (
              <div className="flex items-center gap-2 text-[14px] text-[#646464] cursor-pointer">
                <SearchIcon size={16} />
                <span>Search</span>
              </div>
            ) : (
              <input
                type="text"
                className={cn(
                  "w-full h-[5px] text-[14px] text-[#646464] border-none outline-none",
                  isFocused ? "border-blue-500" : "border-[#e8e8e8]",
                  "focus:ring-0"
                )}
                placeholder="Type to search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onBlur={() => setIsSearching(false)}
                onClick={(e) => e.stopPropagation()}
                onFocus={() => setIsFocused(true)}
              />
            )}
          </div>

          {isEmpty(props.options) ? (
            <div className="flex min-h-[22px] w-[220px] items-center justify-center">
              <Loader />
            </div>
          ) : (
            props.options
              .filter((option: any) => option.toLowerCase().includes(searchText.toLowerCase()))
              .map((option: any) => (
                <li
                  ref={option === props.topic ? props.ref : null}
                  className="min-h-[30px] w-[275px] cursor-pointer ml-4 mr-4 truncate px-4 py-[9px] font-[500] text-[14px] text-[var(--Black-and-white-Gray-500, #646464)] hover:bg-[#e8e8e8] rounded-[2px]"
                  key={option}
                  onClick={() => handleOptionClick(option)}
                >
                  <p className="-mt-1">{option}</p>
                </li>
              ))
          )}
        </ul>
      )}
    </div>
  )
}
