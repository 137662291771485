import React from "react"
import { CrossIcon } from "core/constants/svgs"

interface ConfirmationModalProps {
  title: string
  message: string
  onConfirm: () => void
  onCancel: () => void
  onClose: () => void
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ title, message, onConfirm, onCancel, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-[304px] h-[249px] relative bg-white p-4 rounded-lg shadow-lg gap-y-4">
        <div className="relative flex">
          <div className="text-[#333333] text-[16px] font-[500]">{title}</div>
          <button onClick={onClose} className="absolute right-0">
            <CrossIcon className="h-6 w-6 text-[#312E81]" />
          </button>
        </div>
        <p className="mt-4 w-[218px] text-[#414141] text-[12px] font-[400] leading-tight">{message}</p>
        <span className="text-[#DC2626] text-[10px] font-[400]">Note** You may lose all unsaved changes.</span>
        <div className="absolute bottom-4 right-4 flex justify-end gap-2">
          <button
            onClick={onCancel}
            className="flex h-[35px] w-[79px] cursor-pointer items-center justify-center rounded border border-[#D3D3D3] text-[#EF4444] hover:bg-red-100"
          >
            <span className="text-[14px] font-[500]">Cancel</span>
          </button>
          <button
            onClick={onConfirm}
            className="flex h-[35px] w-[86px] cursor-pointer items-center justify-center rounded border bg-[#162456] text-white hover:bg-[#1C3474]"
          >
            <span className="text-[14px] font-[500]">Confirm</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModal
