import Button from "core/components/new/Button"
import DashboardLayout from "core/layouts/DashboardLayout"
import useStudentProfileViewModel from "./StudentProfileViewModel"
import StandaredSelect from "core/components/StandaredSelect"
import Input from "core/components/new/Input"
import Toast from "core/components/Toast"
import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { format } from "date-fns"
import Select from "core/components/new/Select"
import ConfirmationPopUpMentorRemoval from "./components/ConfirmationPopUpMentorRemoval"
import AdminTable from "core/components/new/AdminTable"
import ChevronDown from "assets/svgs/ChevronDown"
import TransactionDetailPopUp from "./components/TransactionDetailPopUp"
import TransactionDetailUpdatePopup from "./components/TransactionDetailUpdatePopup"
import ChevronUp from "assets/svgs/ChevronUp"
import SsmCallView from "./SsmCall"
import { State } from "country-state-city"
import { Link } from "react-router-dom"
import StudentCard from "./components/StudentCard"
import Header from "./ProgressRoadmap/components/DailyReport/Header"
import PaymentVerification from "./components/PaymentVerification"
import RefundForm from "./components/RefundForm"
import Toggle from "core/components/Toggle"
import PlacementTable from "./components/PlacementTable"
import AddPopUp from "./components/AddPopUp"
import ConfirmationPopUpDelete from "./components/ConfirmationPopUpDelete"
import CommentTable from "./components/CommentTable"
import { Textarea } from "core/components/v2/Textarea"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "core/components/v2/Table"
import { isEmpty } from "core/utils/misc"
import Modal from "core/components/Modal"
import { TimePicker } from "core/components/v2/ui/TimerPicker"
import { Headings } from "core/components/v2/Headings"
import ArrowRectangle from "assets/images/ArrowRectangle.png"
import HomePageGradient from "core/components/new/HomePageGradient"
import { useAuth } from "core/context/auth"
import useonboardindMeetingModelAdmin from "./components/onboardingMeetingModelAdmin"
import { DatePickerWithProps } from "core/components/v2/ui/DatePickerWithProps"
import { TimePickerWithProps } from "core/components/v2/ui/TimePickerWithProps"
import StarRating from "core/components/v2/RatingStars"
import { CrossIcon } from "core/constants/svgs"
import Combobox from "core/components/v2/ui/Combobox"

export default function StudentProfileView() {
  const {
    toast,
    searchEmail,
    student,
    advanceStudent,
    comment,
    refund,
    comments,
    placement,
    mentorPriority,
    mentorList,
    onboardingManagerId,
    onboardingManagersList,
    id,
    refreshed,
    isCopied,
    updating,
    removeMentorLoading,
    paymentDetails,
    isCompletlyPaid,
    isExpanded,
    isExpandedBreakdown,
    studentPayTableData,
    studentGetPayDetail,
    instalmentPayInfo,
    studentEMI,
    paymentDocuments,
    batchHistoryData,
    placementDetails,
    placementReadyDetails,
    studentComments,
    formLoding,
    loading,
    openPaymentViewDetail,
    updateTransactionPopup,
    updateTransactionDetailValues,
    payReciveDate,
    countryOptions,
    country,
    stateOptions,
    state,
    defaultCountryValue,
    refundFormOpen,
    Keys,
    disabledKeys,
    refundUserData,
    removeMilestoneMentorLoading,
    showPopup,
    userFetched,
    setOnboardingManagerId,
    documentVerified,
    addPlacementCompany,
    editPlacementCompany,
    deletePlacementCompany,
    getPlacementCompany,
    getPlacementReady,
    setPlacementReady,
    addStudentComment,
    getStudentComment,
    editStudentComment,
    deleteStudentComment,
    setPaymentDocuments,
    onSelectChangeMilestoneSessionMentor,
    setStudentEMI,
    getStudentEMI,
    getPaymentDocuments,
    uploadPaymentDocument,
    updatePaymentDocument,
    handleRemoveMilestoneMentor,
    redirectToSearchedUser,
    handleSearchByEmail,
    onChangeStudentObj,
    onChangeAdvStudentObj,
    onSelectChangeStudentStatus,
    onChangeRefund,
    onChangePlacement,
    addPlacementHistory,
    onSelectChangeOnboardingManager,
    onSelectChangeStudentObj,
    onSelectChangeAdvStudentObj,
    onChangeTypeOfRefund,
    onChangeComment,
    addComment,
    handleSubmit,
    handlePlacementHistoryChange,
    getUserData,
    changeToastVisibility,
    getMentorsList,
    getOnboardingManagerList,
    handleCopyClick,
    handleRemoveMentor,
    setIsCopied,
    navigate,
    handlePaymentSubmit,
    handleChangePayment,
    ChangePaymentOptionsMenu,
    getPaymentDetailAdmin,
    getPayableAmount,
    calculateOfferDiscount,
    calculateOneshotDiscount,
    setOpenPaymentViewDetail,
    handlePaymentViewDetails,
    setUpdateTransactionPopup,
    setPayReciveDate,
    setUpdateTransactionDetailValues,
    submitUpdateTransactionDetail,
    submitUpdateTransaction_of_CCAv_Detail,
    handleInstalmentChange,
    handleAddInstalment,
    handleRemoveInstalment,
    handleToggle,
    onSelectChangePaymentMethod,
    handleUpdateTransactionDetail,
    handleCountryChange,
    setStateOptions,
    setCountry,
    handlestateChange,
    getBatchHistoryData,
    handleRefundBtnClick,
    handleDiscardBtnClick,
    handleSaveChangesClick,
    handleCancelClick,
    handleConfirmClick,
    handleInputChange,
    handlePopUpClose,
    handleRefundFormClose,
    handleOnboardingMeetingTable,
    tableData,
    handleEditOnboardingDetails,
    handleFormSubmit,
    handleFormSubmitFeedback,
    cancelOnboardingForm,
    cancelFeedbackForm,
    isModalOpen,
    setIsModalOpen,
    isFeedbackModalOpen,
    SetIsFeedbackModalOpen,
    onboardingDate,
    setOnboardingDate,
    onboardingTime,
    setOnboardingTime,
    objective,
    setObjective,
    workAction,
    setWorkAction,
    handleObjective,
    handleWorkAction,
    onboardingData,
    setOnboardingData,
    getOnBoardingData,
    endTime,
    setEndTime,
    addThirtyMinutes,
    startTime,
    setStartTime,
    convertOnboardingTimeToTimeStamp,
    expired,
    setExpired,
    remainingTime,
    setRemainingTime,
    isFirstClick,
    setIsFirstClick,
    isFeedbackModalView,
    SetIsFeedbackModalView,
    formatTimestamp,
    joinMeet,
    getOnboardingManagerName,
    onboardingManagerName,
    setOnboardingManagerName,
  } = useStudentProfileViewModel()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isMilestoneSessionMentorOpen, setIsMilestoneSessionMentorOpen] = useState<any>(false)
  const options = [
    "Process in Progress",
    "Payment action Required",
    "Payment in Progress",
    "Payment Completed",
    "Batch Change",
  ]
  const options2 = ["true", "false"]
  const course_type = ["working professionals", "student"]
  const course_name = [
    "Accelerator",
    "Evolve",
    "Transformer",
    "Data_Scientist",
    "Data_Analyst",
    "Data-(Scientist+Analyst)",
  ]
  const program_type = ["Accelerator", "Transformer", "Evolve", "Data Science", "Data Analytics", "Data Engineering"]
  const payment_method = ["cash", "loan", "razorpay", "bank transfer"]
  const screen_test_status = ["sent", "not sent", "done"]
  const google_groups_status = ["sent", "not sent"]
  const type_of_refund = ["full refund", "processing fee refund"]
  const [collapseBasicInfo, setCollapseBasicInfo] = useState(false)
  const [mentorDetails, setMentorDetails] = useState(false)
  const [paymentInfo, setPaymentInfo] = useState(false)
  const [onboarding, setOnboarding] = useState(false)
  const [collapsePlacement, setCollapsePlacement] = useState(false)
  const [collapseComments, setCollapseComments] = useState(false)
  const [collapseRefund, setCollapseRefund] = useState(false)
  const [collapseBatchHistory, setCollapseBatchHistory] = useState(false)
  const [placementModel, setPlacementModel] = useState(false)
  const [commentModel, setCommentModel] = useState(false)
  const [editCommentModel, setEditCommentModel] = useState(false)
  const [editPlacementModel, setEditPlacementModel] = useState(false)
  const [deletePlacementModel, setDeletePlacementModel] = useState(false)
  const [deleteCommentModel, setDeleteCommentModel] = useState(false)
  const [companyDetails, setCompanyDetails] = useState({
    company_name: "",
    logo: "",
    company_date: "",
    designation: "",
  })

  const [editCompanyDetails, setEditCompanyDetails] = useState({
    id: "",
    company_name: "",
    logo: "",
    company_date: "",
    designation: "",
  })
  const [deleteCompanyDetails, setDeleteCompanyDetails] = useState({
    id: "",
  })
  const [deleteCommentDetails, setDeleteCommentDetails] = useState({
    id: "",
  })

  const [commentDetails, setCommentDetails] = useState({
    id: "",
    comment_date: "",
    updated_by: "",
    reason: "",
  })

  const [editCommentDetails, setEditCommentDetails] = useState({
    id: "",
    comment_date: "",
    updated_by: "",
    reason: "",
  })

  const changeCompanyDetails = (e: any) => {
    setCompanyDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }
  const changeEditCompanyDetails = (e: any) => {
    setEditCompanyDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  const changeCommentDetails = (e: any) => {
    setCommentDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }
  const changeEditCommentDetails = (e: any) => {
    setEditCommentDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }
  const saveCompanyDetails = () => {
    const dateObject = new Date(companyDetails?.company_date)
    const timestampInMillis = dateObject.getTime()

    addPlacementCompany({
      email: searchEmail,
      ...companyDetails,
      company_date: timestampInMillis,
    })
  }

  const saveEditCompanyDetails = () => {
    const dateObject = new Date(editCompanyDetails?.company_date)
    const timestampInMillis = dateObject.getTime()
    editPlacementCompany({
      email: searchEmail,
      ...editCompanyDetails,
      company_date: timestampInMillis,
    })
  }

  const saveCommentDetails = () => {
    const dateObject = new Date(commentDetails?.comment_date)
    const timestampInMillis = dateObject.getTime()

    const fiveThirtyInMillis = 5 * 60 * 60 * 1000 + 30 * 60 * 1000

    const adjustedTimestamp = timestampInMillis - fiveThirtyInMillis

    const now = new Date()
    const currentTimeInMillis = now.getTime() - new Date(now.setHours(0, 0, 0, 0)).getTime()

    const finalTimestamp = adjustedTimestamp + currentTimeInMillis

    addStudentComment({
      email: searchEmail,
      ...commentDetails,
      comment_date: finalTimestamp,
    })
  }

  const saveEditCommentDetails = () => {
    const dateObject = new Date(editCommentDetails?.comment_date)
    const timestampInMillis = dateObject.getTime()

    const fiveThirtyInMillis = 5 * 60 * 60 * 1000 + 30 * 60 * 1000

    const adjustedTimestamp = timestampInMillis - fiveThirtyInMillis

    const now = new Date()
    const currentTimeInMillis = now.getTime() - new Date(now.setHours(0, 0, 0, 0)).getTime()

    const finalTimestamp = adjustedTimestamp + currentTimeInMillis

    editStudentComment({
      ...editCommentDetails,
      email: searchEmail,
      comment_date: finalTimestamp,
    })
  }

  useEffect(() => {
    setPlacementModel(false)
    setEditPlacementModel(false)
    setDeletePlacementModel(false)
    setCompanyDetails({
      company_name: "",
      logo: "",
      company_date: "",
      designation: "",
    })
  }, [placementDetails])

  useEffect(() => {
    setCommentModel(false)
    setEditCommentModel(false)
    setDeleteCommentModel(false)
    setCommentDetails({
      id: "",
      comment_date: "",
      updated_by: "",
      reason: "",
    })
  }, [studentComments])
  const { auth } = useAuth()
  useEffect(() => {
    if (userFetched && "email" in student) {
      const { email } = student
      getStudentEMI(email)
      getPaymentDocuments(email)
      getBatchHistoryData(email)
      getPlacementCompany(email)
      getPlacementReady(email)
      getStudentComment(email)
      getPaymentDetailAdmin(email)
    }
  }, [userFetched])

  useEffect(() => {
    getOnBoardingData(id)
    getOnboardingManagerName(id)
  }, [id, isModalOpen, isFeedbackModalOpen])

  const handleEditPayment = () => {
    navigate("payment-breakdown")
  }

  const handleCCAvenuePaymentDetails = () => {
    navigate("ccavenue-payment-breakdown")
  }

  const handleToggleState = (setState: Dispatch<SetStateAction<boolean>>, state: boolean) => {
    setState(!state)
  }

  useEffect(() => {
    handleOnboardingMeetingTable(onboardingData)
  }, [onboardingData, isModalOpen, onboardingManagerName])

  useEffect(() => {
    convertOnboardingTimeToTimeStamp(onboardingTime, onboardingDate)
  }, [onboardingTime, onboardingDate, refreshed])

  useEffect(() => {
    addThirtyMinutes(onboardingTime)
  }, [onboardingTime])

  useEffect(() => {
    getUserData(id)
    getMentorsList(id)
    getOnboardingManagerList()
    setOnboardingManagerId(student?.onboardingManagerId)
  }, [student?.email])

  useEffect(() => {
    setStateOptions(
      State.getStatesOfCountry(student?.country?.slice(-2)).map((state: any) => ({
        value: state.name,
        label: state.name,
      }))
    )
  }, [student])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isCopied) {
        setIsCopied(false)
      }
    }, 2000)

    return () => clearTimeout(timeout)
  }, [isCopied])

  useEffect(() => {
    let intervalId: any

    const updateRemainingTime = () => {
      let selectedOnboardingTime = onboardingData?.start_time
      const afterMinutesInMs = 45 * 60 * 1000
      const beforeMinutesInMs = 15 * 60 * 1000
      const currentDate = new Date()
      let selectedOnboardingTimeInMillis = selectedOnboardingTime * 1000

      if (!selectedOnboardingTimeInMillis) {
        setExpired(true)
        setRemainingTime({ days: "", hours: "", minutes: "", seconds: "" })
        return false
      }
      if (currentDate.getTime() < selectedOnboardingTimeInMillis) {
        if (expired) setExpired(false)

        const remainingTimeInMillis = selectedOnboardingTimeInMillis - currentDate.getTime()
        const oneSecond = 1000
        const oneMinute = 60 * oneSecond
        const oneHour = 60 * oneMinute
        const oneDay = 24 * oneHour
        const days = String(Math.floor(remainingTimeInMillis / oneDay))
        const hours = String(Math.floor((remainingTimeInMillis % oneDay) / oneHour)).padStart(2, "0")
        const minutes = String(Math.floor((remainingTimeInMillis % oneHour) / oneMinute)).padStart(2, "0")
        const seconds = String(Math.floor((remainingTimeInMillis % oneMinute) / oneSecond)).padStart(2, "0")

        setRemainingTime({ days, hours, minutes, seconds })
        return false
      }
      if (selectedOnboardingTimeInMillis + afterMinutesInMs > currentDate.getTime()) {
        if (expired) setExpired(false)
        setRemainingTime({ days: "00", hours: "00", minutes: "00", seconds: "00" })
        return false
      } else {
        setExpired(true)
        setRemainingTime({ days: "", hours: "", minutes: "", seconds: "" })
        return true
      }
    }

    updateRemainingTime()

    intervalId = setInterval(() => {
      if (updateRemainingTime()) {
        clearInterval(intervalId)
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [onboardingData, onboardingTime, onboardingDate])

  return (
    <DashboardLayout header={<Header points={student?.points || student?.total_points} />}>
      {isOpen && (
        <ConfirmationPopUpMentorRemoval
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          email={student?.email}
          updateStudentsMentor={handleRemoveMentor}
          heading="Are you sure you want to remove this mentor?"
        />
      )}
      {isMilestoneSessionMentorOpen && (
        <ConfirmationPopUpMentorRemoval
          isOpen={isMilestoneSessionMentorOpen}
          setIsOpen={setIsMilestoneSessionMentorOpen}
          email={student?.email}
          updateMilestoneSessionMentor={handleRemoveMilestoneMentor}
          heading="Are you sure you want to remove this mentor?"
        />
      )}
      {openPaymentViewDetail && (
        <TransactionDetailPopUp
          openPaymentViewDetail={openPaymentViewDetail}
          setOpenPaymentViewDetail={setOpenPaymentViewDetail}
          setUpdateTransactionPopup={setUpdateTransactionPopup}
          instalmentPayInfo={instalmentPayInfo}
          handlePaymentViewDetails={handlePaymentViewDetails}
        />
      )}
      {updateTransactionPopup && (
        <TransactionDetailUpdatePopup
          openPaymentViewDetail={openPaymentViewDetail}
          instalmentPayInfo={instalmentPayInfo}
          setUpdateTransactionPopup={setUpdateTransactionPopup}
          setUpdateTransactionDetailValues={setUpdateTransactionDetailValues}
          updateTransactionDetailValues={updateTransactionDetailValues}
          setPayReciveDate={setPayReciveDate}
          payReciveDate={payReciveDate}
          submitUpdateTransactionDetail={
            student?.payment_method === "ccavenue"
              ? submitUpdateTransaction_of_CCAv_Detail
              : submitUpdateTransactionDetail
          }
        />
      )}
      {isModalOpen ? (
        <Modal
          open={isModalOpen}
          width="w-[349px]"
          height="h-[435px]"
          className="p-[24px] rounded-[10px] border border-gray-300 bg-white shadow-md"
        >
          <form
            onSubmit={async (e) => {
              e.preventDefault()
              await handleFormSubmit({
                email: student?.email,
                date: onboardingDate,
                start: startTime,
                end: endTime,
                manager: onboardingManagerId,
              })
              // setIsModalOpen(false)
            }}
          >
            <div className="flex flex-col justify-between m-0 p-0">
              <div className="flex flex-col justify-between">
                <div className="flex items-center justify-between mb-[8px]">
                  <div className=" text-[#1b3473] text-base font-['Inter'] font-bold">General Information</div>
                </div>
                <div className="flex flex-col justify-between">
                  <div className="my-[8px]">
                    <div className="text-sm font-['Inter'] font-semibold mb-[2px]">Meeting Status</div>
                    <input
                      value={
                        onboardingData ? (onboardingData.duration > 1 ? "Attended" : "Scheduled") : "Not Scheduled"
                      }
                      className="w-full h-10 border-[#D3D3D3] rounded-[4px] focus:outline-0 pointer-events-none"
                      readOnly
                    ></input>
                  </div>
                  <div className="my-[8px]">
                    <div className="text-sm font-['Inter'] font-semibold mb-[2px]">Onboarding Date</div>
                    <DatePickerWithProps
                      className="border-[#D3D3D3] rounded-[4px] focus:bg-transparent focus:text-inherit"
                      date={onboardingDate}
                      setDate={setOnboardingDate}
                    />
                  </div>
                  <div className="my-[8px]">
                    <div className="text-sm font-['Inter'] font-semibold mb-[2px]">Onboarding Time</div>
                    <TimePickerWithProps
                      className="border-[#D3D3D3] rounded-[4px] focus:bg-transparent focus:text-inherit"
                      setStartTime={setOnboardingTime}
                      time={onboardingTime}
                      setTime={setOnboardingTime}
                    />
                  </div>
                  <div className="my-[8px]">
                    <div className="text-sm font-['Inter'] font-semibold mb-[2px]">Onboarding Manager</div>
                    <Combobox
                      name="onboardingManagerId"
                      id="onboardingManagerId"
                      required
                      onChange={onSelectChangeOnboardingManager}
                      defaultOption="Select Onboarding Manager"
                      options={onboardingManagersList}
                      value={onboardingManagerId}
                      setValue={setOnboardingManagerId}
                      className="h-[40px] w-[301px] border-[1px] rounded-[4px] hover:border-v2-white border-[#D3D3D3] bg-v2-white text-v2-gray-500 hover:bg-v2-gray-200 focus:bg-transparent focus:text-inherit outline-auto focus:border-[#D3D3D3] focus:ring-0"
                    />
                  </div>

                  <div className="grid grid-cols-3 gap-[14px] mt-[8px]">
                    <div></div>
                    <div
                      onClick={cancelOnboardingForm}
                      className="flex justify-center items-center w-full border-2 border-gray-300 bg-white text-new-solid-black rounded-[4px] cursor-pointer font-inter text-base font-medium leading-normal h-[35px] mt-[6px] p-[6px] text-[14px]"
                    >
                      {" "}
                      Cancel
                    </div>
                    <button
                      type="submit"
                      className={
                        "flex justify-center w-full hover:bg-[#03249A] bg-new-accent text-white rounded-[4px]  font-inter text-base font-medium leading-normal h-[35px] mt-[6px] p-[6px] text-[14px]"
                      }
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal>
      ) : (
        <></>
      )}

      {onboardingData?.manager_feedback ? (
        <Modal
          open={isFeedbackModalOpen}
          width="w-[452px]"
          height="h-auto max-h-[80vh]"
          className="p-[24px] rounded-[10px] border border-gray-300 bg-white shadow-md overflow-auto"
        >
          <div className="flex flex-col justify-between m-0 p-0">
            <div className="flex flex-col justify-between">
              <div className="flex flex-col mb-[8px]">
                <div className="text-[#1b3473] text-base font-['Inter'] font-bold">Session Feedback</div>
                <div className="text-[#636363] text-sm font-medium font-['Inter']">
                  {formatTimestamp(onboardingData?.start_time)}
                </div>
              </div>
              <button
                className="absolute top-6 right-6"
                onClick={() => {
                  SetIsFeedbackModalOpen(false)
                }}
              >
                <CrossIcon className="h-4 w-4" />
              </button>
              <div className="flex flex-col justify-between">
                <div className="my-[8px]">
                  <div className="text-[#333232] text-sm font-['Inter'] font-semibold mb-[2px]">Manager Feedback</div>
                  <div className="text-[10px] text-[#636363] font-['Inter'] font-semibold mt-[9px] mb-[11px]">
                    Objective
                  </div>
                  <textarea
                    value={onboardingData?.manager_feedback?.objective}
                    rows={3}
                    className="w-full p-2 bg-[#f8f8f8] text-new-neutral border border-solid border-[#D3D3D3] rounded-[4px] outline-none overflow-auto resize-none pointer-events-none"
                  />
                  <div className="text-[10px] text-[#636363] font-['Inter'] font-semibold mt-[11px] mb-[11px]">
                    Next Work Action
                  </div>
                  <textarea
                    value={onboardingData?.manager_feedback?.action}
                    rows={3}
                    className="w-full p-2 bg-[#f8f8f8] text-new-neutral border border-solid border-[#D3D3D3] rounded-[4px] outline-none overflow-auto resize-none pointer-events-none"
                  />
                </div>
                <div className="my-[8px]">
                  <div className="text-[#333232] text-sm font-['Inter'] font-semibold mb-[11px]">Student Feedback</div>
                  <StarRating starRating={onboardingData?.student_feedback?.star} />
                  <textarea
                    value={onboardingData?.student_feedback?.message}
                    rows={3}
                    className="w-full mt-[11px] p-2 bg-[#f8f8f8] text-new-neutral border border-solid border-[#D3D3D3] rounded-[4px] outline-none overflow-auto resize-none pointer-events-none"
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          open={isFeedbackModalOpen}
          width="w-[452px]"
          height="h-[535px]"
          className="p-[24px] rounded-[10px] border border-gray-300 bg-white shadow-md"
        >
          <form
            onSubmit={async (e) => {
              e.preventDefault()
              await handleFormSubmitFeedback({
                email: student?.email,
                manager_feedback: {
                  action: workAction,
                  objective: objective,
                },
              })
              SetIsFeedbackModalOpen(false)
            }}
          >
            <div className="flex flex-col justify-between m-0 p-0">
              <div className="flex flex-col justify-between">
                <div className="flex flex-col mb-[8px]">
                  <div className=" text-[#1b3473] text-base font-['Inter'] font-bold">Session Feedback</div>
                  <div className="test-[#636363] text-sm font-medium font-['Inter'] ">{`Onboarding Session`}</div>
                </div>
                <div className="flex flex-col justify-between">
                  <div className="my-[8px]">
                    <div className=" text-[#333232] text-sm font-['Inter'] font-semibold mb-[2px]">
                      Manager Feedback
                    </div>
                    <div className="text-[10px] text-[#636363] font-['Inter'] font-semibold mt-[9px] mb-[11px]">
                      Objective
                    </div>
                    <textarea
                      placeholder="Type your message here!"
                      onChange={handleObjective}
                      className="w-full h-[100px] p-2 text-new-neutral border border-solid border-new-neutral rounded-[4px] outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent overflow-auto text-new-netural"
                    />
                    <div className="text-[10px] text-[#636363] font-['Inter'] font-semibold mt-[11px] mb-[11px]">
                      Next Work Action
                    </div>
                    <textarea
                      placeholder="Type your message here!"
                      onChange={handleWorkAction}
                      className="w-full h-[90px] p-2 text-new-neutral border border-solid border-new-neutral rounded-[4px] outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent overflow-auto text-new-netural"
                    />
                  </div>
                  <div className="my-[8px]">
                    <div className="text-[#333232] ext-sm font-['Inter'] font-semibold mb-[11px] ">
                      Student Feedback
                    </div>
                    <StarRating starRating={onboardingData?.student_feedback?.star} />
                  </div>
                  <div className="grid grid-cols-3 gap-[14px] mt-[8px]">
                    <div></div>
                    <div
                      onClick={cancelFeedbackForm}
                      className="flex justify-center items-center w-full border-2 border-gray-300 bg-white text-new-solid-black rounded-[4px] cursor-pointer font-inter text-base font-medium leading-normal h-[35px] mt-[6px] p-[6px] text-[14px]"
                    >
                      {" "}
                      Cancel
                    </div>
                    <button
                      type="submit"
                      className={
                        "flex justify-center w-full hover:bg-[#03249A] bg-new-accent text-white rounded-[4px]  font-inter text-base font-medium leading-normal h-[35px] mt-[6px] p-[6px] text-[14px]"
                      }
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal>
      )}

      <div className="font-montserrat space-y-6 p-6">
        <div>
          <div>
            <div className="flex ">
              <div className="flex-1 text-2xl font-bold text-[#312E81]">
                <div>
                  <h2 className="text-new-accent">Student Profile</h2>
                  <p className="font-normal text-black">View, edit & update student's profile details</p>
                </div>
              </div>
              <Link to={`/admin/students/`}>
                <button className="flex h-[33px] w-[93px] items-center rounded-sm border-[0.5px] border-[#DC2626] px-4 py-2 text-[14px] font-medium text-[#DC2626]  hover:bg-[#DC2626] hover:text-white">
                  Go Back
                </button>
              </Link>
            </div>
          </div>
        </div>
        <StudentCard
          email={student?.email || "email"}
          name={student?.name || "name"}
          imageURL={student?.imageURL || "imageURL"}
          phone={student?.phone || "phone"}
          batch={student?.batch || "batch"}
          batchPaused={student?.batch_v4?.slice(-1)[0]?.batch_pause_reason ? true : false}
          batchPausedTimeStamp={student?.batch_v4?.slice(-1)[0]?.timestamp_end || 0}
        />
        {/* <div className="flex flex-wrap items-center gap-4">
          <form
            className="flex flex-wrap items-center gap-4"
            onSubmit={redirectToSearchedUser}
          >
            <Input
              id="search-email"
              containerClass="min-w-[300px]"
              placeholder="Search with email"
              value={searchEmail}
              onChange={handleSearchByEmail}
              search
            />
            <Button outlined className="w-[200px]">
              Search
            </Button>
          </form>
          <Button
            small
            className="w-[200px]"
            loading={updating}
            disabled={updating}
            onClick={handleSubmit}
          >
            Save Profile
          </Button>
        </div> */}
        <div className="space-y-8">
          <div
            className={`space-y-4 rounded-[4px] border border-[#ADADAD] ${!collapseBasicInfo && "border-opacity-50"}`}
          >
            <div
              onClick={() => handleToggleState(setCollapseBasicInfo, collapseBasicInfo)}
              className={`text-new-accent px-6 py-4 cursor-pointer`}
            >
              <div className="flex justify-evenly">
                <div className="font-montserrat  font-semibold text-[#312E81]">Basic Information</div>
                <div className="ml-auto mr-2 flex cursor-pointer items-center">
                  {collapseBasicInfo ? (
                    <ChevronDown className=" h-6 w-6 rotate-180 transform" />
                  ) : (
                    <ChevronDown className=" h-6 w-6 " />
                  )}
                </div>
              </div>
            </div>
            <div
              className={`m-5 p-5 grid grid-cols-3 gap-4 lg:grid-cols-4 border rounded-[4px] border-[#ADADAD] ${collapseBasicInfo ? "block" : "hidden"}`}
              style={{ marginBottom: "20px" }}
            >
              <Input placeholder="Email" value={student?.email} onChange={onChangeStudentObj} id="email" />
              <StandaredSelect
                required
                options={options}
                placeholder="Student status"
                onChange={onSelectChangeStudentStatus}
                value={String(advanceStudent?.student_status)}
              />
              <Input placeholder="Name" value={student?.name} onChange={onChangeStudentObj} id="name" />
              <Input placeholder="Phone" value={student?.phone} onChange={onChangeStudentObj} id="phone" />

              <Select
                options={countryOptions}
                onChange={onChangeStudentObj}
                defaultOption="Select Country"
                value={student?.country}
                id="country"
              />
              <Select
                options={stateOptions}
                defaultOption="Select State"
                onChange={onChangeStudentObj}
                value={String(student?.state)}
                id="state"
              />
              <Input placeholder="Student ID" value={advanceStudent?.id} onChange={onChangeAdvStudentObj} id="id" />
              <Input placeholder="Batch" value={student?.batch} onChange={onChangeStudentObj} id="batch" />
              <Input
                placeholder="Secoundary Phone"
                value={advanceStudent?.secondary_phone_number}
                onChange={onChangeAdvStudentObj}
                id="secondary_phone_number"
              />
              <Input
                placeholder="Experience"
                value={student?.experience}
                onChange={onChangeStudentObj}
                id="experience"
              />
              <Input
                placeholder="Current Company"
                value={student?.current_company}
                onChange={onChangeStudentObj}
                id="current_company"
              />
              <Input
                placeholder="Dream Company"
                value={advanceStudent?.dream_company}
                onChange={onChangeAdvStudentObj}
                id="dream_company"
              />
              <Input placeholder="College" value={student?.college} onChange={onChangeStudentObj} id="college" />
              <StandaredSelect
                name="course_type"
                id="course_type"
                placeholder="Course type"
                value={student?.course_type}
                options={course_type}
                onChange={onSelectChangeStudentObj}
              />
              <Input
                placeholder="Current state"
                value={advanceStudent?.current_state}
                onChange={onChangeAdvStudentObj}
                id="current_state"
              />
              <Input
                placeholder="Permenant state"
                value={advanceStudent?.permanent_state}
                onChange={onChangeAdvStudentObj}
                id="permanent_state"
              />
              <Input
                placeholder="Sales representative"
                value={advanceStudent?.sales_rep}
                onChange={onChangeAdvStudentObj}
                id="sales_rep"
              />
              <Input
                placeholder="Referred by"
                value={student?.referred_by}
                onChange={onChangeStudentObj}
                id="referred_by"
              />
              <Input
                placeholder="Lead source"
                value={advanceStudent?.lead_source}
                onChange={onChangeAdvStudentObj}
                id="lead_source"
              />
              <Input
                placeholder="Evengalist"
                value={advanceStudent?.evangalist}
                onChange={onChangeAdvStudentObj}
                id="evangalist"
              />
              <StandaredSelect
                name="leetcode_verified"
                required
                options={options2}
                placeholder="Leetcode verified"
                onChange={onSelectChangeStudentObj}
                value={student?.leetcode_verified}
                id="leetcode_verified"
              />
              <Input
                placeholder="Leetcode username"
                value={student?.leetcode}
                onChange={onChangeStudentObj}
                id="leetcode"
              />
              <div className="flex gap-3">
                <Input
                  containerClass="w-full"
                  placeholder="Password"
                  value={student?.password}
                  id="password"
                  readOnly
                />
                <Button outlined className="w-[200px]" onClick={handleCopyClick}>
                  {isCopied ? "Copied" : "Copy"}
                </Button>
              </div>
              <StandaredSelect
                name="isICP"
                required
                options={options2}
                placeholder="isICP"
                onChange={onSelectChangeStudentObj}
                value={!student?.isICP ? false : student?.isICP}
                id="isICP"
              />

              <StandaredSelect
                name="course_completed"
                required
                options={options2}
                placeholder="Course Completed"
                onChange={onSelectChangeStudentObj}
                value={!student?.course_completed ? false : student?.course_completed}
                id="course_completed"
              />

              <StandaredSelect
                name="course_name"
                id="course_name"
                placeholder="Course Name"
                value={student?.course_name}
                options={course_name}
                onChange={onSelectChangeStudentObj}
              />

              <StandaredSelect
                name="program_type"
                id="program_type"
                placeholder="Program Type"
                value={student?.program_type}
                options={program_type}
                onChange={onSelectChangeStudentObj}
              />

              <Button small onClick={() => navigate("reset-password")}>
                Reset Password
              </Button>
            </div>
          </div>
          <div className="space-y-4">
            <div
              className={`text-new-accent rounded-[4px] border border-[#ADADAD] ${
                !mentorDetails && "border-opacity-50"
              } px-6 py-4`}
            >
              <div
                className="flex justify-evenly cursor-pointer"
                onClick={() => handleToggleState(setMentorDetails, mentorDetails)}
              >
                <div className="font-montserrat font-semibold text-[#312E81]">Mentor Details</div>
                <div className="ml-auto mr-2 flex cursor-pointer items-center">
                  {mentorDetails ? (
                    <ChevronDown className="h-6 w-6 rotate-180 transform" />
                  ) : (
                    <ChevronDown className="h-6 w-6" />
                  )}
                </div>
              </div>

              <div
                className={`mt-5 p-7 grid grid-cols-3 gap-4 lg:grid-cols-4 border rounded-[4px] border-[#ADADAD] ${mentorDetails ? "block" : "hidden"}`}
              >
                <Input
                  placeholder="Mentor ID"
                  value={student?.milestone_session_mentor === "" ? "NOT ALLOTED" : student?.milestone_session_mentor}
                  onChange={onChangeStudentObj}
                />
                {/* {mentorPriority?.map((option: any, index: any) => (
                  <Input
                    key={index}
                    id={index}
                    placeholder="Milestone Session Mentor ID"
                    value={option}
                    onChange={onChangeStudentObj}
                  />
                ))} */}
                <Select
                  name="mentor_id"
                  id="mentor_id"
                  required
                  options={mentorList}
                  defaultOption="Select Milestone Session Mentor"
                  onChange={onSelectChangeMilestoneSessionMentor}
                  value={student?.milestone_session_mentor}
                />
                <Button
                  outlined
                  loading={removeMilestoneMentorLoading}
                  disabled={removeMilestoneMentorLoading}
                  onClick={() => setIsMilestoneSessionMentorOpen(true)}
                >
                  Remove Milestone Session Mentor
                </Button>
              </div>
              <div
                className={`mt-5 p-7 grid grid-cols-3 gap-4 lg:grid-cols-4 border rounded-[4px] border-[#ADADAD] ${mentorDetails ? "block" : "hidden"}`}
              >
                <Input
                  placeholder="Mentor ID"
                  value={student?.mentor_id === "" ? "NOT ALLOTED" : student?.mentor_id}
                  onChange={onChangeStudentObj}
                />
                {mentorPriority?.map((option: any, index: any) => (
                  <Input key={index} id={index} placeholder="Mentor ID" value={option} onChange={onChangeStudentObj} />
                ))}
                <Select
                  name="mentor_id"
                  id="mentor_id"
                  required
                  options={mentorList}
                  defaultOption="Select Mentor"
                  onChange={onSelectChangeStudentObj}
                  value={student?.mentor_id}
                />
                <Button
                  outlined
                  loading={removeMentorLoading}
                  disabled={removeMentorLoading}
                  onClick={() => setIsOpen(true)}
                >
                  Remove Mentor
                </Button>
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <div
              className={`text-new-accent rounded-[4px] border border-[#bdabab] ${
                !paymentInfo && "border-opacity-50"
              } px-6 py-4`}
            >
              <div
                className="flex justify-evenly cursor-pointer"
                onClick={() => handleToggleState(setPaymentInfo, paymentInfo)}
              >
                <div className="font-montserrat font-semibold text-[#312E81]">Payment Information</div>
                <div className="ml-auto mr-2 flex  items-center">
                  {paymentInfo ? (
                    <ChevronDown className="h-6 w-6 rotate-180 transform" />
                  ) : (
                    <ChevronDown className="h-6 w-6" />
                  )}
                </div>
              </div>

              <div
                className={`mt-5 p-7 grid grid-cols-3 border border-[#ADADAD] rounded-[4px] gap-4 lg:grid-cols-4 ${paymentInfo ? "block" : "hidden"}`}
              >
                <Input id="fees" placeholder="Fees" value={student?.fees} onChange={onChangeStudentObj} />
                <StandaredSelect
                  name="payment_method"
                  id="payment_method"
                  required
                  options={payment_method}
                  placeholder="Payment Method"
                  onChange={onSelectChangeStudentObj}
                  value={student?.payment_method}
                />
                <Input
                  id="discount"
                  placeholder="Discount"
                  value={advanceStudent?.discount}
                  onChange={onChangeAdvStudentObj}
                />
                <Input
                  id="Payment Status"
                  placeholder="Payment Status"
                  value={advanceStudent?.payment_status}
                  onChange={onChangeAdvStudentObj}
                />
                <Input
                  id="loan_amount"
                  placeholder="Loan Amount"
                  value={advanceStudent?.loan_amount}
                  onChange={onChangeAdvStudentObj}
                />
                <Input
                  id="loan_tenure"
                  placeholder="Loan Tenure"
                  value={advanceStudent?.loan_tenure}
                  onChange={onChangeAdvStudentObj}
                />
                <Input
                  id="disbursed_amount"
                  placeholder="Disbursed Amount"
                  value={advanceStudent?.disbursed_amount}
                  onChange={onChangeAdvStudentObj}
                />
                <Input
                  id="loan_id"
                  placeholder="Loan ID"
                  value={advanceStudent?.loan_id}
                  onChange={onChangeAdvStudentObj}
                />
                <Input
                  id="advance"
                  placeholder="Advanced Payment"
                  value={advanceStudent?.advance}
                  onChange={onChangeAdvStudentObj}
                />
                <Input
                  id="advance_payment_id"
                  placeholder="Advanced Payment ID"
                  value={advanceStudent?.advance_payment_id}
                  onChange={onChangeAdvStudentObj}
                />
                <Input
                  id="advance_payment_method"
                  placeholder="Advanced Payment Method"
                  value={advanceStudent?.advance_payment_method}
                  onChange={onChangeAdvStudentObj}
                />
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <div
              className={`text-new-accent rounded-[4px] border border-[#ADADAD] ${
                !onboarding && "border-opacity-50"
              } px-6 py-4`}
            >
              <div
                className="flex justify-evenly cursor-pointer"
                onClick={() => handleToggleState(setOnboarding, onboarding)}
              >
                <div className="font-montserrat font-semibold text-[#312E81]">Onboarding</div>
                <div className="ml-auto mr-2 flex cursor-pointer items-center">
                  {onboarding ? (
                    <ChevronDown className="h-6 w-6 rotate-180 transform" />
                  ) : (
                    <ChevronDown className="h-6 w-6" />
                  )}
                </div>
              </div>
              <div
                className={`mt-5 p-7 grid border border-[#ADADAD] rounded-[4px] gap-4  ${onboarding ? "block" : "hidden"}`}
              >
                {!expired ? (
                  <div className="w-full h-[115px] py-[16px] px-[24px] relative bg-white rounded-lg shadow border border-[#d3d3d3]">
                    <div className="flex justify-between">
                      <div className="flex flex-col">
                        <div className="relative w-[142px] h-[24px]">
                          <img
                            src={ArrowRectangle}
                            alt="Mandatory Label"
                            className="absolute top-0 left-0 w-full h-full object-cover"
                          />
                          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                            <span className="text-white text-sm font-medium font-['Inter']">Mandatory</span>
                          </div>
                        </div>
                        <div className="w-[162px] h-[17px] mt-[4px]">
                          <div className="relative text-[#333232] text-sm font-semibold font-['Inter']">
                            Portal Onboarding Meet
                          </div>
                        </div>
                        <div className="w-[313px] h-[30px] mt-[8px]">
                          <div className="relative text-[#333232] text-xs font-normal font-['Inter']">
                            This meet will help you to get your a better understanding of our portal as well as other
                            processes.
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="absolute top-0 left-[126px]">
                          <HomePageGradient className={"#FFBEBE"} />
                        </div>
                        <div className="absolute top-0 left-[201px]">
                          <HomePageGradient className={"#FFBEBE"} />
                        </div>
                        <div className="absolute top-0 left-[821px] -z-10">
                          <HomePageGradient className={"#FFBEBE"} />
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="w-[142px] h-[17px] mb-[31px] relative justify-end text-right text-red-600 font-semibold font-['Inter']">
                          {Number(remainingTime.days) > 0 ? (
                            `${remainingTime.days} day${Number(remainingTime.days) > 1 ? "s" : ""}`
                          ) : (
                            <span className="font-mono text-base">
                              {remainingTime.hours}:{remainingTime.minutes}:{remainingTime.seconds}
                            </span>
                          )}
                        </div>
                        {Number(remainingTime.days) == 0 &&
                        Number(remainingTime.hours) == 0 &&
                        Number(remainingTime.minutes) < 30 ? (
                          <button
                            onClick={joinMeet}
                            className="w-[142px] h-[35px] px-4 py-[9px] relative bg-[#312E81] rounded justify-center items-center gap-2.5 inline-flex"
                          >
                            <div className="text-white text-sm font-medium font-['Inter']">Join Now</div>
                          </button>
                        ) : (
                          <button className="w-[142px] h-[35px] px-4 py-[9px] relative bg-[#d3d3d3] rounded justify-center items-center gap-2.5 inline-flex cursor-not-allowed">
                            <div className="text-white text-sm font-medium font-['Inter']">Join Now</div>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div />
                )}
                <Table className="overflow-hidden rounded">
                  <TableHeader className="rounded">
                    <TableRow className="rounded bg-[#333]">
                      {tableData?.header?.map((title: any, i: any) => (
                        <TableHead className="text-lg text-white">{title}</TableHead>
                      ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody className="overflow-hidden rounded">
                    {!isEmpty(tableData?.rows) ? (
                      tableData?.rows?.map((row: any, i: any) => (
                        <TableRow className="hover:bg-[#ebebeb]">
                          {row.map((cell: any, j: any) => (
                            <TableCell
                              className={`justify-center text-base ${j === 0 || j === row.length - 1 ? "px-4 py-2" : "p-2"}`}
                            >
                              {cell}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    ) : (
                      <div></div>
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <div
              className={`text-new-accent rounded-[4px] border border-[#ADADAD] ${
                !collapseBatchHistory && "border-opacity-50"
              } px-6 py-4`}
            >
              <div
                className="flex justify-evenly cursor-pointer"
                onClick={() => handleToggleState(setCollapseBatchHistory, collapseBatchHistory)}
              >
                <div className="font-montserrat font-semibold text-[#312E81]">Batch History</div>
                <div className="ml-auto mr-2 flex cursor-pointer items-center">
                  {collapseBatchHistory ? (
                    <ChevronDown className="h-6 w-6 rotate-180 transform" />
                  ) : (
                    <ChevronDown className="h-6 w-6" />
                  )}
                </div>
              </div>
              {collapseBatchHistory && (
                <div className="mt-4">
                  <table className="min-w-full border-collapse border border-gray-200">
                    <thead>
                      <tr>
                        <th className="border border-gray-200 px-4 py-2 font-medium">Sr. No</th>
                        <th className="border border-gray-200 px-4 py-2 font-medium">Batch</th>
                        <th className="border border-gray-200 px-4 py-2 font-medium">Date & Time</th>
                        <th className="border border-gray-200 px-4 py-2 font-medium">Updated By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {batchHistoryData?.map((item: any, index: number) => (
                        <tr key={index}>
                          <td className="border border-gray-200 px-4 py-2 text-center">{index + 1}</td>
                          <td className="border border-gray-200 px-4 py-2 text-center">{item.batch_id}</td>
                          <td className="border border-gray-200 px-4 py-2 text-center">
                            {format(new Date(item.timestamp_start * 1000), "MMM dd, yyyy hh:mm a")}
                          </td>
                          <td className="border border-gray-200 px-4 py-2 text-center">{item.changed_by}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>

          <div className="space-y-4 flex flex-col">
            <div
              className={`text-new-accent rounded-[4px] px-6 py-4 border border-[#ADADAD] ${
                !collapsePlacement && "border-opacity-50"
              }`}
            >
              <div
                className="flex justify-evenly cursor-pointer"
                onClick={() => handleToggleState(setCollapsePlacement, collapsePlacement)}
              >
                <div className="font-montserrat font-semibold text-[#312E81]">Placement</div>

                <div className="ml-auto mr-2 flex cursor-pointer items-center">
                  {collapsePlacement ? (
                    <ChevronDown className="h-6 w-6 rotate-180 transform" />
                  ) : (
                    <ChevronDown className="h-6 w-6" />
                  )}
                </div>
              </div>

              <div className={`p-2 text-black rounded-[4px] ${collapsePlacement ? "block" : "hidden"}`}>
                <div className="flex justify-between items-center">
                  <h3 className="text-[18px] font-[600] ">Placement</h3>
                  <button
                    onClick={() => {
                      setPlacementModel(true)
                    }}
                    className="rounded-[4px] px-[16px] py-[9px] border-[1px] text-[14px] hover:bg-[#F9F9F9]"
                  >
                    Add Company
                  </button>
                </div>

                <AddPopUp isOpen={placementModel} width={"w-[349px]"}>
                  <div className="mb-[15px]">
                    <h3 className="text-[16px] font-[600]">Placement information</h3>
                    <h3 className="text-[14px] font-[400] text-[#646464]">Name : {student?.name}</h3>
                  </div>

                  <div className="mt-[10px]">
                    <label className="text-[14px] font-[600]">
                      Placement Company Name <span className="text-[#F00]">*</span>
                    </label>
                    <Input
                      type="text"
                      className="my-[5px] w-full rounded-sm border-[0.5px] border-[#D3D3D3] bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                      placeholder="Placement Company Name"
                      value={companyDetails.company_name}
                      onChange={changeCompanyDetails}
                      name="company_name"
                    />
                  </div>

                  <div className="mt-[10px]">
                    <label className="text-[14px] font-[600]">
                      Select Date <span className="text-[#F00]">*</span>
                    </label>
                    <Input
                      type="date"
                      name="company_date"
                      className="my-[5px] w-full rounded-sm border-[0.5px] border-[#D3D3D3] bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                      value={companyDetails.company_date}
                      onChange={changeCompanyDetails}
                    />
                  </div>

                  <div className="mt-[10px]">
                    <label className="text-[14px] font-[600]">
                      Designation <span className="text-[#F00]">*</span>
                    </label>
                    <Input
                      type="text"
                      className="my-[5px] w-full rounded-sm border-[0.5px] border-[#D3D3D3] bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                      placeholder="Designation"
                      name="designation"
                      value={companyDetails.designation}
                      onChange={changeCompanyDetails}
                    />
                  </div>

                  <div className="absolute bottom-0 h-[39px] bg-[#F9F9F9] w-full">
                    <div className="flex justify-end w-full gap-[16px]">
                      <button
                        onClick={() => {
                          setPlacementModel(false)
                        }}
                        className="bg-[#D3D3D3] py-[9px] px-[16px] rounded-[4px] text-[#333] hover:bg-new-failure hover:text-white"
                      >
                        Cancel
                      </button>
                      <Button
                        onClick={saveCompanyDetails}
                        disabled={formLoding}
                        loading={formLoding}
                        className="w-[100px] py-[9px] px-[16px] rounded-[4px] text-white bg-new-accent hover:bg-blue-800"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </AddPopUp>

                <div className="my-[10px] border-[0.5px] px-[24px] py-[16px] rounded-[4px]">
                  <div className="flex justify-between my-[5px]">
                    <h3 className="text-[14px] font-[600] ">Placement Ready</h3>
                    <h3 className="text-[14px] font-[600] text-[#07B42D]">Student Score</h3>
                  </div>
                  <div className="flex justify-between  items-center">
                    <h3 className="text-[12px] font-[400]">
                      {placementReadyDetails?.hasOwnProperty("placement_ready_timestamp")
                        ? new Date(placementReadyDetails.placement_ready_timestamp).toLocaleDateString("en-GB")
                        : "DD/MM/YYYY"}
                    </h3>

                    <Toggle
                      checked={placementReadyDetails?.hasOwnProperty("placement_ready_timestamp") ? true : false}
                      onChange={() => {
                        setPlacementReady({
                          email: student?.email,
                          placement_date: Date.now(),
                        })
                      }}
                    />
                  </div>
                </div>

                <AddPopUp isOpen={editPlacementModel} width={"w-[349px]"}>
                  <div className="mb-[15px]">
                    <h3 className="text-[16px] font-[600]">Placement information</h3>
                    <h3 className="text-[14px] font-[400] text-[#646464]">Name : {student?.name}</h3>
                  </div>

                  <div className="mt-[10px]">
                    <label className="text-[14px] font-[600]">
                      Placement Company Name <span className="text-[#F00]">*</span>
                    </label>
                    <Input
                      type="text"
                      className="my-[5px] w-full rounded-sm border-[0.5px] border-[#D3D3D3] bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                      placeholder="Placement Company Name"
                      value={editCompanyDetails.company_name}
                      onChange={changeEditCompanyDetails}
                      name="company_name"
                    />
                  </div>

                  <div className="mt-[10px]">
                    <label className="text-[14px] font-[600]">
                      Select Date <span className="text-[#F00]">*</span>
                    </label>
                    <Input
                      type="date"
                      name="company_date"
                      className="my-[5px] w-full rounded-sm border-[0.5px] border-[#D3D3D3] bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                      value={editCompanyDetails.company_date}
                      onChange={changeEditCompanyDetails}
                    />
                  </div>

                  <div className="mt-[10px]">
                    <label className="text-[14px] font-[600]">
                      Designation <span className="text-[#F00]">*</span>
                    </label>
                    <Input
                      type="text"
                      className="my-[5px] w-full rounded-sm border-[0.5px] border-[#D3D3D3] bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                      placeholder="Designation"
                      name="designation"
                      value={editCompanyDetails.designation}
                      onChange={changeEditCompanyDetails}
                    />
                  </div>

                  <div className="absolute bottom-0 h-[39px] bg-[#F9F9F9] w-full">
                    <div className="flex justify-end w-full gap-[16px]">
                      <button
                        onClick={() => {
                          setEditPlacementModel(false)
                        }}
                        className="bg-[#D3D3D3] py-[9px] px-[16px] rounded-[4px] text-[#333] hover:bg-new-failure hover:text-white"
                      >
                        Cancel
                      </button>
                      <Button
                        onClick={saveEditCompanyDetails}
                        disabled={formLoding}
                        loading={formLoding}
                        className="w-[100px] py-[9px] px-[16px] rounded-[4px] text-white bg-new-accent hover:bg-blue-800"
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </AddPopUp>

                <ConfirmationPopUpDelete isOpen={deletePlacementModel} width="w-[575px]">
                  <div className="p-[24px]">
                    <p className="text-[16px] font-[600] text-[#F00]">Delete Confirmation</p>
                    <div className="text-[14px] font-[400] mt-[10px]">
                      <p className="text-[#DC2626]">This action cannot be undone</p>
                      <p className="">You are about to save changes </p>
                    </div>

                    <div className="mt-[10px] w-full flex justify-end gap-[8px]">
                      <button
                        onClick={() => {
                          setDeletePlacementModel(false)
                        }}
                        className="bg-[#D3D3D3] py-[9px] px-[16px] rounded-[4px] text-[#333] hover:bg-[#878787]"
                      >
                        Cancel
                      </button>

                      <Button
                        onClick={() => {
                          deletePlacementCompany({ id: deleteCompanyDetails.id, email: searchEmail })
                        }}
                        loading={formLoding}
                        className="w-[100px] py-[9px] px-[16px] rounded-[4px] text-white bg-new-failure hover:bg-red-800"
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                </ConfirmationPopUpDelete>

                <PlacementTable
                  tableHeaders={["Company Name", "Designation", "Date", ""]}
                  placementDetails={placementDetails}
                  setEditPlacementModel={setEditPlacementModel}
                  setEditCompanyDetails={setEditCompanyDetails}
                  setDeletePlacementModel={setDeletePlacementModel}
                  deletePlacementCompany={deletePlacementCompany}
                  setDeleteCompanyDetails={setDeleteCompanyDetails}
                />
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <div
              className={`text-new-accent rounded-[4px] border border-[#ADADAD] ${
                !collapseRefund && "border-opacity-50"
              } px-6 py-4`}
            >
              <div
                className="flex justify-evenly cursor-pointer"
                onClick={() => handleToggleState(setCollapseRefund, collapseRefund)}
              >
                <div className="font-montserrat font-semibold text-[#312E81]">Refund</div>
                <div className="ml-auto mr-2 flex cursor-pointer items-center">
                  {collapseRefund ? (
                    <ChevronDown className="h-6 w-6 rotate-180 transform" />
                  ) : (
                    <ChevronDown className="h-6 w-6" />
                  )}
                </div>
              </div>
              <div
                className={`mt-5 p-7 border border-[#ADADAD] rounded-[4px] grid grid-cols-3 gap-4 lg:grid-cols-4 ${collapseRefund ? "block" : "hidden"}`}
              >
                <StandaredSelect
                  name="type_of_refund"
                  id="type_of_refund"
                  required
                  options={type_of_refund}
                  placeholder="Type of Refund"
                  onChange={onChangeTypeOfRefund}
                  value={refund?.type_of_refund}
                />
                <Input
                  id="refund_status"
                  placeholder="Refund Status"
                  value={refund?.refund_status}
                  onChange={onChangeRefund}
                />
                <Input id="refund_id" placeholder="Refund ID" value={refund?.refund_id} onChange={onChangeRefund} />
                <Input
                  id="refund_amount"
                  placeholder="Refund Amount"
                  value={refund?.refund_amount}
                  onChange={onChangeRefund}
                />
                <Input
                  id="refund_date"
                  placeholder="Refund Date"
                  value={refund?.refund_date}
                  onChange={onChangeRefund}
                />
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <div
              className={`text-new-accent rounded-[4px] border border-[#ADADAD] ${
                !collapseComments && "border-opacity-50"
              } px-6 py-4`}
            >
              <div
                className="flex justify-evenly cursor-pointer"
                onClick={() => handleToggleState(setCollapseComments, collapseComments)}
              >
                <div className="font-montserrat font-semibold text-[#312E81]">Comments</div>
                <div className="ml-auto mr-2 flex cursor-pointer items-center">
                  {collapseComments ? (
                    <ChevronDown className="h-6 w-6 rotate-180 transform" />
                  ) : (
                    <ChevronDown className="h-6 w-6" />
                  )}
                </div>
              </div>

              <div className={`mt-5 p-2 text-black ${collapseComments ? "block" : "hidden"}`}>
                <h3 className="text-[16px] font-[500]">Student’s History</h3>
                <p className="text-[14px] font-[400] text-[#646464]">
                  View, manage, and update student's history here.
                </p>

                <button
                  onClick={() => {
                    setCommentModel(true)
                  }}
                  className="flex items-center gap-[4px] my-[16px] rounded-[4px] px-[16px] py-[9px] border-[1px] text-[14px] hover:bg-[#F9F9F9]"
                >
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                      <rect y="0.5" width="16" height="16" rx="2" fill="#F9F9F9" />
                      <path
                        d="M7.3335 7.8335V3.8335H8.66683V7.8335H12.6668V9.16683H8.66683V13.1668H7.3335V9.16683H3.3335V7.8335H7.3335Z"
                        fill="#001018"
                      />
                    </svg>
                  </span>{" "}
                  Add Case
                </button>

                <AddPopUp isOpen={commentModel} width={"w-[349px]"}>
                  <div className="mb-[15px]">
                    <h3 className="text-[16px] font-[600]">Student's History</h3>
                    <h3 className="text-[14px] font-[400] text-[#646464]">Name : {student?.name}</h3>
                  </div>

                  <div className="mt-[10px]">
                    <label className="text-[14px] font-[600]">
                      Select Date <span className="text-[#F00]">*</span>
                    </label>
                    <Input
                      type="date"
                      name="comment_date"
                      className="my-[5px] w-full rounded-sm border-[0.5px] border-[#D3D3D3] bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                      value={commentDetails.comment_date}
                      onChange={changeCommentDetails}
                    />
                  </div>

                  <div className="mt-[10px]">
                    <label className="text-[14px] font-[600]">
                      Updated By <span className="text-[#F00]">*</span>
                    </label>
                    <Input
                      type="text"
                      className="my-[5px] w-full rounded-sm border-[0.5px] border-[#D3D3D3] bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                      placeholder="Type here"
                      name="updated_by"
                      value={commentDetails.updated_by}
                      onChange={changeCommentDetails}
                    />
                  </div>

                  <div className="mt-[10px]">
                    <label className="text-[14px] font-[600]">
                      Reason <span className="text-[#F00]">*</span>
                    </label>
                    <Textarea
                      className="min-w-[300px] h-[140px] my-[5px] rounded-sm border-[0.5px] border-[#D3D3D3] bg-new-solid-white text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                      placeholder="Type your message here!"
                      name="reason"
                      value={commentDetails.reason}
                      onChange={changeCommentDetails}
                    />
                  </div>

                  <div className="absolute bottom-0 h-[39px] bg-[#F9F9F9] w-full">
                    <div className="flex justify-end w-full gap-[16px]">
                      <button
                        onClick={() => {
                          setCommentModel(false)
                        }}
                        className="bg-[#D3D3D3] py-[9px] px-[16px] rounded-[4px] text-[#333] hover:bg-new-failure hover:text-white"
                      >
                        Cancel
                      </button>
                      <Button
                        onClick={saveCommentDetails}
                        disabled={formLoding}
                        loading={formLoding}
                        className="w-[100px] py-[9px] px-[16px] rounded-[4px] text-white bg-new-accent hover:bg-blue-800"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </AddPopUp>

                <AddPopUp isOpen={editCommentModel} width={"w-[349px]"}>
                  <div className="mb-[15px]">
                    <h3 className="text-[16px] font-[600]">SSM Meeting</h3>
                    <h3 className="text-[14px] font-[400] text-[#646464]">Name : {student?.name}</h3>
                  </div>

                  <div className="mt-[10px]">
                    <label className="text-[14px] font-[600]">
                      Select Date <span className="text-[#F00]">*</span>
                    </label>
                    <Input
                      type="date"
                      name="comment_date"
                      className="my-[5px] w-full rounded-sm border-[0.5px] border-[#D3D3D3] bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                      value={editCommentDetails.comment_date}
                      onChange={changeEditCommentDetails}
                    />
                  </div>

                  <div className="mt-[10px]">
                    <label className="text-[14px] font-[600]">
                      Updated By <span className="text-[#F00]">*</span>
                    </label>
                    <Input
                      type="text"
                      className="my-[5px] w-full rounded-sm border-[0.5px] border-[#D3D3D3] bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                      placeholder="Type here"
                      name="updated_by"
                      value={editCommentDetails.updated_by}
                      onChange={changeEditCommentDetails}
                    />
                  </div>

                  <div className="mt-[10px]">
                    <label className="text-[14px] font-[600]">
                      Reason <span className="text-[#F00]">*</span>
                    </label>
                    <Textarea
                      className="min-w-[300px] h-[140px] my-[5px] rounded-sm border-[0.5px] border-[#D3D3D3] bg-new-solid-white text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                      placeholder="Type your message here!"
                      name="reason"
                      value={editCommentDetails.reason}
                      onChange={changeEditCommentDetails}
                    />
                  </div>

                  <div className="absolute bottom-0 h-[39px] bg-[#F9F9F9] w-full">
                    <div className="flex justify-end w-full gap-[16px]">
                      <button
                        onClick={() => {
                          setEditCommentModel(false)
                        }}
                        className="bg-[#D3D3D3] py-[9px] px-[16px] rounded-[4px] text-[#333] hover:bg-new-failure hover:text-white"
                      >
                        Cancel
                      </button>
                      <Button
                        onClick={saveEditCommentDetails}
                        disabled={formLoding}
                        loading={formLoding}
                        className="w-[100px] py-[9px] px-[16px] rounded-[4px] text-white bg-new-accent hover:bg-blue-800"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </AddPopUp>

                <ConfirmationPopUpDelete isOpen={deleteCommentModel} width="w-[575px]">
                  <div className="p-[24px]">
                    <p className="text-[16px] font-[600] text-[#F00]">Delete Confirmation</p>
                    <div className="text-[14px] font-[400] mt-[10px]">
                      <p className="text-[#DC2626]">This action cannot be undone</p>
                      <p className="">You are about to save changes </p>
                    </div>

                    <div className="mt-[10px] w-full flex justify-end gap-[8px]">
                      <button
                        onClick={() => {
                          setDeleteCommentModel(false)
                        }}
                        className="bg-[#D3D3D3] py-[9px] px-[16px] rounded-[4px] text-[#333] hover:bg-[#878787]"
                      >
                        Cancel
                      </button>

                      <Button
                        onClick={() => {
                          deleteStudentComment({ id: deleteCommentDetails.id, email: searchEmail })
                        }}
                        className="w-[100px] py-[9px] px-[16px] rounded-[4px] text-white bg-new-failure hover:bg-red-800"
                        loading={formLoding}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                </ConfirmationPopUpDelete>

                <CommentTable
                  tableHeaders={["Sr. No.", "Date and Time", "Updated By", "Reason", ""]}
                  studentComments={studentComments}
                  setEditCommentModel={setEditCommentModel}
                  setEditCommentDetails={setEditCommentDetails}
                  setDeleteCommentModel={setDeleteCommentModel}
                  setDeleteCommentDetails={setDeleteCommentDetails}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="border-400 mb-4 mt-4 w-full rounded border-[0.5px] border-[#ADADAD] text-[20px] font-semibold">
          <div className="flex flex-col">
            <div className="flex  flex-col justify-between pl-[24px] pr-[24px] pt-[16px] pb-[16px]">
              <div onClick={handleToggle} className="flex h-[27px] cursor-pointer items-center  justify-between">
                <h2 className="font-montserrat text-[18px] font-semibold text-[#312E81] ">User Transaction</h2>

                <button className="focus:outline-none">
                  {isExpanded ? <ChevronUp className=" h-6 w-6" /> : <ChevronDown className="h-6 w-6" />}
                </button>
              </div>

              <div className="flex w-full justify-center">
                {isExpanded && (
                  <div className="flex w-full flex-col">
                    <div className="mt-[24px] flex items-center justify-between rounded bg-[#F5F5F5] p-[16px]">
                      <div className="font-sans text-[14px] font-semibold leading-normal  text-[#414141]">
                        Payment Method
                      </div>
                      <Select
                        name="PAYMENT_METHOD"
                        required
                        options={[
                          { label: "RazorPay", value: "razorpay" },
                          { label: "ccAvenue", value: "ccavenue" },
                        ]}
                        onChange={onSelectChangePaymentMethod}
                        defaultOption={"Payment Method"}
                        value={student?.payment_method}
                        id="PAYMENT_METHOD"
                      />
                    </div>
                    {student?.payment_method && (
                      <div className="mt-[24px] flex items-center justify-between rounded bg-[#F5F5F5] p-[16px]">
                        <div className="font-sans text-[14px] font-semibold leading-normal  text-[#414141]">
                          New Payment Option
                        </div>
                        <Select
                          name="PAYMENT_OPTION"
                          required
                          options={[
                            { label: "Enable", value: "true" },
                            { label: "Disable", value: "false" },
                          ]}
                          onChange={ChangePaymentOptionsMenu}
                          defaultOption={"Payment Option"}
                          value={student?.PAYMENT_OPTION !== undefined ? student?.PAYMENT_OPTION : "false"}
                          id="PAYMENT_OPTION"
                        />
                      </div>
                    )}
                    {/* {student?.payment_method && (
                      <div className="mt-[24px] flex items-center justify-between rounded bg-[#F5F5F5] p-[16px]">
                        <div className="font-sans text-[14px] font-semibold leading-normal  text-[#414141]">
                          New Payment Option
                        </div>
                        <Select
                          name="PAYMENT_OPTION"
                          required
                          options={[
                            { label: "Enable", value: "true" },
                            { label: "Disable", value: "false" },
                          ]}
                          placeholder="Payment Option"
                          onChange={ChangePaymentOptionsMenu}
                          defaultOption={"Payment Option"}
                          value={
                            student?.PAYMENT_OPTION !== undefined
                              ? student?.PAYMENT_OPTION
                              : "false"
                          }
                          id="PAYMENT_OPTION"
                        />
                      </div>
                    )} */}
                    <div className="mt-[24px] flex items-center justify-between rounded bg-[#F5F5F5] p-[16px]">
                      <div className="font-sans text-[14px] font-semibold leading-normal  text-[#414141]">
                        Full payment confirmation :{" "}
                      </div>
                      <button className="inline-flex h-[33px] w-[163px] items-center justify-center rounded-sm border border-gray-500 bg-white p-2 text-[14px] font-medium">
                        {isCompletlyPaid ? (
                          <div className="w-[406px] text-sm font-semibold text-[#00C22B] ">Completely Paid</div>
                        ) : (
                          <div className="font-sans text-[14px] font-semibold leading-normal text-[#DC2626]">Due</div>
                        )}
                      </button>
                    </div>

                    <div className="mt-[24px] mb-[24px] w-full rounded border border-[#ADADAD] p-[24px]">
                      <div className="flex items-center justify-between">
                        <div className="font-montserrat text-primary-color-bp flex text-[18px] font-semibold leading-normal text-[#312E81]">
                          Fee Details
                        </div>
                        <div className="flex">
                          <button
                            onClick={
                              student?.payment_method === "ccavenue" ? handleCCAvenuePaymentDetails : handleEditPayment
                            }
                            className="inline-flex h-[36px] w-[175px] items-center justify-center rounded-sm border border-gray-500 bg-white p-3 text-[14px] font-medium text-[#312E81]"
                          >
                            Edit Payment Detail
                          </button>
                        </div>
                      </div>

                      <div className="mb-2 mt-7  flex items-center justify-between gap-[30vw]">
                        <div className="font-sans text-[14px] font-semibold leading-normal  text-[#414141]">
                          Total Course Fee
                        </div>
                        <input
                          type="text"
                          value={`${studentGetPayDetail?.fees} Rs`}
                          className="text-[#font-sans #414141] h-[40px]  w-40 appearance-none rounded-[1px]  border-none bg-[#F5F5F5] p-2 text-center text-[14px] text-base font-[500] font-semibold leading-normal"
                          placeholder="Amount"
                        />
                      </div>

                      <div className="mb-2  flex items-center justify-between gap-[30vw]">
                        <div className="font-sans text-[14px] font-semibold leading-normal  text-[#414141]">
                          Seat Booking Amount
                        </div>
                        <input
                          type="text"
                          value={`${studentGetPayDetail?.seat_booking_amount} Rs`}
                          className="text-[#font-sans #414141] h-[40px] w-40 appearance-none  rounded-[1px] border-none bg-[#F5F5F5] p-2 text-center text-[14px] text-base font-[500] font-semibold leading-normal"
                          placeholder="Amount"
                        />
                      </div>

                      <div className="mb-2 flex items-center justify-between gap-[30vw]">
                        <div className="font-sans text-[14px] font-semibold leading-normal text-[#DC2626]">
                          Standard Discount :
                        </div>
                        <input
                          type="text"
                          value={`${studentGetPayDetail?.standard_discount} Rs`}
                          className="h-[40px] w-40 appearance-none rounded-[1px] border-none bg-[#F5F5F5] p-2 text-center text-[14px] font-[500] text-[#DC2626]"
                          placeholder="Amount"
                        />
                      </div>
                      <div
                        className={`mb-2 flex items-center justify-between gap-[30vw] ${
                          studentGetPayDetail?.expired ? "opacity-20" : "opacity-100"
                        }`}
                      >
                        <div className="font-sans text-[14px] font-semibold leading-normal text-[#DC2626]">
                          {`Offer Discount : `}
                          <span className="text-slate-500">
                            (Offer Available Till {studentGetPayDetail?.offer_date})
                          </span>
                        </div>
                        <input
                          type="text"
                          value={`${studentGetPayDetail?.offer_discount} Rs`}
                          className="h-[40px] w-40 appearance-none rounded-[1px] border-none bg-[#F5F5F5] p-2 text-center text-[14px] font-[500] text-[#DC2626]"
                          placeholder="Amount"
                        />
                      </div>
                      <div className="mb-2 flex items-center justify-between gap-[30vw]">
                        <div className="font-sans text-[14px] font-semibold leading-normal text-[#DC2626]">
                          {` One Shot Payment Discount ( ${studentGetPayDetail?.one_shot_discount}% ) :`}
                        </div>
                        <input
                          type="text"
                          value={`${calculateOneshotDiscount()} Rs`}
                          className="h-[40px] w-40 appearance-none rounded-[1px] border-none bg-[#F5F5F5] p-2 text-center text-[14px] font-[500] text-[#DC2626]"
                          placeholder="Amount"
                        />
                      </div>

                      <hr className="my-6 border-t-2 border-dashed border-gray-300" />

                      {/* Payable Amount */}
                      <div className="flex items-center justify-between rounded-[0.25rem] bg-[#F5F5F5] p-[1rem]">
                        <div className="font-sans  text-[14px] font-semibold leading-normal  text-[#414141]">
                          Total amount payable :{" "}
                        </div>
                        <button className="inline-flex h-[36px] w-[163px] items-center justify-center rounded-sm border border-gray-500 bg-white p-3 text-[14px] font-medium">
                          {`${getPayableAmount()} Rs`}
                        </button>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="flex flex-col">
                        <div className="text-[18px] font-semibold text-[#312E81]">Transaction History:</div>
                        <div className="font-montserrat text-[14px] font-normal leading-relaxed text-[#737373]">
                          Here, you can find the user transaction history.
                        </div>
                      </div>
                      <button
                        className="w-40 h-10 bg-white border border-[#EF4444] text-[#EF4444] hover:bg-[#EF4444] hover:text-white transition duration-300 text-base rounded"
                        onClick={handleRefundBtnClick}
                      >
                        Initiate Refund
                      </button>
                    </div>
                    {refundFormOpen && (
                      <RefundForm
                        Keys={Keys}
                        disabledKeys={disabledKeys}
                        currentData={refundUserData}
                        handleDiscardBtnClick={handleDiscardBtnClick}
                        handleSaveChanges={handleSaveChangesClick}
                        handleInputChange={handleInputChange}
                        confirmClick={handleConfirmClick}
                        cancelClick={handleCancelClick}
                        showPopup={showPopup}
                        popUpClose={handlePopUpClose}
                        refundFormClose={handleRefundFormClose}
                      />
                    )}

                    <div className="flex w-full items-center justify-center p-1 pt-4">
                      <AdminTable
                        data={studentPayTableData}
                        outlined={true}
                        headerWidths={["[15px]", "[100px]", "[150px]", "[175px]", "[100px]", "[70px]", "full"]}
                        rowWidths={["[15px]", "[100px]", "[150px]", "[175px]", "[100px]", "[70px]", "full"]}
                        paddingInfo={["[24px]", "[8px]", "[24px]", "[16px]", "[13px]"]}
                      />
                    </div>
                    {studentEMI && studentEMI.data
                      ? studentEMI.data.is_submitted === true && (
                          <PaymentVerification
                            studentEmail={student?.email}
                            paymentDocuments={paymentDocuments}
                            studentEMI={studentEMI}
                            getPaymentDocuments={getPaymentDocuments}
                            setPaymentDocuments={setPaymentDocuments}
                            updatePaymentDocument={updatePaymentDocument}
                            uploadPaymentDocument={uploadPaymentDocument}
                            documentVerified={documentVerified}
                          />
                        )
                      : "LOADINGGG"}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center">
        <Button small className="w-[200px]" loading={updating} disabled={updating} onClick={handleSubmit}>
          Save Profile
        </Button>
      </div>
      <SsmCallView email={searchEmail} student={student} />
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
