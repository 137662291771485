import React, { useContext, useState } from "react"
import useLocalStorage from "core/hooks/useLocalStorage"
import useToast from "core/hooks/useToast"
import ContestAPIDataSourceImpl from "data/API/Student/ContestAPIDataSourceImpl"
import ContestRepositoryImpl from "data/repository/Student/ContestRepositoryImpl"
import GetAllContest from "domain/useCase/Student/Assessment/GetAllContest"
import { STR_FAILURE } from "core/constants/strings"
import { SuccessCheckboxIcon } from "core/constants/svgs"
import { useAuth } from "core/context/auth"
import { genError } from "core/utils/string"
import GetAllExams from "domain/useCase/Student/Assessment/GetAllExams"
import { useApp } from "core/context/app"
import { isEmpty } from "core/utils/misc"
import GetUpcomingExams from "domain/useCase/Student/Assessment/GetUpcomingExams"
import { AppContext } from "core/context/app/AppContext"

export default function ContestViewModel() {
  const [tableData, setTableData] = React.useState<any>({
    header: [<SuccessCheckboxIcon className="h-4 w-4 text-[#FAFAFA]" />, "Name", "Date", "Time", "Link", "Exam Result"],
    rows: [],
  })
  const [activeButtonType, setActiveButtonType] = React.useState(0)
  const [allContest, setAllContest] = useState<any>({})
  const [upcomingExams, setUpcomingExams] = useState<any>({})
  const [totalContest, setTotalContest] = useLocalStorage<any>("contest", {})
  const [loading, setLoading] = React.useState<boolean>(true)
  const [page, setPage] = React.useState(1)
  const { contestList } = useApp()
  const [totalDataList, setTotalDataList] = React.useState(0)
  const { auth, refreshed } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const appContext = useContext(AppContext)
  if (!appContext) {
    throw new Error("AppContext is not available!")
  }
  const { pendingAPI, setPendingAPI } = appContext

  const GetAllContestUseCase = new GetAllContest(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const GetUpcomingExamsUseCase = new GetUpcomingExams(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const GetAllExamsUseCase = new GetAllExams(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))

  async function getAllContest(page: number, limit: number = 20) {
    setLoading(true)
    if (isEmpty(totalContest?.[page])) {
      const response = await GetAllExamsUseCase.invoke(auth, page, limit)
      if (!response?.success) {
        changeToastDetails(STR_FAILURE, genError(response))
        changeToastVisibility(true)
        return
      }
      setAllContest(response?.data)
      setTotalDataList(response?.data?.total)
      setTotalContest((prev: any) => ({
        ...prev,
        [page]: response?.data,
      }))
      const tabType = ["upcoming", "recorded"][activeButtonType]
      setTableData({ ...tableData, rows: response?.data[tabType] })
    } else {
      const upcomingResponse = await GetUpcomingExamsUseCase.invoke(auth, page, limit)

      if (!upcomingResponse?.success) {
        changeToastDetails(STR_FAILURE, genError(upcomingResponse))
        changeToastVisibility(true)
        setLoading(false)
        return
      }

      // Merge upcoming exams data into cached data
      const updatedPageData = {
        ...totalContest[page],
        upcoming: upcomingResponse?.data?.upcoming,
      }

      // Update state with the merged data
      setTotalContest((prev: any) => ({
        ...prev,
        [page]: updatedPageData,
      }))

      setTotalDataList(updatedPageData?.total)

      const tabType = ["upcoming", "recorded"][activeButtonType]
      setTableData({ ...tableData, rows: updatedPageData[tabType] })
    }
    setLoading(false)
  }

  React.useEffect(() => {
    window.onbeforeunload = () => {
      localStorage.removeItem("contest")
    }
    return () => {
      window.onbeforeunload = null
    }
  }, [])

  function changeActiveButtonType(index: number) {
    const tabType = ["upcoming", "recorded"][index]
    if (totalContest?.[page]) {
      setTableData({ ...tableData, rows: totalContest?.[page][tabType] })
    } else {
      setTableData({ ...tableData, rows: allContest[tabType] })
    }
    setActiveButtonType(index)
  }

  return {
    tableData,
    allContest,
    loading,
    activeButtonType,
    toast,
    refreshed,
    changeActiveButtonType,
    getAllContest,
    changeToastVisibility,
    setActiveButtonType,
    page,
    totalDataList,
  }
}
